import { Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SelectAllTables from '../components/SelectAllTables';
import { ReviewProps } from '../typings';

const Configuration: FC<ReviewProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.comp_integration.config' });

  return (
    <SelectAllTables
      t={t}
      form={form}
      tableTitle={<Typography.Title level={3}>{t?.('config_title')}</Typography.Title>}
      isConfig
    />
  );
};
export default Configuration;
