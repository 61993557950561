import * as coreClient from "@azure/core-client";
import * as coreRestPipeline from "@azure/core-rest-pipeline";
import * as coreAuth from "@azure/core-auth";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  WebAPIClientOptionalParams,
  CreateAccountOptionalParams,
  CreateAccountResponse,
  GetAccountOptionalParams,
  GetAccountResponse,
  UpdateAccountOptionalParams,
  UpdateAccountResponse,
  GetAccountByIdOptionalParams,
  GetAccountByIdResponse,
  InviteUsersToAccountOptionalParams,
  InviteUsersToAccountOperationResponse,
  RedeemInviteOptionalParams,
  RedeemInviteResponse,
  DeleteUserInviteFromAccountOptionalParams,
  ListParticipantsOptionalParams,
  ListParticipantsOperationResponse,
  GetSubscriptionOptionalParams,
  GetSubscriptionResponse,
  ListUserAccountsOptionalParams,
  ListUserAccountsOperationResponse,
  DeleteUserFromAccountOptionalParams,
  IsValidInviteOptionalParams,
  IsValidInviteOperationResponse,
  GetTemplateAttributeByIdOptionalParams,
  GetTemplateAttributeByIdResponse,
  UpdateTemplateAttributeOptionalParams,
  UpdateTemplateAttributeResponse,
  DeleteTemplateAttributeOptionalParams,
  ListAttributesOptionalParams,
  ListAttributesResponse,
  CreateTemplateAttributeOptionalParams,
  CreateTemplateAttributeResponse,
  ListAttributesDefaultValuesOptionalParams,
  ListAttributesDefaultValuesResponse,
  DeleteAttributeDefaultValueOptionalParams,
  ListBizStepsOptionalParams,
  ListBizStepsResponse,
  GetContainerInventoryOptionalParams,
  GetContainerInventoryResponse,
  ListDispositionsOptionalParams,
  ListDispositionsResponse,
  GetDocumentByIdOptionalParams,
  GetDocumentByIdResponse,
  UpdateDocumentOptionalParams,
  UpdateDocumentResponse,
  DeleteDocumentByIdOptionalParams,
  DeleteDocumentByIdResponse,
  DownloadDocumentOptionalParams,
  DownloadDocumentResponse,
  CreateDocumentOptionalParams,
  CreateDocumentResponse,
  GetPaginatedDocumentsOptionalParams,
  GetPaginatedDocumentsResponse,
  ShareDocumentOptionalParams,
  ShareDocumentOperationResponse,
  UnshareDocumentOptionalParams,
  UnshareDocumentResponse,
  SendSupportEmailOptionalParams,
  CreateEpcisDataOptionalParams,
  CreateEpcisDataResponse,
  GetEventTemplateByIdOptionalParams,
  GetEventTemplateByIdResponse,
  UpdateEventTemplateOptionalParams,
  UpdateEventTemplateResponse,
  DeleteEventTemplateOptionalParams,
  DeleteEventTemplateResponse,
  GetEventTemplatesOptionalParams,
  GetEventTemplatesResponse,
  CreateEventTemplateOptionalParams,
  CreateEventTemplateResponse,
  ShareEventTemplateOptionalParams,
  ShareEventTemplateResponse,
  UnshareEventTemplateOptionalParams,
  UnshareEventTemplateResponse,
  GetEventByIdOptionalParams,
  GetEventByIdResponse,
  ListEventDetailsOptionalParams,
  ListEventDetailsResponse,
  GetEventDetailsDiagramOptionalParams,
  GetEventDetailsDiagramResponse,
  GetEventDetailsMapOptionalParams,
  GetEventDetailsMapResponse,
  SimpleEventQueryOptionalParams,
  SimpleEventQueryResponse,
  GetEventHistoryCountOptionalParams,
  GetEventHistoryCountOperationResponse,
  CommissionOptionalParams,
  CommissionResponse,
  DecommissionOptionalParams,
  DecommissionResponse,
  ObserveOptionalParams,
  ObserveResponse,
  AggregateOptionalParams,
  AggregateResponse,
  DisaggregateOptionalParams,
  DisaggregateResponse,
  TransformOptionalParams,
  TransformResponse,
  ShipOptionalParams,
  ShipResponse,
  ReceiveOptionalParams,
  ReceiveResponse,
  DeleteEventOptionalParams,
  CreateIntegrationDataOptionalParams,
  CreateIntegrationDataResponse,
  DirectReceiveOptionalParams,
  DirectReceiveResponse,
  GetBlockchainByEventIdOptionalParams,
  GetBlockchainByEventIdResponse,
  SetExternalRegistryDetailsOptionalParams,
  SetExternalRegistryDetailsResponse,
  UpdateExternalRegistryDetailsOptionalParams,
  UpdateExternalRegistryDetailsResponse,
  GetExternalRegistryDetailsOptionalParams,
  GetExternalRegistryDetailsOperationResponse,
  GetInboundApiKeyOptionalParams,
  GetInboundApiKeyOperationResponse,
  QueryDigitalLinkOptionalParams,
  QueryDigitalLinkResponse,
  CreateLanguageOptionalParams,
  CreateLanguageResponse,
  ListLanguagesOptionalParams,
  ListLanguagesResponse,
  GetLanguageOptionalParams,
  GetLanguageResponse,
  DeleteLanguageOptionalParams,
  DeleteLanguageResponse,
  CreateLanguageAttributeOptionalParams,
  CreateLanguageAttributeResponse,
  ListAllLanguageAttributesOptionalParams,
  ListAllLanguageAttributesResponse,
  CreateLanguageAttributeBulkOptionalParams,
  CreateLanguageAttributeBulkResponse,
  UpdateLanguageAttributeBulkOptionalParams,
  GetLanguageAttributeOptionalParams,
  GetLanguageAttributeResponse,
  DeleteLanguageAttributeOptionalParams,
  ListLanguageAttributesOptionalParams,
  ListLanguageAttributesResponse,
  UpdateLanguageAttributeOptionalParams,
  UpdateLanguageAttributeResponse,
  ListLocationsOptionalParams,
  ListLocationsOperationResponse,
  CreateLocationOptionalParams,
  CreateLocationResponse,
  GetLocationByIdOptionalParams,
  GetLocationByIdResponse,
  UpdateLocationOptionalParams,
  UpdateLocationResponse,
  DeleteLocationOptionalParams,
  HasActiveInventoryOptionalParams,
  HasActiveInventoryOperationResponse,
  ToggleArchiveLocationOptionalParams,
  ToggleArchiveLocationOperationResponse,
  ShareLocationOptionalParams,
  ShareLocationResponse,
  GetLocationStatisticsByIdOptionalParams,
  GetLocationStatisticsByIdResponse,
  MasterDataPglnOptionalParams,
  MasterDataPglnResponse,
  MasterDataGLNOptionalParams,
  MasterDataGLNResponse,
  MasterDataGtinOptionalParams,
  MasterDataGtinResponse,
  MasterDataGtinLotSerialOptionalParams,
  MasterDataGtinLotSerialResponse,
  ListNetworksOptionalParams,
  ListNetworksResponse,
  ListNetworkInvitesOptionalParams,
  ListNetworkInvitesResponse,
  CreateNetworkInviteOptionalParams,
  CreateNetworkInviteResponse,
  GetNetworkInviteByIdOptionalParams,
  GetNetworkInviteByIdResponse,
  UpdateNetworkInviteOptionalParams,
  UpdateNetworkInviteResponse,
  DeleteNetworkInviteOptionalParams,
  DeleteNetworkInviteResponse,
  ListNotificationsOptionalParams,
  ListNotificationsResponse,
  ChangeToReadNotificationsOptionalParams,
  ListOrphansOptionalParams,
  ListOrphansResponse,
  ListOrphansSuggestionsOptionalParams,
  ListOrphansSuggestionsResponse,
  ResolveOrphanOptionalParams,
  ResolveOrphanResponse,
  GetIntegrationEventsOptionalParams,
  GetIntegrationEventsResponse,
  GetExecutionCheckDiagnosticsOptionalParams,
  GetExecutionCheckDiagnosticsResponse,
  GetIntegrationsOptionalParams,
  GetIntegrationsResponse,
  CreateIntegrationOptionalParams,
  CreateIntegrationOperationResponse,
  GetIntegrationByIdOptionalParams,
  GetIntegrationByIdResponse,
  UpdateIntegrationOptionalParams,
  UpdateIntegrationResponse,
  DeleteIntegrationOptionalParams,
  GetDefaultConfigurationOptionsOptionalParams,
  GetDefaultConfigurationOptionsResponse,
  CreatePreConfiguredOutboundIntegrationOptionalParams,
  CreatePreConfiguredOutboundIntegrationResponse,
  GetDefaultConfigurationsOptionalParams,
  GetDefaultConfigurationsResponse,
  GetTriggerSpecificationsOptionalParams,
  GetTriggerSpecificationsResponse,
  GetTriggerSpecificationCriteriaOptionsOptionalParams,
  GetTriggerSpecificationCriteriaOptionsResponse,
  GetDefaultMappableElementsOptionalParams,
  GetDefaultMappableElementsResponse,
  CreateTriggerCriteriaOptionalParams,
  CreateTriggerCriteriaResponse,
  DeleteTriggerCriteriaOptionalParams,
  CreateTriggerCriteriaValueOptionalParams,
  CreateTriggerCriteriaValueResponse,
  DeleteTriggerCriteriaValueOptionalParams,
  CreateElementMapOptionalParams,
  CreateElementMapResponse,
  DeleteElementMapOptionalParams,
  ListPluginConfigurationsOptionalParams,
  ListPluginConfigurationsResponse,
  SaveIntegrationFtpSecretsOptionalParams,
  CreateProductOptionalParams,
  CreateProductResponse,
  GetPaginatedProductsOptionalParams,
  GetPaginatedProductsResponse,
  GetProductByIdOptionalParams,
  GetProductByIdResponse,
  UpdateProductOptionalParams,
  UpdateProductResponse,
  GetPaginatedProductInventoryOptionalParams,
  GetPaginatedProductInventoryResponse,
  GetPaginatedLotInventoryOptionalParams,
  GetPaginatedLotInventoryResponse,
  CreateSubProductOptionalParams,
  CreateSubProductResponse,
  DeleteSubProductOptionalParams,
  ChangeArchivedProductOptionalParams,
  ChangeArchivedProductOperationResponse,
  GetGlobalSearchPaginatedProductsOptionalParams,
  GetGlobalSearchPaginatedProductsResponse,
  UploadProductsOptionalParams,
  UploadProductsResponse,
  GetPaginatedShipmentsOptionalParams,
  GetPaginatedShipmentsResponse,
  GetShipmentsSummaryOptionalParams,
  GetShipmentsSummaryOperationResponse,
  CreateExternalShipmentOptionalParams,
  CreateExternalShipmentResponse,
  ListExternalShipmentsOptionalParams,
  ListExternalShipmentsOperationResponse,
  GetExternalShipmentByCaptureOptionalParams,
  GetExternalShipmentByCaptureResponse,
  GetPaginatedInboundShipmentsOptionalParams,
  GetPaginatedInboundShipmentsResponse,
  GetPaginatedOtherSystemShipmentsOptionalParams,
  GetPaginatedOtherSystemShipmentsResponse,
  GetInboundShipmentDetailsOptionalParams,
  GetInboundShipmentDetailsResponse,
  CountInboundShipmentsOptionalParams,
  CountInboundShipmentsResponse,
  GetPaginatedOutboundShipmentsOptionalParams,
  GetPaginatedOutboundShipmentsResponse,
  GetOutboundShipmentDetailsOptionalParams,
  GetOutboundShipmentDetailsResponse,
  CountOutboundShipmentsOptionalParams,
  CountOutboundShipmentsResponse,
  GetShipEventByIdOptionalParams,
  GetShipEventByIdResponse,
  GetOtherSystemShipmentByIdOptionalParams,
  GetOtherSystemShipmentByIdResponse,
  ListShipmentDetailsOptionalParams,
  ListShipmentDetailsResponse,
  GetShipmentDiagramOptionalParams,
  GetShipmentDiagramOperationResponse,
  GetShipmentMapOptionalParams,
  GetShipmentMapResponse,
  InterestingEndpointOptionalParams,
  InterestingEndpointResponse,
  CreateTradePartnerOptionalParams,
  CreateTradePartnerResponse,
  ListTradePartnersOptionalParams,
  ListTradePartnersOperationResponse,
  GetTradePartnerByIdOptionalParams,
  GetTradePartnerByIdResponse,
  UpdateTradePartnerOptionalParams,
  UpdateTradePartnerResponse,
  ChangeArchivedTradePartnerOptionalParams,
  ChangeArchivedTradePartnerOperationResponse,
  GetTradePartnerStatisticsByIdOptionalParams,
  GetTradePartnerStatisticsByIdResponse,
  ToggleArchiveAllVesselsOptionalParams,
  ToggleArchiveAllVesselsResponse,
  GetUserAccountOptionalParams,
  GetUserAccountResponse,
  ListAccountsFromUserOptionalParams,
  ListAccountsFromUserResponse,
  ChangeAccountOptionalParams,
  ChangeAcceptedTermsOptionalParams,
  ChangePasswordOptionalParams,
  ChangeEmailOptionalParams,
  SetSiteAdminOptionalParams,
  ChangePinnedAccountOptionalParams,
  ChangePersonalInfoOptionalParams,
  ChangePersonalInfoOperationResponse,
  ChangeSettingsOptionalParams,
  ChangeSettingsOperationResponse,
  ChangeRoleOptionalParams,
  ChangeRoleResponse,
  UploadProfilePicture$binaryOptionalParams,
  UploadProfilePicture$multipartOptionalParams,
  UploadProfilePictureOperationResponse,
  DisableMapsOptionalParams,
  DisableMapsResponse,
} from "./models";

export class WebAPIClient extends coreClient.ServiceClient {
  $host: string;

  /**
   * Initializes a new instance of the WebAPIClient class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param $host server parameter
   * @param options The parameter options
   */
  constructor(
    credentials: coreAuth.TokenCredential,
    $host: string,
    options?: WebAPIClientOptionalParams,
  ) {
    if (credentials === undefined) {
      throw new Error("'credentials' cannot be null");
    }
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: WebAPIClientOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
      credential: credentials,
    };

    const packageDetails = `azsdk-js-WebAPIClient/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    if (!options.credentialScopes) {
      options.credentialScopes = [
        "https://wholechainusermigrationdev.onmicrosoft.com/df631b5d-e243-4944-a388-b6667c8fda08/.default",
      ];
    }
    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint: options.endpoint ?? options.baseUri ?? "{$host}",
    };
    super(optionsWithDefaults);

    let bearerTokenAuthenticationPolicyFound: boolean = false;
    if (options?.pipeline && options.pipeline.getOrderedPolicies().length > 0) {
      const pipelinePolicies: coreRestPipeline.PipelinePolicy[] =
        options.pipeline.getOrderedPolicies();
      bearerTokenAuthenticationPolicyFound = pipelinePolicies.some(
        (pipelinePolicy) =>
          pipelinePolicy.name ===
          coreRestPipeline.bearerTokenAuthenticationPolicyName,
      );
    }
    if (
      !options ||
      !options.pipeline ||
      options.pipeline.getOrderedPolicies().length == 0 ||
      !bearerTokenAuthenticationPolicyFound
    ) {
      this.pipeline.removePolicy({
        name: coreRestPipeline.bearerTokenAuthenticationPolicyName,
      });
      this.pipeline.addPolicy(
        coreRestPipeline.bearerTokenAuthenticationPolicy({
          credential: credentials,
          scopes:
            optionsWithDefaults.credentialScopes ??
            `${optionsWithDefaults.endpoint}/.default`,
          challengeCallbacks: {
            authorizeRequestOnChallenge:
              coreClient.authorizeRequestOnClaimChallenge,
          },
        }),
      );
    }
    // Parameter assignments
    this.$host = $host;
  }

  /**
   * Creates a new account in the system.
   * @param options The options parameters.
   */
  createAccount(
    options?: CreateAccountOptionalParams,
  ): Promise<CreateAccountResponse> {
    return this.sendOperationRequest({ options }, createAccountOperationSpec);
  }

  /**
   * Get the company account that matches the currently logged-in user.
   * @param options The options parameters.
   */
  getAccount(options?: GetAccountOptionalParams): Promise<GetAccountResponse> {
    return this.sendOperationRequest({ options }, getAccountOperationSpec);
  }

  /**
   * Update the account of which the currently logged-in user is a member.
   * @param options The options parameters.
   */
  updateAccount(
    options?: UpdateAccountOptionalParams,
  ): Promise<UpdateAccountResponse> {
    return this.sendOperationRequest({ options }, updateAccountOperationSpec);
  }

  /**
   * Get the account based on the provided id.
   * @param id Id of the account.
   * @param options The options parameters.
   */
  getAccountById(
    id: string,
    options?: GetAccountByIdOptionalParams,
  ): Promise<GetAccountByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getAccountByIdOperationSpec,
    );
  }

  /**
   * Invite existing Wholechain or new users to the current account. The inviter must be an account
   * administrator.
   * @param options The options parameters.
   */
  inviteUsersToAccount(
    options?: InviteUsersToAccountOptionalParams,
  ): Promise<InviteUsersToAccountOperationResponse> {
    return this.sendOperationRequest(
      { options },
      inviteUsersToAccountOperationSpec,
    );
  }

  /**
   * Redeem an invite.
   * @param id Id of the invite to redeem.
   * @param options The options parameters.
   */
  redeemInvite(
    id: string,
    options?: RedeemInviteOptionalParams,
  ): Promise<RedeemInviteResponse> {
    return this.sendOperationRequest(
      { id, options },
      redeemInviteOperationSpec,
    );
  }

  /**
   * Delete an existing invite.
   * @param inviteId Id of the invite to delete.
   * @param options The options parameters.
   */
  deleteUserInviteFromAccount(
    inviteId: string,
    options?: DeleteUserInviteFromAccountOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { inviteId, options },
      deleteUserInviteFromAccountOperationSpec,
    );
  }

  /**
   * List participants of the current account.
   * @param options The options parameters.
   */
  listParticipants(
    options?: ListParticipantsOptionalParams,
  ): Promise<ListParticipantsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listParticipantsOperationSpec,
    );
  }

  /**
   * Get the subscription for the current account.
   * @param options The options parameters.
   */
  getSubscription(
    options?: GetSubscriptionOptionalParams,
  ): Promise<GetSubscriptionResponse> {
    return this.sendOperationRequest({ options }, getSubscriptionOperationSpec);
  }

  /**
   * List all accounts for the current user.
   * @param options The options parameters.
   */
  listUserAccounts(
    options?: ListUserAccountsOptionalParams,
  ): Promise<ListUserAccountsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listUserAccountsOperationSpec,
    );
  }

  /**
   * Delete an user from the account. Requestor must be and account administrator.
   * @param accountId The account id to delete the user from.
   * @param userId The user id to be deleted.
   * @param options The options parameters.
   */
  deleteUserFromAccount(
    accountId: string,
    userId: string,
    options?: DeleteUserFromAccountOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { accountId, userId, options },
      deleteUserFromAccountOperationSpec,
    );
  }

  /**
   * Check if an invite is valid.
   * @param id The invite id.
   * @param options The options parameters.
   */
  isValidInvite(
    id: string,
    options?: IsValidInviteOptionalParams,
  ): Promise<IsValidInviteOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      isValidInviteOperationSpec,
    );
  }

  /**
   * Get a template attribute by its id.
   * @param id The template attribute id.
   * @param options The options parameters.
   */
  getTemplateAttributeById(
    id: string,
    options?: GetTemplateAttributeByIdOptionalParams,
  ): Promise<GetTemplateAttributeByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getTemplateAttributeByIdOperationSpec,
    );
  }

  /**
   * Update a template attribute.
   * @param id The ID of the template attribute.
   * @param options The options parameters.
   */
  updateTemplateAttribute(
    id: string,
    options?: UpdateTemplateAttributeOptionalParams,
  ): Promise<UpdateTemplateAttributeResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateTemplateAttributeOperationSpec,
    );
  }

  /**
   * Delete a template attribute.
   * @param id The ID of the template attribute.
   * @param options The options parameters.
   */
  deleteTemplateAttribute(
    id: string,
    options?: DeleteTemplateAttributeOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { id, options },
      deleteTemplateAttributeOperationSpec,
    );
  }

  /**
   * Get a list of all template attributes for the current account.
   * @param options The options parameters.
   */
  listAttributes(
    options?: ListAttributesOptionalParams,
  ): Promise<ListAttributesResponse> {
    return this.sendOperationRequest({ options }, listAttributesOperationSpec);
  }

  /**
   * Create a new template attribute.
   * @param options The options parameters.
   */
  createTemplateAttribute(
    options?: CreateTemplateAttributeOptionalParams,
  ): Promise<CreateTemplateAttributeResponse> {
    return this.sendOperationRequest(
      { options },
      createTemplateAttributeOperationSpec,
    );
  }

  /**
   * Get a list of all Attributes Default Values.
   * @param options The options parameters.
   */
  listAttributesDefaultValues(
    options?: ListAttributesDefaultValuesOptionalParams,
  ): Promise<ListAttributesDefaultValuesResponse> {
    return this.sendOperationRequest(
      { options },
      listAttributesDefaultValuesOperationSpec,
    );
  }

  /**
   * Delete an Attribute Default Value.
   * @param id The ID of the attribute.
   * @param locationId The ID of the location where the default value will be removed.
   * @param options The options parameters.
   */
  deleteAttributeDefaultValue(
    id: string,
    locationId: string,
    options?: DeleteAttributeDefaultValueOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { id, locationId, options },
      deleteAttributeDefaultValueOperationSpec,
    );
  }

  /**
   * Get a list of the business steps.
   * @param options The options parameters.
   */
  listBizSteps(
    options?: ListBizStepsOptionalParams,
  ): Promise<ListBizStepsResponse> {
    return this.sendOperationRequest({ options }, listBizStepsOperationSpec);
  }

  /**
   * Gets the inventory for a specific container.
   * @param id The container id.
   * @param options The options parameters.
   */
  getContainerInventory(
    id: string,
    options?: GetContainerInventoryOptionalParams,
  ): Promise<GetContainerInventoryResponse> {
    return this.sendOperationRequest(
      { id, options },
      getContainerInventoryOperationSpec,
    );
  }

  /**
   * Get a list of the dispositions.
   * @param options The options parameters.
   */
  listDispositions(
    options?: ListDispositionsOptionalParams,
  ): Promise<ListDispositionsResponse> {
    return this.sendOperationRequest(
      { options },
      listDispositionsOperationSpec,
    );
  }

  /**
   * Get the document based on the provided id.
   * @param documentId Id of the document.
   * @param options The options parameters.
   */
  getDocumentById(
    documentId: string,
    options?: GetDocumentByIdOptionalParams,
  ): Promise<GetDocumentByIdResponse> {
    return this.sendOperationRequest(
      { documentId, options },
      getDocumentByIdOperationSpec,
    );
  }

  /**
   * Update a document.
   * @param documentId Id of the document to be updated.
   * @param options The options parameters.
   */
  updateDocument(
    documentId: string,
    options?: UpdateDocumentOptionalParams,
  ): Promise<UpdateDocumentResponse> {
    return this.sendOperationRequest(
      { documentId, options },
      updateDocumentOperationSpec,
    );
  }

  /**
   * @param documentId
   * @param options The options parameters.
   */
  deleteDocumentById(
    documentId: string,
    options?: DeleteDocumentByIdOptionalParams,
  ): Promise<DeleteDocumentByIdResponse> {
    return this.sendOperationRequest(
      { documentId, options },
      deleteDocumentByIdOperationSpec,
    );
  }

  /**
   * Downloads the document based on the provided id.
   * @param documentId Id of the document.
   * @param options The options parameters.
   */
  downloadDocument(
    documentId: string,
    options?: DownloadDocumentOptionalParams,
  ): Promise<DownloadDocumentResponse> {
    return this.sendOperationRequest(
      { documentId, options },
      downloadDocumentOperationSpec,
    );
  }

  /**
   * Create a new document.
   * @param options The options parameters.
   */
  createDocument(
    options?: CreateDocumentOptionalParams,
  ): Promise<CreateDocumentResponse> {
    return this.sendOperationRequest({ options }, createDocumentOperationSpec);
  }

  /**
   * Get list of paginated documents based on the provided filters.
   * @param options The options parameters.
   */
  getPaginatedDocuments(
    options?: GetPaginatedDocumentsOptionalParams,
  ): Promise<GetPaginatedDocumentsResponse> {
    return this.sendOperationRequest(
      { options },
      getPaginatedDocumentsOperationSpec,
    );
  }

  /**
   * Shares one or more documents to another Wholechain account.
   * @param options The options parameters.
   */
  shareDocument(
    options?: ShareDocumentOptionalParams,
  ): Promise<ShareDocumentOperationResponse> {
    return this.sendOperationRequest({ options }, shareDocumentOperationSpec);
  }

  /**
   * Unshare a document from one or more Wholechain accounts.
   * @param options The options parameters.
   */
  unshareDocument(
    options?: UnshareDocumentOptionalParams,
  ): Promise<UnshareDocumentResponse> {
    return this.sendOperationRequest({ options }, unshareDocumentOperationSpec);
  }

  /**
   * Sends an email to the support.
   * @param options The options parameters.
   */
  sendSupportEmail(options?: SendSupportEmailOptionalParams): Promise<void> {
    return this.sendOperationRequest(
      { options },
      sendSupportEmailOperationSpec,
    );
  }

  /** @param options The options parameters. */
  createEpcisData(
    options?: CreateEpcisDataOptionalParams,
  ): Promise<CreateEpcisDataResponse> {
    return this.sendOperationRequest({ options }, createEpcisDataOperationSpec);
  }

  /**
   * Get an event template by its id.
   * @param id Event template id.
   * @param options The options parameters.
   */
  getEventTemplateById(
    id: string,
    options?: GetEventTemplateByIdOptionalParams,
  ): Promise<GetEventTemplateByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventTemplateByIdOperationSpec,
    );
  }

  /**
   * Updated an event template.
   * @param id Event template id.
   * @param options The options parameters.
   */
  updateEventTemplate(
    id: string,
    options?: UpdateEventTemplateOptionalParams,
  ): Promise<UpdateEventTemplateResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateEventTemplateOperationSpec,
    );
  }

  /**
   * Delete an event template.
   * @param id The event template id to be deleted.
   * @param options The options parameters.
   */
  deleteEventTemplate(
    id: string,
    options?: DeleteEventTemplateOptionalParams,
  ): Promise<DeleteEventTemplateResponse> {
    return this.sendOperationRequest(
      { id, options },
      deleteEventTemplateOperationSpec,
    );
  }

  /**
   * Get a list of all event templates for the current account.
   * @param options The options parameters.
   */
  getEventTemplates(
    options?: GetEventTemplatesOptionalParams,
  ): Promise<GetEventTemplatesResponse> {
    return this.sendOperationRequest(
      { options },
      getEventTemplatesOperationSpec,
    );
  }

  /**
   * Add a new event template.
   * @param options The options parameters.
   */
  createEventTemplate(
    options?: CreateEventTemplateOptionalParams,
  ): Promise<CreateEventTemplateResponse> {
    return this.sendOperationRequest(
      { options },
      createEventTemplateOperationSpec,
    );
  }

  /**
   * Share an event template to another Wholechain account.
   * @param templateId The event template id to be shared.
   * @param options The options parameters.
   */
  shareEventTemplate(
    templateId: string,
    options?: ShareEventTemplateOptionalParams,
  ): Promise<ShareEventTemplateResponse> {
    return this.sendOperationRequest(
      { templateId, options },
      shareEventTemplateOperationSpec,
    );
  }

  /**
   * Unshare an event template.
   * @param templateId The event template id to be unshared.
   * @param options The options parameters.
   */
  unshareEventTemplate(
    templateId: string,
    options?: UnshareEventTemplateOptionalParams,
  ): Promise<UnshareEventTemplateResponse> {
    return this.sendOperationRequest(
      { templateId, options },
      unshareEventTemplateOperationSpec,
    );
  }

  /**
   * Get an event based on its id.
   * @param id The event id.
   * @param options The options parameters.
   */
  getEventById(
    id: string,
    options?: GetEventByIdOptionalParams,
  ): Promise<GetEventByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventByIdOperationSpec,
    );
  }

  /**
   * Return a list of events details for the supply chain.
   * @param options The options parameters.
   */
  listEventDetails(
    options?: ListEventDetailsOptionalParams,
  ): Promise<ListEventDetailsResponse> {
    return this.sendOperationRequest(
      { options },
      listEventDetailsOperationSpec,
    );
  }

  /**
   * Return the event diagram for the supply chain.
   * @param eventId The event id.
   * @param options The options parameters.
   */
  getEventDetailsDiagram(
    eventId: string,
    options?: GetEventDetailsDiagramOptionalParams,
  ): Promise<GetEventDetailsDiagramResponse> {
    return this.sendOperationRequest(
      { eventId, options },
      getEventDetailsDiagramOperationSpec,
    );
  }

  /**
   * Return the event map for the supply chain.
   * @param id The event id.
   * @param options The options parameters.
   */
  getEventDetailsMap(
    id: string,
    options?: GetEventDetailsMapOptionalParams,
  ): Promise<GetEventDetailsMapResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventDetailsMapOperationSpec,
    );
  }

  /**
   * Return a list of events based on the simpleEventQuery filters.
   * @param options The options parameters.
   */
  simpleEventQuery(
    options?: SimpleEventQueryOptionalParams,
  ): Promise<SimpleEventQueryResponse> {
    return this.sendOperationRequest(
      { options },
      simpleEventQueryOperationSpec,
    );
  }

  /**
   * Return the number of previous events in the supply chain.
   * @param id Event id.
   * @param options The options parameters.
   */
  getEventHistoryCount(
    id: string,
    options?: GetEventHistoryCountOptionalParams,
  ): Promise<GetEventHistoryCountOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventHistoryCountOperationSpec,
    );
  }

  /**
   * Create one or multiple commission events.
   * @param options The options parameters.
   */
  commission(options?: CommissionOptionalParams): Promise<CommissionResponse> {
    return this.sendOperationRequest({ options }, commissionOperationSpec);
  }

  /**
   * Create a decommission event.
   * @param options The options parameters.
   */
  decommission(
    options?: DecommissionOptionalParams,
  ): Promise<DecommissionResponse> {
    return this.sendOperationRequest({ options }, decommissionOperationSpec);
  }

  /**
   * Create one or multiple observe events.
   * @param options The options parameters.
   */
  observe(options?: ObserveOptionalParams): Promise<ObserveResponse> {
    return this.sendOperationRequest({ options }, observeOperationSpec);
  }

  /**
   * Create an aggregate event.
   * @param options The options parameters.
   */
  aggregate(options?: AggregateOptionalParams): Promise<AggregateResponse> {
    return this.sendOperationRequest({ options }, aggregateOperationSpec);
  }

  /**
   * Create a disaggregate event.
   * @param options The options parameters.
   */
  disaggregate(
    options?: DisaggregateOptionalParams,
  ): Promise<DisaggregateResponse> {
    return this.sendOperationRequest({ options }, disaggregateOperationSpec);
  }

  /**
   * Create a transform event.
   * @param options The options parameters.
   */
  transform(options?: TransformOptionalParams): Promise<TransformResponse> {
    return this.sendOperationRequest({ options }, transformOperationSpec);
  }

  /**
   * Create one or multiple ship events.
   * @param options The options parameters.
   */
  ship(options?: ShipOptionalParams): Promise<ShipResponse> {
    return this.sendOperationRequest({ options }, shipOperationSpec);
  }

  /**
   * Create one or multiple receive events.
   * @param options The options parameters.
   */
  receive(options?: ReceiveOptionalParams): Promise<ReceiveResponse> {
    return this.sendOperationRequest({ options }, receiveOperationSpec);
  }

  /**
   * Delete an event.
   * @param eventId The id of the event to be deleted.
   * @param options The options parameters.
   */
  deleteEvent(
    eventId: string,
    options?: DeleteEventOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { eventId, options },
      deleteEventOperationSpec,
    );
  }

  /** @param options The options parameters. */
  createIntegrationData(
    options?: CreateIntegrationDataOptionalParams,
  ): Promise<CreateIntegrationDataResponse> {
    return this.sendOperationRequest(
      { options },
      createIntegrationDataOperationSpec,
    );
  }

  /**
   * Create one or multiple direct receive events. A direct receive is a receive event started from
   * scratch
   * (like commission events), however it does not have any track from ship or behind, event chain starts
   * from here.
   * @param options The options parameters.
   */
  directReceive(
    options?: DirectReceiveOptionalParams,
  ): Promise<DirectReceiveResponse> {
    return this.sendOperationRequest({ options }, directReceiveOperationSpec);
  }

  /**
   * Get blockchain event data for an event.
   * @param id The event id.
   * @param options The options parameters.
   */
  getBlockchainByEventId(
    id: string,
    options?: GetBlockchainByEventIdOptionalParams,
  ): Promise<GetBlockchainByEventIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getBlockchainByEventIdOperationSpec,
    );
  }

  /**
   * Set the external registry details for a trade partner with an external traceability system (Digital
   * link and outbound API key).
   * @param options The options parameters.
   */
  setExternalRegistryDetails(
    options?: SetExternalRegistryDetailsOptionalParams,
  ): Promise<SetExternalRegistryDetailsResponse> {
    return this.sendOperationRequest(
      { options },
      setExternalRegistryDetailsOperationSpec,
    );
  }

  /**
   * Update the external registry details for a trade partner with an external traceability system.
   * @param options The options parameters.
   */
  updateExternalRegistryDetails(
    options?: UpdateExternalRegistryDetailsOptionalParams,
  ): Promise<UpdateExternalRegistryDetailsResponse> {
    return this.sendOperationRequest(
      { options },
      updateExternalRegistryDetailsOperationSpec,
    );
  }

  /**
   * Get the external Registry details (Connect).
   * @param options The options parameters.
   */
  getExternalRegistryDetails(
    options?: GetExternalRegistryDetailsOptionalParams,
  ): Promise<GetExternalRegistryDetailsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      getExternalRegistryDetailsOperationSpec,
    );
  }

  /**
   * Generate a new inbound API key for a specific trade partner.
   * A new key will be generated on each call.
   * @param tradePartnerId The trade partner id to generate.
   * @param options The options parameters.
   */
  getInboundApiKey(
    tradePartnerId: string,
    options?: GetInboundApiKeyOptionalParams,
  ): Promise<GetInboundApiKeyOperationResponse> {
    return this.sendOperationRequest(
      { tradePartnerId, options },
      getInboundApiKeyOperationSpec,
    );
  }

  /**
   * Test the digital link of a trade partner to make sure it's valid before saving to the database.
   * @param options The options parameters.
   */
  queryDigitalLink(
    options?: QueryDigitalLinkOptionalParams,
  ): Promise<QueryDigitalLinkResponse> {
    return this.sendOperationRequest(
      { options },
      queryDigitalLinkOperationSpec,
    );
  }

  /**
   * Create a new language.
   * @param options The options parameters.
   */
  createLanguage(
    options?: CreateLanguageOptionalParams,
  ): Promise<CreateLanguageResponse> {
    return this.sendOperationRequest({ options }, createLanguageOperationSpec);
  }

  /**
   * Gets all languages from the system.
   * @param languageCode Optional filter by language code.
   * @param options The options parameters.
   */
  listLanguages(
    languageCode: string,
    options?: ListLanguagesOptionalParams,
  ): Promise<ListLanguagesResponse> {
    return this.sendOperationRequest(
      { languageCode, options },
      listLanguagesOperationSpec,
    );
  }

  /**
   * Get a specific language by its code.
   * @param languageCode Language code to filter.
   * @param options The options parameters.
   */
  getLanguage(
    languageCode: string,
    options?: GetLanguageOptionalParams,
  ): Promise<GetLanguageResponse> {
    return this.sendOperationRequest(
      { languageCode, options },
      getLanguageOperationSpec,
    );
  }

  /**
   * Delete a language based on the language code.
   * @param languageCode Language code to delete.
   * @param options The options parameters.
   */
  deleteLanguage(
    languageCode: string,
    options?: DeleteLanguageOptionalParams,
  ): Promise<DeleteLanguageResponse> {
    return this.sendOperationRequest(
      { languageCode, options },
      deleteLanguageOperationSpec,
    );
  }

  /**
   * Create a language attribute, which is a translation record for that specific language.
   * @param options The options parameters.
   */
  createLanguageAttribute(
    options?: CreateLanguageAttributeOptionalParams,
  ): Promise<CreateLanguageAttributeResponse> {
    return this.sendOperationRequest(
      { options },
      createLanguageAttributeOperationSpec,
    );
  }

  /**
   * List all language attribute keys with pagination.
   * @param options The options parameters.
   */
  listAllLanguageAttributes(
    options?: ListAllLanguageAttributesOptionalParams,
  ): Promise<ListAllLanguageAttributesResponse> {
    return this.sendOperationRequest(
      { options },
      listAllLanguageAttributesOperationSpec,
    );
  }

  /**
   * Create a list of language attributes.
   * @param options The options parameters.
   */
  createLanguageAttributeBulk(
    options?: CreateLanguageAttributeBulkOptionalParams,
  ): Promise<CreateLanguageAttributeBulkResponse> {
    return this.sendOperationRequest(
      { options },
      createLanguageAttributeBulkOperationSpec,
    );
  }

  /**
   * Bulk update multiple language attribute keys.
   * @param options The options parameters.
   */
  updateLanguageAttributeBulk(
    options?: UpdateLanguageAttributeBulkOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { options },
      updateLanguageAttributeBulkOperationSpec,
    );
  }

  /**
   * Get a specific language attribute.
   * @param languageCode The attribute's language code.
   * @param attributeName The attribute's name.
   * @param options The options parameters.
   */
  getLanguageAttribute(
    languageCode: string,
    attributeName: string,
    options?: GetLanguageAttributeOptionalParams,
  ): Promise<GetLanguageAttributeResponse> {
    return this.sendOperationRequest(
      { languageCode, attributeName, options },
      getLanguageAttributeOperationSpec,
    );
  }

  /**
   * Delete a language attribute.
   * @param languageCode The language code of the attribute.
   * @param attributeName The name of the attribute.
   * @param options The options parameters.
   */
  deleteLanguageAttribute(
    languageCode: string,
    attributeName: string,
    options?: DeleteLanguageAttributeOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { languageCode, attributeName, options },
      deleteLanguageAttributeOperationSpec,
    );
  }

  /**
   * List the language attributes based on filters. No pagination.
   * @param languageCode The language code.
   * @param options The options parameters.
   */
  listLanguageAttributes(
    languageCode: string,
    options?: ListLanguageAttributesOptionalParams,
  ): Promise<ListLanguageAttributesResponse> {
    return this.sendOperationRequest(
      { languageCode, options },
      listLanguageAttributesOperationSpec,
    );
  }

  /**
   * Update a language attribute based on the attribute name.
   * @param attributeName The attribute name to update.
   * @param options The options parameters.
   */
  updateLanguageAttribute(
    attributeName: string,
    options?: UpdateLanguageAttributeOptionalParams,
  ): Promise<UpdateLanguageAttributeResponse> {
    return this.sendOperationRequest(
      { attributeName, options },
      updateLanguageAttributeOperationSpec,
    );
  }

  /**
   * Get a list of all locations in the current user's account.
   * @param options The options parameters.
   */
  listLocations(
    options?: ListLocationsOptionalParams,
  ): Promise<ListLocationsOperationResponse> {
    return this.sendOperationRequest({ options }, listLocationsOperationSpec);
  }

  /**
   * Create a new location.
   * @param options The options parameters.
   */
  createLocation(
    options?: CreateLocationOptionalParams,
  ): Promise<CreateLocationResponse> {
    return this.sendOperationRequest({ options }, createLocationOperationSpec);
  }

  /**
   * Get a location by its id.
   * @param id The location id.
   * @param options The options parameters.
   */
  getLocationById(
    id: string,
    options?: GetLocationByIdOptionalParams,
  ): Promise<GetLocationByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getLocationByIdOperationSpec,
    );
  }

  /**
   * Update a location.
   * @param id The location id.
   * @param options The options parameters.
   */
  updateLocation(
    id: string,
    options?: UpdateLocationOptionalParams,
  ): Promise<UpdateLocationResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateLocationOperationSpec,
    );
  }

  /**
   * Delete a location in the current account.
   * @param id The location id.
   * @param options The options parameters.
   */
  deleteLocation(
    id: string,
    options?: DeleteLocationOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { id, options },
      deleteLocationOperationSpec,
    );
  }

  /**
   * Verify if any active locations of the trade partner has active inventory.
   * @param options The options parameters.
   */
  hasActiveInventory(
    options?: HasActiveInventoryOptionalParams,
  ): Promise<HasActiveInventoryOperationResponse> {
    return this.sendOperationRequest(
      { options },
      hasActiveInventoryOperationSpec,
    );
  }

  /**
   * Archive/unarchive one or multiple locations.
   * @param options The options parameters.
   */
  toggleArchiveLocation(
    options?: ToggleArchiveLocationOptionalParams,
  ): Promise<ToggleArchiveLocationOperationResponse> {
    return this.sendOperationRequest(
      { options },
      toggleArchiveLocationOperationSpec,
    );
  }

  /**
   * Share one or multiple locations to another Wholechain account(s).
   * @param options The options parameters.
   */
  shareLocation(
    options?: ShareLocationOptionalParams,
  ): Promise<ShareLocationResponse> {
    return this.sendOperationRequest({ options }, shareLocationOperationSpec);
  }

  /**
   * Get statistics related to a location, including event details that happened at the location.
   * @param id The location id.
   * @param options The options parameters.
   */
  getLocationStatisticsById(
    id: string,
    options?: GetLocationStatisticsByIdOptionalParams,
  ): Promise<GetLocationStatisticsByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getLocationStatisticsByIdOperationSpec,
    );
  }

  /**
   * @param pgln
   * @param options The options parameters.
   */
  masterDataPgln(
    pgln: string,
    options?: MasterDataPglnOptionalParams,
  ): Promise<MasterDataPglnResponse> {
    return this.sendOperationRequest(
      { pgln, options },
      masterDataPglnOperationSpec,
    );
  }

  /**
   * @param gln
   * @param options The options parameters.
   */
  masterDataGLN(
    gln: string,
    options?: MasterDataGLNOptionalParams,
  ): Promise<MasterDataGLNResponse> {
    return this.sendOperationRequest(
      { gln, options },
      masterDataGLNOperationSpec,
    );
  }

  /**
   * @param gtin
   * @param options The options parameters.
   */
  masterDataGtin(
    gtin: string,
    options?: MasterDataGtinOptionalParams,
  ): Promise<MasterDataGtinResponse> {
    return this.sendOperationRequest(
      { gtin, options },
      masterDataGtinOperationSpec,
    );
  }

  /**
   * @param gtin
   * @param lotSerial
   * @param options The options parameters.
   */
  masterDataGtinLotSerial(
    gtin: string,
    lotSerial: string,
    options?: MasterDataGtinLotSerialOptionalParams,
  ): Promise<MasterDataGtinLotSerialResponse> {
    return this.sendOperationRequest(
      { gtin, lotSerial, options },
      masterDataGtinLotSerialOperationSpec,
    );
  }

  /**
   * Return a list of network response, which is a record that includes some details of the location, its
   * trade partner and the invite (if it exists).
   * @param options The options parameters.
   */
  listNetworks(
    options?: ListNetworksOptionalParams,
  ): Promise<ListNetworksResponse> {
    return this.sendOperationRequest({ options }, listNetworksOperationSpec);
  }

  /**
   * List the network invites for the current account.
   * @param options The options parameters.
   */
  listNetworkInvites(
    options?: ListNetworkInvitesOptionalParams,
  ): Promise<ListNetworkInvitesResponse> {
    return this.sendOperationRequest(
      { options },
      listNetworkInvitesOperationSpec,
    );
  }

  /**
   * Create an invite to another Wholechain account.
   * @param options The options parameters.
   */
  createNetworkInvite(
    options?: CreateNetworkInviteOptionalParams,
  ): Promise<CreateNetworkInviteResponse> {
    return this.sendOperationRequest(
      { options },
      createNetworkInviteOperationSpec,
    );
  }

  /**
   * Get a network invite by ID.
   * @param id Id of the network invite.
   * @param options The options parameters.
   */
  getNetworkInviteById(
    id: string,
    options?: GetNetworkInviteByIdOptionalParams,
  ): Promise<GetNetworkInviteByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getNetworkInviteByIdOperationSpec,
    );
  }

  /**
   * Update an existing network invite.
   * @param id The invite id.
   * @param options The options parameters.
   */
  updateNetworkInvite(
    id: string,
    options?: UpdateNetworkInviteOptionalParams,
  ): Promise<UpdateNetworkInviteResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateNetworkInviteOperationSpec,
    );
  }

  /**
   * Delete a network invite.
   * @param id The id of the invite.
   * @param options The options parameters.
   */
  deleteNetworkInvite(
    id: string,
    options?: DeleteNetworkInviteOptionalParams,
  ): Promise<DeleteNetworkInviteResponse> {
    return this.sendOperationRequest(
      { id, options },
      deleteNetworkInviteOperationSpec,
    );
  }

  /**
   * List all notifications for the current user and account.
   * @param options The options parameters.
   */
  listNotifications(
    options?: ListNotificationsOptionalParams,
  ): Promise<ListNotificationsResponse> {
    return this.sendOperationRequest(
      { options },
      listNotificationsOperationSpec,
    );
  }

  /**
   * Change one or multiple notification status to read.
   * @param options The options parameters.
   */
  changeToReadNotifications(
    options?: ChangeToReadNotificationsOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { options },
      changeToReadNotificationsOperationSpec,
    );
  }

  /**
   * Return a list of the first events of all orphan supply chains of the account.
   * @param options The options parameters.
   */
  listOrphans(
    options?: ListOrphansOptionalParams,
  ): Promise<ListOrphansResponse> {
    return this.sendOperationRequest({ options }, listOrphansOperationSpec);
  }

  /**
   * Return a list of suggestions to fix the orphan supply chain.
   * @param options The options parameters.
   */
  listOrphansSuggestions(
    options?: ListOrphansSuggestionsOptionalParams,
  ): Promise<ListOrphansSuggestionsResponse> {
    return this.sendOperationRequest(
      { options },
      listOrphansSuggestionsOperationSpec,
    );
  }

  /**
   * Connects an orphan to its respective supply chain.
   * @param orphanInstanceId
   * @param instanceId
   * @param options The options parameters.
   */
  resolveOrphan(
    orphanInstanceId: string,
    instanceId: string,
    options?: ResolveOrphanOptionalParams,
  ): Promise<ResolveOrphanResponse> {
    return this.sendOperationRequest(
      { orphanInstanceId, instanceId, options },
      resolveOrphanOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param options The options parameters.
   */
  getIntegrationEvents(
    integrationId: string,
    options?: GetIntegrationEventsOptionalParams,
  ): Promise<GetIntegrationEventsResponse> {
    return this.sendOperationRequest(
      { integrationId, options },
      getIntegrationEventsOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param eventId
   * @param options The options parameters.
   */
  getExecutionCheckDiagnostics(
    integrationId: string,
    eventId: string,
    options?: GetExecutionCheckDiagnosticsOptionalParams,
  ): Promise<GetExecutionCheckDiagnosticsResponse> {
    return this.sendOperationRequest(
      { integrationId, eventId, options },
      getExecutionCheckDiagnosticsOperationSpec,
    );
  }

  /** @param options The options parameters. */
  getIntegrations(
    options?: GetIntegrationsOptionalParams,
  ): Promise<GetIntegrationsResponse> {
    return this.sendOperationRequest({ options }, getIntegrationsOperationSpec);
  }

  /** @param options The options parameters. */
  createIntegration(
    options?: CreateIntegrationOptionalParams,
  ): Promise<CreateIntegrationOperationResponse> {
    return this.sendOperationRequest(
      { options },
      createIntegrationOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param options The options parameters.
   */
  getIntegrationById(
    integrationId: string,
    options?: GetIntegrationByIdOptionalParams,
  ): Promise<GetIntegrationByIdResponse> {
    return this.sendOperationRequest(
      { integrationId, options },
      getIntegrationByIdOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param options The options parameters.
   */
  updateIntegration(
    integrationId: string,
    options?: UpdateIntegrationOptionalParams,
  ): Promise<UpdateIntegrationResponse> {
    return this.sendOperationRequest(
      { integrationId, options },
      updateIntegrationOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param options The options parameters.
   */
  deleteIntegration(
    integrationId: string,
    options?: DeleteIntegrationOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { integrationId, options },
      deleteIntegrationOperationSpec,
    );
  }

  /** @param options The options parameters. */
  getDefaultConfigurationOptions(
    options?: GetDefaultConfigurationOptionsOptionalParams,
  ): Promise<GetDefaultConfigurationOptionsResponse> {
    return this.sendOperationRequest(
      { options },
      getDefaultConfigurationOptionsOperationSpec,
    );
  }

  /** @param options The options parameters. */
  createPreConfiguredOutboundIntegration(
    options?: CreatePreConfiguredOutboundIntegrationOptionalParams,
  ): Promise<CreatePreConfiguredOutboundIntegrationResponse> {
    return this.sendOperationRequest(
      { options },
      createPreConfiguredOutboundIntegrationOperationSpec,
    );
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  getDefaultConfigurations(
    id: number,
    options?: GetDefaultConfigurationsOptionalParams,
  ): Promise<GetDefaultConfigurationsResponse> {
    return this.sendOperationRequest(
      { id, options },
      getDefaultConfigurationsOperationSpec,
    );
  }

  /** @param options The options parameters. */
  getTriggerSpecifications(
    options?: GetTriggerSpecificationsOptionalParams,
  ): Promise<GetTriggerSpecificationsResponse> {
    return this.sendOperationRequest(
      { options },
      getTriggerSpecificationsOperationSpec,
    );
  }

  /**
   * @param triggerSpecificationGuid
   * @param options The options parameters.
   */
  getTriggerSpecificationCriteriaOptions(
    triggerSpecificationGuid: string,
    options?: GetTriggerSpecificationCriteriaOptionsOptionalParams,
  ): Promise<GetTriggerSpecificationCriteriaOptionsResponse> {
    return this.sendOperationRequest(
      { triggerSpecificationGuid, options },
      getTriggerSpecificationCriteriaOptionsOperationSpec,
    );
  }

  /**
   * @param defaultConfiguration
   * @param options The options parameters.
   */
  getDefaultMappableElements(
    defaultConfiguration: number,
    options?: GetDefaultMappableElementsOptionalParams,
  ): Promise<GetDefaultMappableElementsResponse> {
    return this.sendOperationRequest(
      { defaultConfiguration, options },
      getDefaultMappableElementsOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param options The options parameters.
   */
  createTriggerCriteria(
    integrationId: string,
    options?: CreateTriggerCriteriaOptionalParams,
  ): Promise<CreateTriggerCriteriaResponse> {
    return this.sendOperationRequest(
      { integrationId, options },
      createTriggerCriteriaOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param criteriaId
   * @param options The options parameters.
   */
  deleteTriggerCriteria(
    integrationId: string,
    criteriaId: string,
    options?: DeleteTriggerCriteriaOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { integrationId, criteriaId, options },
      deleteTriggerCriteriaOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param criterionId
   * @param options The options parameters.
   */
  createTriggerCriteriaValue(
    integrationId: string,
    criterionId: string,
    options?: CreateTriggerCriteriaValueOptionalParams,
  ): Promise<CreateTriggerCriteriaValueResponse> {
    return this.sendOperationRequest(
      { integrationId, criterionId, options },
      createTriggerCriteriaValueOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param criterionId
   * @param criterionTriggerValueId
   * @param options The options parameters.
   */
  deleteTriggerCriteriaValue(
    integrationId: string,
    criterionId: string,
    criterionTriggerValueId: string,
    options?: DeleteTriggerCriteriaValueOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { integrationId, criterionId, criterionTriggerValueId, options },
      deleteTriggerCriteriaValueOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param options The options parameters.
   */
  createElementMap(
    integrationId: string,
    options?: CreateElementMapOptionalParams,
  ): Promise<CreateElementMapResponse> {
    return this.sendOperationRequest(
      { integrationId, options },
      createElementMapOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param elementMapId
   * @param options The options parameters.
   */
  deleteElementMap(
    integrationId: string,
    elementMapId: string,
    options?: DeleteElementMapOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { integrationId, elementMapId, options },
      deleteElementMapOperationSpec,
    );
  }

  /** @param options The options parameters. */
  listPluginConfigurations(
    options?: ListPluginConfigurationsOptionalParams,
  ): Promise<ListPluginConfigurationsResponse> {
    return this.sendOperationRequest(
      { options },
      listPluginConfigurationsOperationSpec,
    );
  }

  /**
   * @param integrationId
   * @param options The options parameters.
   */
  saveIntegrationFtpSecrets(
    integrationId: string,
    options?: SaveIntegrationFtpSecretsOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { integrationId, options },
      saveIntegrationFtpSecretsOperationSpec,
    );
  }

  /**
   * Create a new product.
   * @param options The options parameters.
   */
  createProduct(
    options?: CreateProductOptionalParams,
  ): Promise<CreateProductResponse> {
    return this.sendOperationRequest({ options }, createProductOperationSpec);
  }

  /**
   * Get a list of products based on the filters.
   * @param options The options parameters.
   */
  getPaginatedProducts(
    options?: GetPaginatedProductsOptionalParams,
  ): Promise<GetPaginatedProductsResponse> {
    return this.sendOperationRequest(
      { options },
      getPaginatedProductsOperationSpec,
    );
  }

  /**
   * Get a product based on the id.
   * @param id The product id.
   * @param options The options parameters.
   */
  getProductById(
    id: string,
    options?: GetProductByIdOptionalParams,
  ): Promise<GetProductByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getProductByIdOperationSpec,
    );
  }

  /**
   * Update a product.
   * @param id The product id.
   * @param options The options parameters.
   */
  updateProduct(
    id: string,
    options?: UpdateProductOptionalParams,
  ): Promise<UpdateProductResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateProductOperationSpec,
    );
  }

  /**
   * Get paginated inventory of product. An inventory record can also be called a product instance.
   * @param id The product id.
   * @param options The options parameters.
   */
  getPaginatedProductInventory(
    id: string,
    options?: GetPaginatedProductInventoryOptionalParams,
  ): Promise<GetPaginatedProductInventoryResponse> {
    return this.sendOperationRequest(
      { id, options },
      getPaginatedProductInventoryOperationSpec,
    );
  }

  /**
   * Get paginated inventory of a lot. An inventory record can also be called a product instance.
   * @param lotSerial The lot serial number.
   * @param options The options parameters.
   */
  getPaginatedLotInventory(
    lotSerial: string,
    options?: GetPaginatedLotInventoryOptionalParams,
  ): Promise<GetPaginatedLotInventoryResponse> {
    return this.sendOperationRequest(
      { lotSerial, options },
      getPaginatedLotInventoryOperationSpec,
    );
  }

  /**
   * Add one or multiple sub-products for a specific product.
   * @param productId The parent product id.
   * @param options The options parameters.
   */
  createSubProduct(
    productId: string,
    options?: CreateSubProductOptionalParams,
  ): Promise<CreateSubProductResponse> {
    return this.sendOperationRequest(
      { productId, options },
      createSubProductOperationSpec,
    );
  }

  /**
   * Remove a sub-product from its parent product.
   * @param productId The parent product id.
   * @param subProductId The sub-product id to be removed.
   * @param options The options parameters.
   */
  deleteSubProduct(
    productId: string,
    subProductId: string,
    options?: DeleteSubProductOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { productId, subProductId, options },
      deleteSubProductOperationSpec,
    );
  }

  /**
   * Archive or unarchive a product.
   * @param id The product id.
   * @param options The options parameters.
   */
  changeArchivedProduct(
    id: string,
    options?: ChangeArchivedProductOptionalParams,
  ): Promise<ChangeArchivedProductOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      changeArchivedProductOperationSpec,
    );
  }

  /**
   * Get a list of products for global search field based on the filters.
   * @param options The options parameters.
   */
  getGlobalSearchPaginatedProducts(
    options?: GetGlobalSearchPaginatedProductsOptionalParams,
  ): Promise<GetGlobalSearchPaginatedProductsResponse> {
    return this.sendOperationRequest(
      { options },
      getGlobalSearchPaginatedProductsOperationSpec,
    );
  }

  /** @param options The options parameters. */
  uploadProducts(
    options?: UploadProductsOptionalParams,
  ): Promise<UploadProductsResponse> {
    return this.sendOperationRequest({ options }, uploadProductsOperationSpec);
  }

  /**
   * Get all shipments based on the provided filters.
   * @param options The options parameters.
   */
  getPaginatedShipments(
    options?: GetPaginatedShipmentsOptionalParams,
  ): Promise<GetPaginatedShipmentsResponse> {
    return this.sendOperationRequest(
      { options },
      getPaginatedShipmentsOperationSpec,
    );
  }

  /**
   * Get a summary of the shipments based on the provided filters.
   * @param options The options parameters.
   */
  getShipmentsSummary(
    options?: GetShipmentsSummaryOptionalParams,
  ): Promise<GetShipmentsSummaryOperationResponse> {
    return this.sendOperationRequest(
      { options },
      getShipmentsSummaryOperationSpec,
    );
  }

  /**
   * Create an external shipment.
   * @param options The options parameters.
   */
  createExternalShipment(
    options?: CreateExternalShipmentOptionalParams,
  ): Promise<CreateExternalShipmentResponse> {
    return this.sendOperationRequest(
      { options },
      createExternalShipmentOperationSpec,
    );
  }

  /**
   * List all external shipments based on the provided filters. These are shipments from another
   * traceability system.
   * @param options The options parameters.
   */
  listExternalShipments(
    options?: ListExternalShipmentsOptionalParams,
  ): Promise<ListExternalShipmentsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listExternalShipmentsOperationSpec,
    );
  }

  /**
   * Get external shipment to be used by capture status endpoint.
   * @param captureId The id of the capture of the external shipment.
   * @param options The options parameters.
   */
  getExternalShipmentByCapture(
    captureId: string,
    options?: GetExternalShipmentByCaptureOptionalParams,
  ): Promise<GetExternalShipmentByCaptureResponse> {
    return this.sendOperationRequest(
      { captureId, options },
      getExternalShipmentByCaptureOperationSpec,
    );
  }

  /**
   * Get all inbound shipments based on the provided filters.
   * @param options The options parameters.
   */
  getPaginatedInboundShipments(
    options?: GetPaginatedInboundShipmentsOptionalParams,
  ): Promise<GetPaginatedInboundShipmentsResponse> {
    return this.sendOperationRequest(
      { options },
      getPaginatedInboundShipmentsOperationSpec,
    );
  }

  /**
   * Get all the other system inbound shipments based on the provided filters.
   * @param options The options parameters.
   */
  getPaginatedOtherSystemShipments(
    options?: GetPaginatedOtherSystemShipmentsOptionalParams,
  ): Promise<GetPaginatedOtherSystemShipmentsResponse> {
    return this.sendOperationRequest(
      { options },
      getPaginatedOtherSystemShipmentsOperationSpec,
    );
  }

  /**
   * Get inbound shipments details. Including lots and SSCCs.
   * @param shipmentIds Array of Uuid
   * @param options The options parameters.
   */
  getInboundShipmentDetails(
    shipmentIds: string[],
    options?: GetInboundShipmentDetailsOptionalParams,
  ): Promise<GetInboundShipmentDetailsResponse> {
    return this.sendOperationRequest(
      { shipmentIds, options },
      getInboundShipmentDetailsOperationSpec,
    );
  }

  /**
   * Return the amount (count) of inbound shipments separating by internal, wholechain and other systems
   * shipments.
   * @param options The options parameters.
   */
  countInboundShipments(
    options?: CountInboundShipmentsOptionalParams,
  ): Promise<CountInboundShipmentsResponse> {
    return this.sendOperationRequest(
      { options },
      countInboundShipmentsOperationSpec,
    );
  }

  /**
   * Get all outbound shipments based on the provided filters.
   * @param options The options parameters.
   */
  getPaginatedOutboundShipments(
    options?: GetPaginatedOutboundShipmentsOptionalParams,
  ): Promise<GetPaginatedOutboundShipmentsResponse> {
    return this.sendOperationRequest(
      { options },
      getPaginatedOutboundShipmentsOperationSpec,
    );
  }

  /**
   * Get outbound shipments details. Including lots and SSCCs.
   * @param shipmentIds Array of Uuid
   * @param options The options parameters.
   */
  getOutboundShipmentDetails(
    shipmentIds: string[],
    options?: GetOutboundShipmentDetailsOptionalParams,
  ): Promise<GetOutboundShipmentDetailsResponse> {
    return this.sendOperationRequest(
      { shipmentIds, options },
      getOutboundShipmentDetailsOperationSpec,
    );
  }

  /**
   * Return the amount (count) of outbound shipments separating by internal, wholechain and other systems
   * shipments.
   * @param options The options parameters.
   */
  countOutboundShipments(
    options?: CountOutboundShipmentsOptionalParams,
  ): Promise<CountOutboundShipmentsResponse> {
    return this.sendOperationRequest(
      { options },
      countOutboundShipmentsOperationSpec,
    );
  }

  /**
   * Get a ship event based on its id.
   * @param id The event id.
   * @param options The options parameters.
   */
  getShipEventById(
    id: string,
    options?: GetShipEventByIdOptionalParams,
  ): Promise<GetShipEventByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getShipEventByIdOperationSpec,
    );
  }

  /**
   * Get a list of inbound shipment details based on the external system shipment ids.
   * @param shipmentIds Array of Uuid
   * @param options The options parameters.
   */
  getOtherSystemShipmentById(
    shipmentIds: string[],
    options?: GetOtherSystemShipmentByIdOptionalParams,
  ): Promise<GetOtherSystemShipmentByIdResponse> {
    return this.sendOperationRequest(
      { shipmentIds, options },
      getOtherSystemShipmentByIdOperationSpec,
    );
  }

  /**
   * Return a list of events details for the supply chain.
   * @param eventId The event id.
   * @param options The options parameters.
   */
  listShipmentDetails(
    eventId: string,
    options?: ListShipmentDetailsOptionalParams,
  ): Promise<ListShipmentDetailsResponse> {
    return this.sendOperationRequest(
      { eventId, options },
      listShipmentDetailsOperationSpec,
    );
  }

  /**
   * Return the event diagram for the shipment.
   * @param eventId The event id.
   * @param options The options parameters.
   */
  getShipmentDiagram(
    eventId: string,
    options?: GetShipmentDiagramOptionalParams,
  ): Promise<GetShipmentDiagramOperationResponse> {
    return this.sendOperationRequest(
      { eventId, options },
      getShipmentDiagramOperationSpec,
    );
  }

  /**
   * Return the map for the shipment.
   * @param eventId The event id.
   * @param options The options parameters.
   */
  getShipmentMap(
    eventId: string,
    options?: GetShipmentMapOptionalParams,
  ): Promise<GetShipmentMapResponse> {
    return this.sendOperationRequest(
      { eventId, options },
      getShipmentMapOperationSpec,
    );
  }

  /**
   * Don't use this.
   * @param options The options parameters.
   */
  interestingEndpoint(
    options?: InterestingEndpointOptionalParams,
  ): Promise<InterestingEndpointResponse> {
    return this.sendOperationRequest(
      { options },
      interestingEndpointOperationSpec,
    );
  }

  /**
   * Create a new trade partner in the current account.
   * @param options The options parameters.
   */
  createTradePartner(
    options?: CreateTradePartnerOptionalParams,
  ): Promise<CreateTradePartnerResponse> {
    return this.sendOperationRequest(
      { options },
      createTradePartnerOperationSpec,
    );
  }

  /**
   * Get a list of trade partners of the current account.
   * @param options The options parameters.
   */
  listTradePartners(
    options?: ListTradePartnersOptionalParams,
  ): Promise<ListTradePartnersOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listTradePartnersOperationSpec,
    );
  }

  /**
   * Get the trade partner based on its id.
   * @param id The trade partner id.
   * @param options The options parameters.
   */
  getTradePartnerById(
    id: string,
    options?: GetTradePartnerByIdOptionalParams,
  ): Promise<GetTradePartnerByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getTradePartnerByIdOperationSpec,
    );
  }

  /**
   * Update an existing trade partner.
   * @param id The trade partner id.
   * @param options The options parameters.
   */
  updateTradePartner(
    id: string,
    options?: UpdateTradePartnerOptionalParams,
  ): Promise<UpdateTradePartnerResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateTradePartnerOperationSpec,
    );
  }

  /**
   * Archive or unarchive a trade partner.
   * @param id The trade partner id.
   * @param options The options parameters.
   */
  changeArchivedTradePartner(
    id: string,
    options?: ChangeArchivedTradePartnerOptionalParams,
  ): Promise<ChangeArchivedTradePartnerOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      changeArchivedTradePartnerOperationSpec,
    );
  }

  /**
   * Get statistics related to a trade partner, including event details that happened at the trade
   * partner's locations.
   * @param id Trade partner id.
   * @param options The options parameters.
   */
  getTradePartnerStatisticsById(
    id: string,
    options?: GetTradePartnerStatisticsByIdOptionalParams,
  ): Promise<GetTradePartnerStatisticsByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getTradePartnerStatisticsByIdOperationSpec,
    );
  }

  /**
   * Archive or unarchive all vessel locations of one or more trade partners.
   * @param options The options parameters.
   */
  toggleArchiveAllVessels(
    options?: ToggleArchiveAllVesselsOptionalParams,
  ): Promise<ToggleArchiveAllVesselsResponse> {
    return this.sendOperationRequest(
      { options },
      toggleArchiveAllVesselsOperationSpec,
    );
  }

  /**
   * Get the current user or an user by the id.
   * @param options The options parameters.
   */
  getUserAccount(
    options?: GetUserAccountOptionalParams,
  ): Promise<GetUserAccountResponse> {
    return this.sendOperationRequest({ options }, getUserAccountOperationSpec);
  }

  /**
   * Get a list of the current user's accounts.
   * @param options The options parameters.
   */
  listAccountsFromUser(
    options?: ListAccountsFromUserOptionalParams,
  ): Promise<ListAccountsFromUserResponse> {
    return this.sendOperationRequest(
      { options },
      listAccountsFromUserOperationSpec,
    );
  }

  /**
   * Change the current account for the logged-in user.
   * @param options The options parameters.
   */
  changeAccount(options?: ChangeAccountOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, changeAccountOperationSpec);
  }

  /**
   * Accept or reject the terms and conditions for the current user.
   * @param options The options parameters.
   */
  changeAcceptedTerms(
    options?: ChangeAcceptedTermsOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { options },
      changeAcceptedTermsOperationSpec,
    );
  }

  /**
   * Change the password of the current user.
   * @param options The options parameters.
   */
  changePassword(options?: ChangePasswordOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, changePasswordOperationSpec);
  }

  /**
   * Change the email of the current user.
   * @param options The options parameters.
   */
  changeEmail(options?: ChangeEmailOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, changeEmailOperationSpec);
  }

  /**
   * Change the siteAdmin property of an user.
   * @param userId The userId.
   * @param options The options parameters.
   */
  setSiteAdmin(
    userId: string,
    options?: SetSiteAdminOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { userId, options },
      setSiteAdminOperationSpec,
    );
  }

  /**
   * Change the pinned account for the current user.
   * @param options The options parameters.
   */
  changePinnedAccount(
    options?: ChangePinnedAccountOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { options },
      changePinnedAccountOperationSpec,
    );
  }

  /**
   * Change the personal information of the current user.
   * @param options The options parameters.
   */
  changePersonalInfo(
    options?: ChangePersonalInfoOptionalParams,
  ): Promise<ChangePersonalInfoOperationResponse> {
    return this.sendOperationRequest(
      { options },
      changePersonalInfoOperationSpec,
    );
  }

  /**
   * Change the settings for the current user.
   * @param options The options parameters.
   */
  changeSettings(
    options?: ChangeSettingsOptionalParams,
  ): Promise<ChangeSettingsOperationResponse> {
    return this.sendOperationRequest({ options }, changeSettingsOperationSpec);
  }

  /**
   * Change the role of a speficic user in the current account. To be used by account administrators
   * only.
   * @param userId User id.
   * @param options The options parameters.
   */
  changeRole(
    userId: string,
    options?: ChangeRoleOptionalParams,
  ): Promise<ChangeRoleResponse> {
    return this.sendOperationRequest(
      { userId, options },
      changeRoleOperationSpec,
    );
  }

  /**
   * Update the current user profile picture.
   * @param contentType Upload file type
   * @param options The options parameters.
   */
  uploadProfilePicture(
    contentType: "application/octet-stream",
    options?: UploadProfilePicture$binaryOptionalParams,
  ): Promise<UploadProfilePictureOperationResponse>;
  /**
   * Update the current user profile picture.
   * @param contentType Body Parameter content-type
   * @param options The options parameters.
   */
  uploadProfilePicture(
    contentType: "multipart/form-data",
    options?: UploadProfilePicture$multipartOptionalParams,
  ): Promise<UploadProfilePictureOperationResponse>;
  /**
   * Update the current user profile picture.
   * @param args Includes all the parameters for this operation.
   */
  uploadProfilePicture(
    ...args:
      | ["application/octet-stream", UploadProfilePicture$binaryOptionalParams?]
      | ["multipart/form-data", UploadProfilePicture$multipartOptionalParams?]
  ): Promise<UploadProfilePictureOperationResponse> {
    let operationSpec: coreClient.OperationSpec;
    let operationArguments: coreClient.OperationArguments;
    let options;
    if (args[0] === "application/octet-stream") {
      operationSpec = uploadProfilePicture$binaryOperationSpec;
      operationArguments = { contentType: args[0], options: args[1] };
      options = args[1];
    } else if (args[0] === "multipart/form-data") {
      operationSpec = uploadProfilePicture$multipartOperationSpec;
      operationArguments = { contentType: args[0], options: args[1] };
      options = args[1];
    } else {
      throw new TypeError(
        `"contentType" must be a valid value but instead was "${args[0]}".`,
      );
    }
    operationArguments.options = options || {};
    return this.sendOperationRequest(operationArguments, operationSpec);
  }

  /**
   * Check if the user should have maps feature disabled.
   * @param options The options parameters.
   */
  disableMaps(
    options?: DisableMapsOptionalParams,
  ): Promise<DisableMapsResponse> {
    return this.sendOperationRequest({ options }, disableMapsOperationSpec);
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const createAccountOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.AccountResponse,
    },
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getAccountOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.AccountResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateAccountOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.AccountResponse,
    },
  },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getAccountByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.AccountResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const inviteUsersToAccountOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts/invites",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.InviteUsersToAccountResponse,
    },
  },
  requestBody: Parameters.body2,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const redeemInviteOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts/invites/{id}/redeem",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.InviteUsersToAccountResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const deleteUserInviteFromAccountOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts/invites/{inviteId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.inviteId],
  serializer,
};
const listParticipantsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts/participants",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListParticipantsResponse,
    },
  },
  queryParameters: [
    Parameters.name,
    Parameters.email,
    Parameters.role,
    Parameters.status,
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getSubscriptionOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts/Subscription",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.FullSubscriptionResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listUserAccountsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts/UserAccounts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListUserAccountsResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const deleteUserFromAccountOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts/{accountId}/users/{userId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.accountId, Parameters.userId],
  serializer,
};
const isValidInviteOperationSpec: coreClient.OperationSpec = {
  path: "/api/Accounts/invites/{id}/isValid",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.IsValidInviteResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const getTemplateAttributeByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Attributes/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.AttributeContract,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateTemplateAttributeOperationSpec: coreClient.OperationSpec = {
  path: "/api/Attributes/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.AttributeContract,
    },
  },
  requestBody: Parameters.body3,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteTemplateAttributeOperationSpec: coreClient.OperationSpec = {
  path: "/api/Attributes/{id}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer,
};
const listAttributesOperationSpec: coreClient.OperationSpec = {
  path: "/api/Attributes",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListAttributesPaginatedResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.locationIds,
    Parameters.partnerIds,
    Parameters.fieldsFieldName,
    Parameters.fieldsPropertyName,
    Parameters.fieldsFieldTypes,
    Parameters.values,
    Parameters.valuesDefaultValue,
    Parameters.namespaces,
    Parameters.locationName,
    Parameters.propertyLocations,
    Parameters.hidden,
    Parameters.expandValues,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createTemplateAttributeOperationSpec: coreClient.OperationSpec = {
  path: "/api/Attributes",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.AttributeContract,
    },
  },
  requestBody: Parameters.body4,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const listAttributesDefaultValuesOperationSpec: coreClient.OperationSpec = {
  path: "/api/Attributes/DefaultValues",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListAttributesDefaultValuesPaginatedResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.partnerId,
    Parameters.field,
    Parameters.propertyName,
    Parameters.types,
    Parameters.values1,
    Parameters.defaultValue,
    Parameters.location,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const deleteAttributeDefaultValueOperationSpec: coreClient.OperationSpec = {
  path: "/api/Attributes/{id}/DefaultValues/{locationId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.id, Parameters.locationId],
  serializer,
};
const listBizStepsOperationSpec: coreClient.OperationSpec = {
  path: "/api/BizSteps",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListBizStepResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getContainerInventoryOperationSpec: coreClient.OperationSpec = {
  path: "/api/Containers/{id}/Inventory",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedContainerInventoryResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
  ],
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const listDispositionsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Dispositions",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListDispositionResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getDocumentByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Documents/{documentId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.DocumentResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.documentId],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateDocumentOperationSpec: coreClient.OperationSpec = {
  path: "/api/Documents/{documentId}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.DocumentResponse,
    },
  },
  requestBody: Parameters.body5,
  urlParameters: [Parameters.$host, Parameters.documentId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteDocumentByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Documents/{documentId}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: { type: { name: "Uuid" } },
    },
  },
  urlParameters: [Parameters.$host, Parameters.documentId],
  headerParameters: [Parameters.accept],
  serializer,
};
const downloadDocumentOperationSpec: coreClient.OperationSpec = {
  path: "/api/Documents/{documentId}/download",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Stream" },
        serializedName: "parsedResponse",
      },
    },
  },
  urlParameters: [Parameters.$host, Parameters.documentId],
  headerParameters: [Parameters.accept1],
  serializer,
};
const createDocumentOperationSpec: coreClient.OperationSpec = {
  path: "/api/Documents",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.DocumentResponse,
    },
  },
  formDataParameters: [
    Parameters.title,
    Parameters.file,
    Parameters.privacy,
    Parameters.blockchain,
    Parameters.documentType,
    Parameters.documentTypeName,
    Parameters.expirationDate,
    Parameters.expirationAlertDate,
    Parameters.locationsIds,
    Parameters.productsIds,
    Parameters.tradePartnersIds,
    Parameters.purchaseOrderNumber,
    Parameters.certificationStandard,
    Parameters.certificationAgency,
    Parameters.certificationId,
    Parameters.shipFrom,
    Parameters.shipTo,
    Parameters.sid,
    Parameters.cid,
    Parameters.fob,
    Parameters.shipDate,
    Parameters.carrier,
    Parameters.items,
    Parameters.quantitiesOfShippedItems,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType1, Parameters.accept2],
  serializer,
};
const getPaginatedDocumentsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Documents",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedDocumentsResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.locationIds,
    Parameters.title1,
    Parameters.documentType1,
    Parameters.tradePartnerIds,
    Parameters.productIds,
    Parameters.sharedWith,
    Parameters.privacy1,
    Parameters.showArchived,
    Parameters.productId,
    Parameters.eventId,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const shareDocumentOperationSpec: coreClient.OperationSpec = {
  path: "/api/Documents/share",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ShareDocumentResponse,
    },
  },
  requestBody: Parameters.body6,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const unshareDocumentOperationSpec: coreClient.OperationSpec = {
  path: "/api/Documents/unshare",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.DocumentResponse,
    },
  },
  requestBody: Parameters.body7,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const sendSupportEmailOperationSpec: coreClient.OperationSpec = {
  path: "/api/Emails",
  httpMethod: "POST",
  responses: { 204: {} },
  requestBody: Parameters.body8,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const createEpcisDataOperationSpec: coreClient.OperationSpec = {
  path: "/api/Epcis",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } },
      },
    },
  },
  requestBody: Parameters.body9,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getEventTemplateByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/EventTemplates/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.EventTemplatesResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateEventTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/api/EventTemplates/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.EventTemplatesResponse,
    },
  },
  requestBody: Parameters.body10,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteEventTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/api/EventTemplates/{id}",
  httpMethod: "DELETE",
  responses: {
    204: {},
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept3],
  serializer,
};
const getEventTemplatesOperationSpec: coreClient.OperationSpec = {
  path: "/api/EventTemplates",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListEventTemplatesResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.sharedWith,
    Parameters.templateName,
    Parameters.primaryId,
    Parameters.roles,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createEventTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/api/EventTemplates",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.EventTemplatesResponse,
    },
  },
  requestBody: Parameters.body11,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const shareEventTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/api/EventTemplates/share/{templateId}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.EventTemplatesResponse,
    },
  },
  requestBody: Parameters.body12,
  urlParameters: [Parameters.$host, Parameters.templateId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const unshareEventTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/api/EventTemplates/unshare/{templateId}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.EventTemplatesResponse,
    },
  },
  queryParameters: [Parameters.accountId1],
  urlParameters: [Parameters.$host, Parameters.templateId],
  headerParameters: [Parameters.accept],
  serializer,
};
const getEventByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.EventResponse,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept4],
  serializer,
};
const listEventDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/details",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedEventDetailsResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.locationIds,
    Parameters.productIds,
    Parameters.eventIds,
    Parameters.performTraceback,
    Parameters.isInboundShipment,
    Parameters.eventTypes,
    Parameters.lotSerial,
    Parameters.purchaseOrder,
    Parameters.companyIds,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getEventDetailsDiagramOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/{eventId}/diagram",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetEventHistoryDiagramResponse,
    },
  },
  queryParameters: [
    Parameters.instanceId,
    Parameters.containerId,
    Parameters.showAllProducts,
    Parameters.isInboundShipment1,
  ],
  urlParameters: [Parameters.$host, Parameters.eventId1],
  headerParameters: [Parameters.accept],
  serializer,
};
const getEventDetailsMapOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/{id}/map",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.MapModel,
    },
  },
  queryParameters: [
    Parameters.instanceId,
    Parameters.containerId,
    Parameters.showAllProducts,
    Parameters.isInboundShipment1,
  ],
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.accept],
  serializer,
};
const simpleEventQueryOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/simpleEventQuery",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ErrorOr1,
    },
  },
  queryParameters: [
    Parameters.lERecordTime,
    Parameters.gERecordTime,
    Parameters.lEEventTime,
    Parameters.gEEventTime,
    Parameters.matchAnyEPC,
    Parameters.matchAnyEPCClass,
    Parameters.eQBizStep,
    Parameters.typeParam,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getEventHistoryCountOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/{id}/history-count",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetEventHistoryCountResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const commissionOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/commission",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: {
        type: { name: "Sequence", element: { type: { name: "Uuid" } } },
      },
    },
  },
  requestBody: Parameters.body13,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const decommissionOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/decommission",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: { type: { name: "Uuid" } },
    },
  },
  requestBody: Parameters.body14,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const observeOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/observe",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: {
        type: { name: "Sequence", element: { type: { name: "Uuid" } } },
      },
    },
  },
  requestBody: Parameters.body15,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const aggregateOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/aggregate",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: { type: { name: "Uuid" } },
    },
  },
  requestBody: Parameters.body16,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const disaggregateOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/disaggregate",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: { type: { name: "Uuid" } },
    },
  },
  requestBody: Parameters.body17,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const transformOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/transform",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: { type: { name: "Uuid" } },
    },
  },
  requestBody: Parameters.body18,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const shipOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/Ship",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: {
        type: { name: "Sequence", element: { type: { name: "Uuid" } } },
      },
    },
  },
  requestBody: Parameters.body19,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const receiveOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/receive",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: {
        type: { name: "Sequence", element: { type: { name: "Uuid" } } },
      },
    },
  },
  requestBody: Parameters.body20,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteEventOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/{eventId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.eventId1],
  serializer,
};
const createIntegrationDataOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/Feed",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateIntegrationResponse,
    },
  },
  requestBody: Parameters.body21,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const directReceiveOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/directReceive",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: {
        type: { name: "Sequence", element: { type: { name: "Uuid" } } },
      },
    },
  },
  requestBody: Parameters.body22,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getBlockchainByEventIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Events/{id}/blockchain",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.EventBlockchainResponse,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept4],
  serializer,
};
const setExternalRegistryDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/api/ExternalRegistry",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.SetExternalRegistryResponse,
    },
  },
  requestBody: Parameters.body23,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const updateExternalRegistryDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/api/ExternalRegistry",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateExternalRegistryResponse,
    },
  },
  requestBody: Parameters.body24,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getExternalRegistryDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/api/ExternalRegistry",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetExternalRegistryDetailsResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getInboundApiKeyOperationSpec: coreClient.OperationSpec = {
  path: "/api/ExternalRegistry/{tradePartnerId}/InboundApiKey",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetInboundApiKeyResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.tradePartnerId],
  headerParameters: [Parameters.accept],
  serializer,
};
const queryDigitalLinkOperationSpec: coreClient.OperationSpec = {
  path: "/api/ExternalRegistry/DigitalLink",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.TestDigitalLinkResponse,
    },
  },
  queryParameters: [Parameters.url, Parameters.apiKey, Parameters.linkType],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createLanguageOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.LanguageResponse,
    },
    403: {
      bodyMapper: Mappers.ProblemDetails,
    },
    409: {
      bodyMapper: Mappers.ProblemDetails,
    },
    422: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  requestBody: Parameters.body25,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept4],
  mediaType: "json",
  serializer,
};
const listLanguagesOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "LanguageResponse" },
          },
        },
      },
    },
  },
  urlParameters: [Parameters.$host, Parameters.languageCode],
  headerParameters: [Parameters.accept],
  serializer,
};
const getLanguageOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages/{languageCode}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.LanguageResponse,
    },
    403: {
      bodyMapper: Mappers.ProblemDetails,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host, Parameters.languageCode],
  headerParameters: [Parameters.accept4],
  serializer,
};
const deleteLanguageOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages/{languageCode}",
  httpMethod: "DELETE",
  responses: {
    204: {},
    400: {
      bodyMapper: Mappers.ProblemDetails,
    },
    403: {
      bodyMapper: Mappers.ProblemDetails,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host, Parameters.languageCode],
  headerParameters: [Parameters.accept3],
  serializer,
};
const createLanguageAttributeOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages/attributes",
  httpMethod: "POST",
  responses: {
    201: {},
    403: {
      bodyMapper: Mappers.ProblemDetails,
    },
    409: {
      bodyMapper: Mappers.ProblemDetails,
    },
    422: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  requestBody: Parameters.body26,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept3],
  mediaType: "json",
  serializer,
};
const listAllLanguageAttributesOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages/attributes",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedLanguageAttribute,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.languageCodes,
    Parameters.languageAttributeName,
    Parameters.status1,
    Parameters.languageAttributeValue,
    Parameters.types1,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createLanguageAttributeBulkOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages/attributesBulk",
  httpMethod: "POST",
  responses: {
    201: {},
    403: {
      bodyMapper: Mappers.ProblemDetails,
    },
    409: {
      bodyMapper: Mappers.ProblemDetails,
    },
    422: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  requestBody: Parameters.body27,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept3],
  mediaType: "json",
  serializer,
};
const updateLanguageAttributeBulkOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages/attributesBulk",
  httpMethod: "PUT",
  responses: { 204: {} },
  requestBody: Parameters.body28,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getLanguageAttributeOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages/{languageCode}/attributes/{attributeName}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.LanguageAttributeResponse,
    },
  },
  queryParameters: [Parameters.typeParam1],
  urlParameters: [
    Parameters.$host,
    Parameters.languageCode,
    Parameters.attributeName,
  ],
  headerParameters: [Parameters.accept],
  serializer,
};
const deleteLanguageAttributeOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages/{languageCode}/attributes/{attributeName}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.languageCode,
    Parameters.attributeName,
  ],
  serializer,
};
const listLanguageAttributesOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages/{languageCode}/attributes",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.LanguageAttributeResponseDictionary,
    },
  },
  queryParameters: [Parameters.typeParam1],
  urlParameters: [Parameters.$host, Parameters.languageCode],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateLanguageAttributeOperationSpec: coreClient.OperationSpec = {
  path: "/api/Languages/attributes/{attributeName}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.LanguageAttributeResponse,
    },
    201: {},
  },
  requestBody: Parameters.body29,
  urlParameters: [Parameters.$host, Parameters.attributeName],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const listLocationsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Locations",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListLocationsResponse,
    },
  },
  queryParameters: [Parameters.showArchived1],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createLocationOperationSpec: coreClient.OperationSpec = {
  path: "/api/Locations",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.LocationResponse,
    },
  },
  requestBody: Parameters.body30,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getLocationByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Locations/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.LocationResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateLocationOperationSpec: coreClient.OperationSpec = {
  path: "/api/Locations/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.LocationResponse,
    },
  },
  requestBody: Parameters.body31,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteLocationOperationSpec: coreClient.OperationSpec = {
  path: "/api/Locations/{id}",
  httpMethod: "DELETE",
  responses: { 200: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer,
};
const hasActiveInventoryOperationSpec: coreClient.OperationSpec = {
  path: "/api/Locations/HasActiveInventory",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "HasActiveInventoryResponse",
            },
          },
        },
      },
    },
  },
  queryParameters: [Parameters.tradePartnerId1, Parameters.isVessel],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const toggleArchiveLocationOperationSpec: coreClient.OperationSpec = {
  path: "/api/Locations/Archive",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ToggleArchiveLocationResponse",
            },
          },
        },
      },
    },
  },
  requestBody: Parameters.body32,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const shareLocationOperationSpec: coreClient.OperationSpec = {
  path: "/api/Locations/Share",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } },
      },
    },
  },
  requestBody: Parameters.body33,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getLocationStatisticsByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Locations/{id}/Statistics",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.EventStatistics,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const masterDataPglnOperationSpec: coreClient.OperationSpec = {
  path: "/api/MasterData/party/{pgln}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.TradePartnerResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.pgln],
  headerParameters: [Parameters.accept],
  serializer,
};
const masterDataGLNOperationSpec: coreClient.OperationSpec = {
  path: "/api/MasterData/gln/{gln}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.LocationResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.gln],
  headerParameters: [Parameters.accept],
  serializer,
};
const masterDataGtinOperationSpec: coreClient.OperationSpec = {
  path: "/api/MasterData/gtin/{gtin}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ProductResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.gtin],
  headerParameters: [Parameters.accept],
  serializer,
};
const masterDataGtinLotSerialOperationSpec: coreClient.OperationSpec = {
  path: "/api/MasterData/gtin/{gtin}/lot/{lotSerial}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ProductInstanceResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.gtin, Parameters.lotSerial1],
  headerParameters: [Parameters.accept],
  serializer,
};
const listNetworksOperationSpec: coreClient.OperationSpec = {
  path: "/api/Networks",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedExpandedNetworksResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.locationName,
    Parameters.location,
    Parameters.tradePartnerIds,
    Parameters.address,
    Parameters.status2,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listNetworkInvitesOperationSpec: coreClient.OperationSpec = {
  path: "/api/Networks/Invites",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedNetworkInvitesResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.status3,
    Parameters.networkInvitationId,
    Parameters.accountId2,
    Parameters.incoming,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createNetworkInviteOperationSpec: coreClient.OperationSpec = {
  path: "/api/Networks/Invites",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.NetworkInviteResponse,
    },
  },
  requestBody: Parameters.body34,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getNetworkInviteByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Networks/Invites/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.NetworkInviteResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateNetworkInviteOperationSpec: coreClient.OperationSpec = {
  path: "/api/Networks/Invites/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.NetworkInviteResponse,
    },
  },
  requestBody: Parameters.body35,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteNetworkInviteOperationSpec: coreClient.OperationSpec = {
  path: "/api/Networks/Invites/{id}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } },
      },
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const listNotificationsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Notifications",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedNotificationsResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.typeParam2,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const changeToReadNotificationsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Notifications",
  httpMethod: "PATCH",
  responses: { 204: {} },
  requestBody: Parameters.body36,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const listOrphansOperationSpec: coreClient.OperationSpec = {
  path: "/api/Orphans",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedOrphansResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.locationIds,
    Parameters.eventTypes,
    Parameters.lotSerial,
    Parameters.purchaseOrder,
    Parameters.productName,
    Parameters.company,
    Parameters.quantity,
    Parameters.ticketId,
    Parameters.gtin1,
    Parameters.shipmentStatus,
    Parameters.shippedToIds,
    Parameters.shippedFromIds,
    Parameters.startEventDate,
    Parameters.endEventDate,
    Parameters.startLoggedDate,
    Parameters.endLoggedDate,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listOrphansSuggestionsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Orphans/suggestions",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedOrphanSuggestionsResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.locationIds,
    Parameters.tradePartnerIds,
    Parameters.lotSerial,
    Parameters.orphanInstanceId,
    Parameters.orphanContainerId,
    Parameters.preferred,
    Parameters.quantity1,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const resolveOrphanOperationSpec: coreClient.OperationSpec = {
  path: "/api/Orphans/resolve",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Uuid" } },
    },
  },
  queryParameters: [
    Parameters.orphanInstanceId1,
    Parameters.instanceId1,
    Parameters.needsTransform,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getIntegrationEventsOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}/dispatchLog",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedOutboundIntegrationDispatchLogResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.outboundIntegrationId,
  ],
  urlParameters: [Parameters.$host, Parameters.integrationId],
  headerParameters: [Parameters.accept],
  serializer,
};
const getExecutionCheckDiagnosticsOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}/events/{eventId}/executionCheck",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.IntegrationExecutionCheckResponse,
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.eventId1,
    Parameters.integrationId,
  ],
  headerParameters: [Parameters.accept],
  serializer,
};
const getIntegrationsOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedOutboundIntegrationsResponse,
    },
  },
  queryParameters: [Parameters.accountPluginActivationId],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createIntegrationOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.OutboundIntegrationResponse,
    },
  },
  requestBody: Parameters.body37,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getIntegrationByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.OutboundIntegrationResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.integrationId],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateIntegrationOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.OutboundIntegrationResponse,
    },
  },
  requestBody: Parameters.body38,
  urlParameters: [Parameters.$host, Parameters.integrationId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteIntegrationOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.integrationId],
  serializer,
};
const getDefaultConfigurationOptionsOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/defaultConfigurations",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OutboundIntegrationDefault",
            },
          },
        },
      },
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createPreConfiguredOutboundIntegrationOperationSpec: coreClient.OperationSpec =
  {
    path: "/api/OutboundIntegrations/defaultConfigurations/outboundIntegration",
    httpMethod: "POST",
    responses: {
      201: {
        bodyMapper: {
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "OutboundIntegrationResponse",
              },
            },
          },
        },
      },
    },
    requestBody: Parameters.body39,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept],
    mediaType: "json",
    serializer,
  };
const getDefaultConfigurationsOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/defaultConfigurations/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.OutboundIntegrationResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id2],
  headerParameters: [Parameters.accept],
  serializer,
};
const getTriggerSpecificationsOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/triggerSpecifications",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.TriggerConfigurationsResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getTriggerSpecificationCriteriaOptionsOperationSpec: coreClient.OperationSpec =
  {
    path: "/api/OutboundIntegrations/triggerSpecifications/{triggerSpecificationGuid}/criteriaOptions",
    httpMethod: "GET",
    responses: {
      200: {
        bodyMapper: Mappers.TriggerConfigurationOptionsResponse,
      },
    },
    urlParameters: [Parameters.$host, Parameters.triggerSpecificationGuid],
    headerParameters: [Parameters.accept],
    serializer,
  };
const getDefaultMappableElementsOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/defaultConfigurations/{defaultConfiguration}/mappableElements",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExternalMappableDataElement",
            },
          },
        },
      },
    },
  },
  urlParameters: [Parameters.$host, Parameters.defaultConfiguration],
  headerParameters: [Parameters.accept],
  serializer,
};
const createTriggerCriteriaOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}/triggerCriterion",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.TriggerCriterionResponse,
    },
  },
  requestBody: Parameters.body40,
  urlParameters: [Parameters.$host, Parameters.integrationId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteTriggerCriteriaOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}/triggerCriterion/{criteriaId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.integrationId,
    Parameters.criteriaId,
  ],
  serializer,
};
const createTriggerCriteriaValueOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}/triggerCriterion/{criterionId}/lookupValues",
  httpMethod: "PUT",
  responses: {
    201: {
      bodyMapper: Mappers.TriggerCriterionValueResponse,
    },
  },
  requestBody: Parameters.body41,
  urlParameters: [
    Parameters.$host,
    Parameters.integrationId,
    Parameters.criterionId,
  ],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteTriggerCriteriaValueOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}/triggerCriterion/{criterionId}/lookupValues/{criterionTriggerValueId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.integrationId,
    Parameters.criterionId,
    Parameters.criterionTriggerValueId,
  ],
  serializer,
};
const createElementMapOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}/dataMapElement",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.DataMapElementResponse,
    },
  },
  requestBody: Parameters.body42,
  urlParameters: [Parameters.$host, Parameters.integrationId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteElementMapOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}/dataMapElement/{elementMapId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.integrationId,
    Parameters.elementMapId,
  ],
  serializer,
};
const listPluginConfigurationsOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/pluginConfigurations",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListWholechainPluginConfigurationResponse,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const saveIntegrationFtpSecretsOperationSpec: coreClient.OperationSpec = {
  path: "/api/OutboundIntegrations/{integrationId}/secrets/ftp",
  httpMethod: "PUT",
  responses: { 204: {} },
  requestBody: Parameters.body43,
  urlParameters: [Parameters.$host, Parameters.integrationId],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const createProductOperationSpec: coreClient.OperationSpec = {
  path: "/api/Products",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: { type: { name: "Uuid" } },
    },
  },
  requestBody: Parameters.body44,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getPaginatedProductsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Products",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedProductsResponse,
    },
  },
  queryParameters: [
    Parameters.name,
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.showArchived,
    Parameters.urn,
    Parameters.sharingPolicies,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getProductByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Products/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ProductResponse,
    },
  },
  queryParameters: [Parameters.authorizingEventId],
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateProductOperationSpec: coreClient.OperationSpec = {
  path: "/api/Products/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.ProductResponse,
    },
  },
  requestBody: Parameters.body45,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getPaginatedProductInventoryOperationSpec: coreClient.OperationSpec = {
  path: "/api/Products/{id}/Inventory",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedInventoryResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.locationIds,
    Parameters.eventTypes,
    Parameters.lotSerial,
    Parameters.purchaseOrder,
    Parameters.isHistory,
    Parameters.instanceIds,
  ],
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const getPaginatedLotInventoryOperationSpec: coreClient.OperationSpec = {
  path: "/api/Products/{lotSerial}/Inventory",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedInventoryResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.locationIds,
    Parameters.eventTypes,
    Parameters.purchaseOrder,
    Parameters.isHistory,
    Parameters.instanceIds,
    Parameters.lotSerial11,
  ],
  urlParameters: [Parameters.$host, Parameters.lotSerial1],
  headerParameters: [Parameters.accept],
  serializer,
};
const createSubProductOperationSpec: coreClient.OperationSpec = {
  path: "/api/Products/{productId}/SubProducts",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ProductResponse,
    },
  },
  requestBody: Parameters.body46,
  urlParameters: [Parameters.$host, Parameters.productId1],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const deleteSubProductOperationSpec: coreClient.OperationSpec = {
  path: "/api/Products/{productId}/SubProducts{subProductId}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.productId1,
    Parameters.subProductId,
  ],
  serializer,
};
const changeArchivedProductOperationSpec: coreClient.OperationSpec = {
  path: "/api/Products/{id}/archived",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.ChangeArchivedProductResponse,
    },
  },
  requestBody: Parameters.body47,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const getGlobalSearchPaginatedProductsOperationSpec: coreClient.OperationSpec =
  {
    path: "/api/Products/GlobalSearch",
    httpMethod: "GET",
    responses: {
      200: {
        bodyMapper: Mappers.PaginatedGlobalSearchProductsResponse,
      },
    },
    queryParameters: [
      Parameters.name,
      Parameters.pageNumber,
      Parameters.pageSize,
      Parameters.sortBy,
      Parameters.purchaseOrder,
    ],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
  };
const uploadProductsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Products/upload",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Stream" },
        serializedName: "parsedResponse",
      },
    },
  },
  formDataParameters: [Parameters.file1],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType1, Parameters.accept2],
  serializer,
};
const getPaginatedShipmentsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedShipmentsResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.productName,
    Parameters.ticketId,
    Parameters.direction,
    Parameters.shippedFromTradePartnerUrn,
    Parameters.shippedToTradePartnerUrn,
    Parameters.shippedFromUrn,
    Parameters.shippedToUrn,
    Parameters.primaryId1,
    Parameters.purchaseOrderNumber1,
    Parameters.onlyMyShipments,
    Parameters.status4,
    Parameters.startDate,
    Parameters.endDate,
    Parameters.primaryIdOrProductName,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getShipmentsSummaryOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/summary",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetShipmentsSummaryResponse,
    },
  },
  queryParameters: [
    Parameters.startDate1,
    Parameters.endDate1,
    Parameters.locationUrn,
    Parameters.tradePartnerUrn,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const createExternalShipmentOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/externalShipment",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Uuid" } },
    },
  },
  requestBody: Parameters.body48,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const listExternalShipmentsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/external",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListExternalShipmentsResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.productName,
    Parameters.shippedFromTradePartnerUrn,
    Parameters.shippedToTradePartnerUrn,
    Parameters.shippedFromUrn,
    Parameters.shippedToUrn,
    Parameters.primaryId1,
    Parameters.status4,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getExternalShipmentByCaptureOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/external/{captureId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ExternalShipmentResponse,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host, Parameters.captureId],
  headerParameters: [Parameters.accept4],
  serializer,
};
const getPaginatedInboundShipmentsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/inbound",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedInboundShipmentsResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.productName,
    Parameters.purchaseOrderNumber1,
    Parameters.status4,
    Parameters.startDate,
    Parameters.endDate,
    Parameters.originTradePartnerUrns,
    Parameters.originLocationUrns,
    Parameters.destinationTradePartnerUrns,
    Parameters.destinationLocationUrns,
    Parameters.shipmentIds,
    Parameters.inboundShipmentType,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getPaginatedOtherSystemShipmentsOperationSpec: coreClient.OperationSpec =
  {
    path: "/api/Shipments/inbound/othersystem",
    httpMethod: "GET",
    responses: {
      200: {
        bodyMapper: Mappers.ListOtherSystemShipmentsResponse,
      },
    },
    queryParameters: [
      Parameters.pageNumber,
      Parameters.pageSize,
      Parameters.sortBy,
      Parameters.purchaseOrder,
      Parameters.productName,
      Parameters.shippedFromTradePartnerUrn,
      Parameters.shippedToTradePartnerUrn,
      Parameters.shippedFromUrn,
      Parameters.shippedToUrn,
      Parameters.primaryId1,
      Parameters.status4,
      Parameters.startDate,
      Parameters.endDate,
      Parameters.shipmentIds,
    ],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer,
  };
const getInboundShipmentDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/inbound/details",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "ShipmentDetailsResponse" },
          },
        },
      },
    },
  },
  queryParameters: [
    Parameters.sortBy,
    Parameters.productName,
    Parameters.primaryId1,
    Parameters.shipmentIds1,
    Parameters.gtin2,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const countInboundShipmentsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/inbound/count",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ShipmentCountResponse,
    },
  },
  queryParameters: [
    Parameters.status4,
    Parameters.startDate,
    Parameters.endDate,
    Parameters.originTradePartnerUrns,
    Parameters.originLocationUrns,
    Parameters.destinationTradePartnerUrns,
    Parameters.destinationLocationUrns,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getPaginatedOutboundShipmentsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/outbound",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedOutboundShipmentsResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.productName,
    Parameters.purchaseOrderNumber1,
    Parameters.status4,
    Parameters.startDate,
    Parameters.endDate,
    Parameters.originTradePartnerUrns,
    Parameters.originLocationUrns,
    Parameters.destinationTradePartnerUrns,
    Parameters.destinationLocationUrns,
    Parameters.outboundShipmentType,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getOutboundShipmentDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/outbound/details",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "ShipmentDetailsResponse" },
          },
        },
      },
    },
  },
  queryParameters: [
    Parameters.sortBy,
    Parameters.productName,
    Parameters.primaryId1,
    Parameters.shipmentIds1,
    Parameters.gtin2,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const countOutboundShipmentsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/outbound/count",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ShipmentCountResponse,
    },
  },
  queryParameters: [
    Parameters.status4,
    Parameters.startDate,
    Parameters.endDate,
    Parameters.originTradePartnerUrns,
    Parameters.originLocationUrns,
    Parameters.destinationTradePartnerUrns,
    Parameters.destinationLocationUrns,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getShipEventByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.EventResponse,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept4],
  serializer,
};
const getOtherSystemShipmentByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/otherSystem",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OtherSystemShipmentResponse",
            },
          },
        },
      },
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  queryParameters: [
    Parameters.sortBy,
    Parameters.productName,
    Parameters.primaryId1,
    Parameters.shipmentIds1,
    Parameters.gtin2,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept4],
  serializer,
};
const listShipmentDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/{eventId}/eventDetails",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PaginatedEventDetailsResponse,
    },
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.locationIds,
    Parameters.productIds,
    Parameters.eventTypes,
    Parameters.lotSerial,
    Parameters.companyIds,
  ],
  urlParameters: [Parameters.$host, Parameters.eventId1],
  headerParameters: [Parameters.accept],
  serializer,
};
const getShipmentDiagramOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/{eventId}/diagram",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetShipmentDiagramResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.eventId1],
  headerParameters: [Parameters.accept],
  serializer,
};
const getShipmentMapOperationSpec: coreClient.OperationSpec = {
  path: "/api/Shipments/{eventId}/map",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.MapModel,
    },
  },
  urlParameters: [Parameters.$host, Parameters.eventId1],
  headerParameters: [Parameters.accept],
  serializer,
};
const interestingEndpointOperationSpec: coreClient.OperationSpec = {
  path: "/api/Test",
  httpMethod: "GET",
  responses: {
    200: {},
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  queryParameters: [
    Parameters.hello,
    Parameters.testApiKey,
    Parameters.digitalLink,
    Parameters.userId1,
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept3],
  serializer,
};
const createTradePartnerOperationSpec: coreClient.OperationSpec = {
  path: "/api/TradePartners",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.TradePartnerResponse,
    },
  },
  requestBody: Parameters.body49,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const listTradePartnersOperationSpec: coreClient.OperationSpec = {
  path: "/api/TradePartners",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListTradePartnersResponse,
    },
  },
  queryParameters: [Parameters.name, Parameters.urns, Parameters.types2],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getTradePartnerByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/TradePartners/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.TradePartnerResponse,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer,
};
const updateTradePartnerOperationSpec: coreClient.OperationSpec = {
  path: "/api/TradePartners/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.TradePartnerResponse,
    },
    403: {
      bodyMapper: Mappers.ProblemDetails,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
    409: {
      bodyMapper: Mappers.ProblemDetails,
    },
    422: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  requestBody: Parameters.body50,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept4],
  mediaType: "json",
  serializer,
};
const changeArchivedTradePartnerOperationSpec: coreClient.OperationSpec = {
  path: "/api/TradePartners/{id}/archived",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.ChangeArchivedTradePartnerResponse,
    },
    403: {
      bodyMapper: Mappers.ProblemDetails,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  requestBody: Parameters.body51,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept4],
  mediaType: "json",
  serializer,
};
const getTradePartnerStatisticsByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/TradePartners/{id}/Statistics",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.EventStatistics,
    },
    403: {
      bodyMapper: Mappers.ProblemDetails,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept4],
  serializer,
};
const toggleArchiveAllVesselsOperationSpec: coreClient.OperationSpec = {
  path: "/api/TradePartners/vessels/archive",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ToggleArchiveVesselsResponse",
            },
          },
        },
      },
    },
    403: {
      bodyMapper: Mappers.ProblemDetails,
    },
    404: {
      bodyMapper: Mappers.ProblemDetails,
    },
  },
  requestBody: Parameters.body52,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept4],
  mediaType: "json",
  serializer,
};
const getUserAccountOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.UserResponse,
    },
  },
  queryParameters: [Parameters.id3],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const listAccountsFromUserOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/me/accounts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "UserAccountsResponse" },
          },
        },
      },
    },
  },
  queryParameters: [Parameters.name1],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const changeAccountOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/me/current-account",
  httpMethod: "PATCH",
  responses: { 204: {} },
  requestBody: Parameters.body53,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const changeAcceptedTermsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/me/accepted-terms",
  httpMethod: "PATCH",
  responses: { 204: {} },
  requestBody: Parameters.body54,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const changePasswordOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/me/password",
  httpMethod: "PATCH",
  responses: { 204: {} },
  requestBody: Parameters.body55,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const changeEmailOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/me/email",
  httpMethod: "PATCH",
  responses: { 204: {} },
  requestBody: Parameters.body56,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const setSiteAdminOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/{userId}/siteAdmin",
  httpMethod: "PATCH",
  responses: { 204: {} },
  formDataParameters: [Parameters.isSiteAdmin],
  urlParameters: [Parameters.$host, Parameters.userId],
  headerParameters: [Parameters.contentType1],
  serializer,
};
const changePinnedAccountOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/me/pinned-account",
  httpMethod: "PATCH",
  responses: { 204: {} },
  requestBody: Parameters.body57,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer,
};
const changePersonalInfoOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/me",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.ChangePersonalInfoResponse,
    },
  },
  requestBody: Parameters.body58,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const changeSettingsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/me/settings",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.ChangeSettingsResponse,
    },
  },
  requestBody: Parameters.body59,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const changeRoleOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/{userId}/role",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper: Mappers.ChangeRoleRequest,
    },
  },
  requestBody: Parameters.body60,
  urlParameters: [Parameters.$host, Parameters.userId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer,
};
const uploadProfilePicture$binaryOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/me/profile-picture",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.UploadProfilePictureResponse,
    },
  },
  requestBody: Parameters.data,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType2, Parameters.accept5],
  mediaType: "binary",
  serializer,
};
const uploadProfilePicture$multipartOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/me/profile-picture",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.UploadProfilePictureResponse,
    },
  },
  formDataParameters: [Parameters.picture],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept2, Parameters.contentType3],
  serializer,
};
const disableMapsOperationSpec: coreClient.OperationSpec = {
  path: "/api/Users/disableMaps",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
