import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Descriptions, Space, Tooltip, Typography } from 'antd';
import { FC } from 'react';
import styles from '../index.module.less';
import { DetailsDataProps, DetailsEpcisProps } from '../typings';
import DataTitle from './DataTitle';

const CardTitle: FC<DetailsDataProps> = ({ activeEvent, t }) => (
  <Descriptions size="small" className={styles.cardtitle}>
    <Descriptions.Item
      label={
        <Space size="small">
          <Typography.Text strong>{t?.('event_hash')}</Typography.Text>
          <Tooltip placement="bottom" title={t?.('tooltip_msg')}>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <Typography.Paragraph copyable ellipsis>
        {activeEvent?.blockchain?.hashedEventData || ''}
      </Typography.Paragraph>
    </Descriptions.Item>
  </Descriptions>
);
const EpcisContent: FC<DetailsEpcisProps> = ({
  event,
  activeEvent,
  isPretty,
  productInstance,
  isSerial,
  identifier,
  t,
}) => (
  <>
    <DataTitle
      activeEvent={activeEvent}
      productInstance={productInstance}
      isSerial={isSerial}
      identifier={identifier}
    />
    <Card
      title={<CardTitle event={event} activeEvent={activeEvent} t={t} />}
      className={styles.mrt10}
    >
      {isPretty && <pre className={styles.jsonbox}>{JSON.stringify(activeEvent, null, 2)}</pre>}
      {!isPretty && <p className={styles.jsonbox}>{JSON.stringify(activeEvent, null, 2)}</p>}
    </Card>
  </>
);
export default EpcisContent;
