import { FormInstance } from 'antd';
import { ifood, respositrack } from 'assets';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import {
  DefaultConfiguration,
  OutboundIntegrationResponse,
  WholechainPluginAccountConfigurationResponse,
} from 'services/api/client/src';

export interface Integration {
  id: number;
  name: string;
  code: string;
  shortDesc: string;
  longDesc: string;
  img: string;
  manage: boolean;
}

export interface EnableFormProps {
  form?: FormInstance<any>;
}

type TitleSizeType = 1 | 3 | 5 | 2 | 4 | undefined;

export interface TitleProp {
  tkey?: string;
  count?: number;
  hideCount?: boolean;
  hideDesc?: boolean;
  titleSize?: TitleSizeType;
  errorMessage?: string;
}

export interface ServerCredentialProp<FormType = any> {
  form?: FormInstance<FormType>;
}

export interface ReviewProps<FormType = any> {
  form?: FormInstance<FormType>;
}

export interface SelectTableProps {
  isReview?: boolean;
  isConfig?: boolean;
}

export interface SelectAllTableProps<FormType = any> {
  tableTitle?: ReactNode;
  form?: FormInstance<FormType>;
  t?: TFunction<'pages', 'settings.comp_integration'>;
  isReview?: boolean;
  isConfig?: boolean;
}

export interface IntegrationCardProps {
  plugin: WholechainPluginAccountConfigurationResponse;
  config?: OutboundIntegrationResponse;
  selectedKey: string;
}

export const desc: Record<DefaultConfiguration, string> = {
  Repositrak: 'repositrak',
  IFoodDS: 'ifood',
};

export const imgFile: Record<DefaultConfiguration, string> = {
  Repositrak: respositrack,
  IFoodDS: ifood,
};

export const supportEmail: Record<DefaultConfiguration, string> = {
  Repositrak: 'support@repositrak.com',
  IFoodDS: 'support@ifoodds.com',
};

export const Learnmore: Record<DefaultConfiguration, string> = {
  Repositrak: 'https://repositrak.com/',
  IFoodDS: 'https://www.ifoodds.com/',
};
