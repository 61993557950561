import { HttpClient } from 'services/utils/security';
import { SaveIntegrationFtpSecretsRequest } from 'services/api/client/src';
import { QueryClient, useMutation } from 'react-query';

/// For now this api works as save/update (creates/updates a ftp server credentials for integrations
export const updateFtpSecret = async (id: string, body: SaveIntegrationFtpSecretsRequest) =>
  HttpClient.saveIntegrationFtpSecrets(id, { body });

/// This API works as saved and update (Need to look at the backend)
export const useUpdateIntegrationFtpSecrets = (queryClient: QueryClient) =>
  useMutation(
    ({ id, ftpSecrets }: { id: string; ftpSecrets: SaveIntegrationFtpSecretsRequest }) =>
      updateFtpSecret(id, ftpSecrets),
    {
      onSuccess: (_, obj) => {
        queryClient.invalidateQueries('ftpIntegrations');
        queryClient.invalidateQueries('ftpIntegrations');
        queryClient.invalidateQueries(['ftpIntegrationId', obj.id.toString()]);
      },
    },
  );
