import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useModalVisibility } from 'hooks';
import TraceabilityLotCodeSourceModal from 'pages/Events/components/Forms/TraceabilityLotCodeSourceModal';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventDiagramById } from 'services/api';
import { ListEventDetailsOptionalParams } from 'services/api/client/src';
import { useDebouncedCallback } from 'use-debounce';
import { flattenAddress } from 'utils';
import GSelect from './GSelect';
import { FetchDataFn, TraceabilityLotCodeSourceSelectProps } from './typings';

const TraceabilityLotCodeSourceSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  prependOption,
  isAddProduct,
  filterItem,
  updateListHander,
  newCompanyLocationList,
  eventId,
  ...props
}: TraceabilityLotCodeSourceSelectProps<ValueType, OptionType>) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.trace_select' });
  const newCompanyLocationModal = useModalVisibility(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState<ListEventDetailsOptionalParams>();
  const { data: eventDiagramDetails } = useEventDiagramById(eventId || '');
  const showModal = useCallback(() => {
    newCompanyLocationModal.show();
  }, [newCompanyLocationModal]);

  const filterOption = useCallback(
    (option?: DefaultOptionType) => {
      if (!filterItem) {
        return true;
      }
      return filterItem?.(option?.itemProps as OptionType['itemProps']);
    },
    [filterItem],
  );
  function getUniqueLocations(data: DefaultOptionType[]) {
    const uniqueEntries: DefaultOptionType[] = [];
    const seen = new Set();

    data.forEach((entry) => {
      const { name } = entry.itemProps;
      const latlng = entry?.itemProps?.geoCoordinates?.split(',');
      const latitude =
        entry?.itemProps?.address?.geoCoordinates?.latitude || Number(latlng ? latlng[0] : 1);
      const longitude =
        entry?.itemProps?.address?.geoCoordinates?.longitude || Number(latlng ? latlng[1] : 1);
      // Create a unique key combining name and coordinates
      const uniqueKey = `${name}-${latitude}-${longitude}`;

      if (!seen.has(uniqueKey)) {
        seen.add(uniqueKey);

        uniqueEntries.push(
          entry?.itemProps?.geoCoordinates
            ? {
                ...entry,
                itemProps: {
                  ...entry?.itemProps,
                  address: {
                    ...entry?.itemProps?.address,
                    geoCoordinates: {
                      latitude,
                      longitude,
                    },
                  },
                },
              }
            : entry,
        );
      }
    });

    return uniqueEntries;
  }
  // we create here the list with the shape the select is expecting for
  const productOptions: Array<DefaultOptionType> = useMemo(() => {
    const eventDiagramDetailsLocation = eventDiagramDetails?.nodes
      ?.filter((event) => event?.eventType === 'Transform' || event?.eventType === 'Commission')
      ?.map((event) => event?.location);
    const uniqueLocations =
      Array.from(
        new Map(eventDiagramDetailsLocation?.map((item) => [item?.id, item])).values(),
      )?.map((address) => ({
        label: address?.name,
        value: address?.name,
        disabled: false,
        itemProps: address,
      })) || [];
    let locations = [
      ...(prependOption ? [...prependOption] : []),
      ...(newCompanyLocationList ? [...newCompanyLocationList] : []),
      ...(uniqueLocations?.filter(filterOption) || []),
    ];
    locations = locations.map((location) => ({
      ...location,
      label: `${location.label} - ${flattenAddress(location?.itemProps?.address)}`,
    }));
    return getUniqueLocations(locations);
  }, [eventDiagramDetails?.nodes, prependOption, newCompanyLocationList, filterOption]);

  const debounced = useDebouncedCallback((filterText) => {
    setParams((oldParams) => ({ ...oldParams, name: filterText }));
  }, 1000);

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText, initialValue }) => {
      if (initialValue) {
        setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
      } else {
        debounced(filterText);
      }
    },
    [debounced],
  );

  const dropdownRender = useCallback(
    (menu: ReactElement) => (
      <>
        {menu}
        <Button type="link" icon={<PlusOutlined />} onClick={() => showModal()}>
          {t?.('diff_add')}
        </Button>
      </>
    ),
    [showModal, t],
  );

  const handleAdd = (updatedCompanyDetails: DefaultOptionType) => {
    if (newCompanyLocationList) {
      updateListHander([...newCompanyLocationList, { ...updatedCompanyDetails }]);
    }
  };
  return (
    <>
      <GSelect<ValueType, OptionType>
        placeholder={t?.('placeholder')}
        {...props}
        options={productOptions as OptionType[]}
        fetchData={fetchData}
        allowFilterOption
        dropdownRender={isAddProduct ? dropdownRender : undefined}
      />
      <TraceabilityLotCodeSourceModal
        modalProps={newCompanyLocationModal}
        addNewCompany={handleAdd}
      />
    </>
  );
};

export default TraceabilityLotCodeSourceSelect;
