import { Space, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../index.module.less';
import { TitleProp } from '../typings';

const FormTitleBar: FC<TitleProp> = ({
  count,
  tkey,
  hideCount,
  hideDesc,
  titleSize,
  errorMessage,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: '' });
  const mainTitleSize = titleSize ?? 3;
  return (
    <Space direction="vertical">
      <Typography.Title level={mainTitleSize} className="mr0">
        {`${t(`${tkey || ''}.title`)}`}
      </Typography.Title>

      {hideDesc ? null : <Typography.Text>{`${t(`${tkey || ''}.desc`)}`}</Typography.Text>}

      {errorMessage && <Typography.Text className={styles.alert}>{errorMessage}</Typography.Text>}
      {!hideCount && (
        <Typography.Text className={styles.selected}>
          {`${count} ${
            count === 1 ? `${t(`${tkey || ''}.item`)}` : `${t(`${tkey || ''}.items`)}`
          } ${t(`${tkey || ''}.selected`)}`}
        </Typography.Text>
      )}
    </Space>
  );
};

export default FormTitleBar;
