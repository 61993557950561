/* eslint-disable no-useless-catch */
import { CloseCircleOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormColumnsType } from '@ant-design/pro-form';
import { Alert, Card, Input, Modal, Result, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useUpdateIntegrationFtpSecrets } from '../../../../services/api/outboundIntegrations';
import LoadingProgress from '../../../Loading/LoadingProgress';
import FormTitleBar from '../components/FormTitleBar';
import { setIntegrationStepSession, useFormContext } from '../FormProvider';
import styles from '../index.module.less';
import { ServerCredentialProp, supportEmail } from '../typings';

interface ServerCredentials {
  server: string;
  username: string;
  password: string;
}

type ServerCredentialModalProps = {
  toggle: boolean;
  isLoading: boolean;
  tick: number;
  setTick: (tick: number) => void;
  t: any;
};
type ServerCredentialErrorProps = {
  locate: any;
  selectedKey: string;
};

const columns = (t: any): ProFormColumnsType<ServerCredentials>[] => [
  {
    title: t('form.server_label'),
    dataIndex: 'server',
    formItemProps: {
      rules: [{ required: true, message: t('form.server_input_error') }],
    },
    fieldProps: {
      name: 'server', // Explicitly set name
      placeholder: t('form.server_label'),
    },
    width: 'md',
  },
  {
    title: t('form.username_label'),
    dataIndex: 'username',
    formItemProps: {
      rules: [{ required: true, message: t('form.username_input_error') }],
    },
    fieldProps: {
      name: 'username',
      placeholder: t('form.username_label'),
    },
    width: 'md',
  },
  {
    title: t('form.password_label'),
    dataIndex: 'password',
    formItemProps: {
      rules: [{ required: true, message: t('form.password_input_error') }],
    },
    fieldProps: {
      type: 'password',
      name: 'password',
      placeholder: t('form.password_label'),
      visibilityToggle: true,
    },
    renderFormItem: () => (
      <Input.Password placeholder={t('form.password_label')} visibilityToggle />
    ),
    width: 'md',
  },
];

const { Title } = Typography;

const LoadingIndicatorModal = ({
  isLoading,
  toggle,
  tick,
  setTick,
  t,
}: ServerCredentialModalProps) => (
  <Modal visible={toggle} footer={null} centered width="30%" closable={false}>
    <Space direction="horizontal" className={styles.servercredentialloading} size="large">
      <Space direction="vertical" size="large" align="center">
        <Card className={styles.height80} bordered={false}>
          <LoadingProgress
            isFloating
            percent={Number(tick) || 0}
            isLoading={isLoading}
            setTick={setTick}
          />
        </Card>
        <Title level={5} className={styles.servercredentialtitle}>
          {t('server_credential.modal.loading_msg')}
        </Title>
      </Space>
    </Space>
  </Modal>
);

const ErrorMessageAlert = ({ locate, selectedKey }: ServerCredentialErrorProps) => (
  <Alert
    message=" "
    description={
      <Space direction="vertical" size={0}>
        <p className={styles.servercredentialsparagrapherrormsg}>
          {locate('server_credentials_error_message_phrase1')}
        </p>
        <p className={styles.servercredentialsparagrapherrormsg}>
          {locate('server_credentials_error_message_phrase2')}&nbsp;
          <a href={`${supportEmail[selectedKey]}`} type="mail">
            {supportEmail[selectedKey]}
          </a>
          &nbsp;
          <span>{locate('server_credentials_error_message_phrase3')}</span>
        </p>
      </Space>
    }
    type="error"
    icon={<CloseCircleOutlined className={styles.icon} />}
    showIcon
  />
);

const ServerCredential: React.FC<ServerCredentialProp> = ({ form }) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'settings.integration',
  });

  const queryClient = useQueryClient();
  const { isLoading, mutateAsync } = useUpdateIntegrationFtpSecrets(queryClient);
  const { current, setCurrent, integrationId, pluginObject } = useFormContext();
  const [toggle, setToggle] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [tick, setTick] = useState<number>(0);

  const openLoadingModal = () => {
    setToggle(true);
  };
  const closeLoadingModal = () => {
    setToggle(false);
  };

  const onSubmit = async (serverRequest: ServerCredentials) => {
    try {
      if (!integrationId) {
        return {};
      }
      openLoadingModal();
      const response = await mutateAsync({
        id: integrationId,
        ftpSecrets: serverRequest,
      });
      closeLoadingModal();
      return response;
    } catch (error) {
      closeLoadingModal();
      setErrorAlert(true);
      throw error;
    }
  };
  const onSuccessMessageHandler = () => {
    const successModal = Modal.success({
      title: null,
      width: '33%',
      centered: true,
      closable: false,
      icon: '',
      okButtonProps: { hidden: true, size: 'small' },
      content: (
        <Space
          direction="horizontal"
          className={styles.servercredentialloadingmodalsuccess}
          size="middle"
        >
          <Result
            className={styles.servercredentialloadingmodalsuccessresult}
            status="success"
            title={
              <Title level={5} className={styles.servercredentialtitle}>
                {t('server_credential.modal.success_msg')}
              </Title>
            }
          />
        </Space>
      ),
    });

    setTimeout(() => {
      successModal.destroy();
    }, 1700);
  };
  const onFinish = async (data: ServerCredentials) => {
    try {
      await onSubmit(data);
      onSuccessMessageHandler();
      setCurrent?.((current || 0) + 1);
      setIntegrationStepSession(String((current || 0) + 1));
      return true;
    } catch (error: any) {
      // if (errorHandler(error)) {
      //   message.error(errorHandler(error));
      // }
      return false;
    }
  };

  return (
    <Space direction="vertical" size="large">
      <FormTitleBar hideCount tkey="settings.integration.title_server_credential" />
      {errorAlert && (
        <ErrorMessageAlert locate={t} selectedKey={pluginObject?.externalSystem || ''} />
      )}
      <BetaSchemaForm<ServerCredentials>
        columns={columns(t)}
        form={form}
        layoutType="Form"
        submitter={{ render: () => null }}
        onFinish={onFinish}
      />

      <LoadingIndicatorModal
        tick={tick}
        setTick={setTick}
        isLoading={isLoading}
        toggle={toggle}
        t={t}
      />
    </Space>
  );
};

export default ServerCredential;
