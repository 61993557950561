import { ForTestingStuff } from 'components';
import { SettingsDrawer } from 'components/Settings';
import { EnableFormDrawer, ManageFormDrawer } from 'components/Settings/Integrations';
import { BlankLayout, GeneralLayout } from 'layouts';
import InviteModal from 'layouts/GeneralLayout/components/RightContent/InviteModal';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AccessDocument from './AccessDocument';
import InboundShipments from './AllShipments/InboundShipments/InboundShipments';
import OutboundShipments from './AllShipments/OutboundShipments/OutboundShipments';
import ShipEventDetail from './AllShipments/PreviewEvent';
import ShipEventDocuments from './AllShipments/PreviewEvent/ShipEventDocuments';
import CreateAccount from './CreateAccount';
import Dashboard from './Dashboard';
import Documents from './Documents';
import AddDocumentForm from './Documents/Forms/AddDocumentForm';
import ViewDocumentForm from './Documents/Forms/ViewDocumentForm';
import Events from './Events';
import Labels from './Labels';
import Login from './Login';
import Network, {
  AddCompanyLocation,
  EditCompanyLocation,
  ViewCompany,
  ViewLocation,
} from './Network';
import Products, {
  AddProductForm,
  ProductDocuments,
  ProductInventory,
  ProductSettingsForm,
} from './Products';
import EventDetails, { EventDocuments } from './Products/Inventory/EventDetails';
import Query from './Query';
import RedeemInvite from './RedeemInvite';
import Reports from './Reports/Reports';
import Search, { SearchDrawer } from './Search';
import { BatchShipForm, ShipmentTickets, UpdateTicketForm, ViewTicketForm } from './Shipments';
import SourceView from './SourceView';
import SwitchAccount from './SwitchAccount';
import Templates, {
  AddDocumentTemplateForm,
  AddTemplateForm,
  TemplateDocuments,
  ViewDocumentTemplateForm,
  ViewTemplateForm,
  ViewTemplatePreviewForm,
} from './Templates';
import Workflows, { AddWorkflowForm, ViewWorkflowForm } from './Workflows';

const Pages = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<BlankLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/fortestingstuff" element={<ForTestingStuff />} />
        <Route path="/query" element={<Query />} />
        <Route path="/redeem_invite" element={<RedeemInvite />} />
        <Route path="/view_document" element={<AccessDocument />} />
        <Route path="/change_account" element={<SwitchAccount />} />
      </Route>
      <Route element={<GeneralLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="view_invite" element={<InviteModal />} />
        <Route path="search/*">
          <Route index element={<Search />} />
          <Route path=":purchaseOrder/">
            <Route index element={<Search />} />
            <Route path="po_search" element={<SearchDrawer />} />
          </Route>
        </Route>
        <Route path="products/*">
          <Route index element={<Products />} />
          <Route path="add" element={<AddProductForm />} />
          <Route path=":productId/*">
            <Route index element={<ProductInventory />} />
            <Route path="documents" element={<ProductDocuments />}>
              <Route path="add" element={<AddDocumentForm />} />
              <Route path=":documentId" element={<ViewDocumentForm />} />
            </Route>
            <Route path="events/*" element={<Events />} />
            <Route path=":lotId/*">
              <Route path="" element={<EventDetails />}>
                <Route path="edocuments" element={<EventDocuments />}>
                  <Route path=":documentId" element={<ViewDocumentForm />} />
                </Route>
              </Route>
              <Route path="documents" element={<EventDocuments />}>
                <Route path="add" element={<AddDocumentForm />} />
                <Route path=":documentId" element={<ViewDocumentForm />} />
              </Route>
            </Route>
            <Route path="settings" element={<ProductSettingsForm />} />
          </Route>
        </Route>
        <Route path="/shipments">
          <Route index element={<InboundShipments />} />
          <Route path="outbound/*">
            <Route index element={<OutboundShipments />} />
            <Route path=":shipmentId/*">
              <Route path="eventDetails" element={<ShipEventDetail />} />
              <Route path="documents" element={<ShipEventDocuments />}>
                <Route path="add" element={<AddDocumentForm />} />
                <Route path=":documentId" element={<ViewDocumentForm />} />
              </Route>
            </Route>
          </Route>
          <Route path="inbound/*">
            <Route index element={<InboundShipments />} />
            <Route path=":shipmentId/*">
              <Route path="eventDetails" element={<ShipEventDetail />} />
              <Route path="documents" element={<ShipEventDocuments />}>
                <Route path="add" element={<AddDocumentForm />} />
                <Route path=":documentId" element={<ViewDocumentForm />} />
              </Route>
            </Route>
          </Route>
          <Route path=":shipmentId/*">
            <Route path="eventDetails" element={<ShipEventDetail />} />
            <Route path="documents" element={<ShipEventDocuments />}>
              <Route path="add" element={<AddDocumentForm />} />
              <Route path=":documentId" element={<ViewDocumentForm />} />
            </Route>
          </Route>
          <Route path="tickets/*" element={<ShipmentTickets />}>
            <Route path="batch_ship" element={<BatchShipForm />} />
            <Route path=":ticketId" element={<ViewTicketForm />} />
            <Route path=":ticketId/update" element={<UpdateTicketForm />} />
          </Route>
        </Route>
        <Route path="templates/*">
          {/* <Route index element={<WorkflowTemplates />} />
          <Route path="add" element={<AddWorkflowTemplate />} />
          <Route path=":templateId/*" element={<ViewWorkflowTemplate />} /> */}
          <Route index element={<Templates />} />
          <Route path="add" element={<AddTemplateForm />} />
          <Route path=":templateId/*">
            <Route index element={<ViewTemplateForm />} />
            <Route path="preview" element={<ViewTemplatePreviewForm />} />
          </Route>
          <Route path="events/*">
            <Route index element={<Templates />} />
            <Route path="add" element={<AddTemplateForm />} />
            <Route path=":templateId/*">
              <Route index element={<ViewTemplateForm />} />
              <Route path="preview" element={<ViewTemplatePreviewForm />} />
            </Route>
          </Route>

          <Route path="documents/*">
            <Route index element={<TemplateDocuments />} />
            <Route path="add" element={<AddDocumentTemplateForm />} />
            <Route path=":documentId/*">
              <Route index element={<ViewDocumentTemplateForm />} />
            </Route>
          </Route>
        </Route>
        <Route path="workflows/*">
          <Route index element={<Workflows />} />
          <Route path="add" element={<AddWorkflowForm />} />
          <Route path=":workflowId" element={<ViewWorkflowForm />} />
        </Route>
        <Route path="/labels" element={<Labels />} />
        <Route path="/documents/*" element={<Documents />}>
          <Route path="add" element={<AddDocumentForm />} />
          <Route path=":documentId" element={<ViewDocumentForm />} />
        </Route>
        <Route path="/reports/*" element={<Reports />} />
        <Route path="network/*">
          <Route index element={<Network />} />
          <Route path="add_location" element={<AddCompanyLocation />} />
          <Route path=":companyId/">
            <Route index element={<ViewCompany />} />
            <Route path="editLocation" element={<EditCompanyLocation />} />
            <Route path=":locationId/*" element={<ViewLocation />}>
              <Route path="editLocationId" element={<EditCompanyLocation />} />
            </Route>
          </Route>
          <Route path="shipments/*">
            <Route index element={<InboundShipments />} />
            <Route path="outbound/*">
              <Route index element={<OutboundShipments />} />
              <Route path=":shipmentId/*">
                <Route path="eventDetails" element={<ShipEventDetail />} />
                <Route path="documents" element={<ShipEventDocuments />}>
                  <Route path="add" element={<AddDocumentForm />} />
                  <Route path=":documentId" element={<ViewDocumentForm />} />
                </Route>
              </Route>
            </Route>
            <Route path="inbound/*">
              <Route index element={<InboundShipments />} />
              <Route path=":shipmentId/*">
                <Route path="eventDetails" element={<ShipEventDetail />} />
                <Route path="documents" element={<ShipEventDocuments />}>
                  <Route path="add" element={<AddDocumentForm />} />
                  <Route path=":documentId" element={<ViewDocumentForm />} />
                </Route>
              </Route>
            </Route>
            <Route path=":shipmentId/*">
              <Route path="eventDetails" element={<ShipEventDetail />} />
              <Route path="documents" element={<ShipEventDocuments />}>
                <Route path="add" element={<AddDocumentForm />} />
                <Route path=":documentId" element={<ViewDocumentForm />} />
              </Route>
            </Route>
            <Route path="tickets/*" element={<ShipmentTickets />}>
              <Route path="batch_ship" element={<BatchShipForm />} />
              <Route path=":ticketId" element={<ViewTicketForm />} />
              <Route path=":ticketId/update" element={<UpdateTicketForm />} />
            </Route>
          </Route>
        </Route>

        <Route path="/sourceview" element={<SourceView />} />
        <Route path="settings/*">
          <Route index element={<SettingsDrawer />} />
          <Route path=":externalSystem/enable" element={<EnableFormDrawer />} />
          <Route path=":externalSystem/manage" element={<ManageFormDrawer />} />
        </Route>
        <Route path="/create_account" element={<CreateAccount />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </BrowserRouter>
);

export default Pages;
