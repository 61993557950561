import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Button, Modal, RowProps, message } from 'antd';
import classNames from 'classnames';
import AddDocumentFormFields from 'pages/Documents/Forms/AddDocumentForm.fields';
import { DataItem } from 'pages/Documents/Forms/typings';
import useDocumentsStore from 'pages/Documents/hooks/useDocumentsStore';
import { CSSProperties, FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { CreateDocumentOptionalParams } from 'services/api/client/src';
import { useCreateDocument } from 'services/api/documents';
import { capitalizeFirstLetter, errorHandler, generateDocTags, getDivImage } from 'utils';
import styles from './index.module.less';
import { AddDocumentFormProps } from './typings';

const rowProps: RowProps = { gutter: [32, 24] };
const docContainerStyle: CSSProperties = {
  maxHeight: '65vh',
};

const AddDocumentForm: FC<AddDocumentFormProps> = ({
  form,
  modal,
  onAddSuccess,
  onAddExisting,
  isWorkflow,
}) => {
  const queryClient = useQueryClient();
  const { getFieldValue, setFieldsValue } = form;
  const { t } = useTranslation('pages', { keyPrefix: 'documents.add_document' });
  const { hasAdvancedSettings, eventTagAlert, setEventTagAlert } = useDocumentsStore();
  const modalFormRef = useRef<ProFormInstance>();
  const documents: Array<DataItem> = getFieldValue('documents');
  const setDocuments = useCallback(
    (data: Array<DataItem>) => {
      setFieldsValue({ documents: data });
    },
    [setFieldsValue],
  );

  const createDocument = useCreateDocument(queryClient);

  const title = t('title');
  const submitBtnText = t('submit_btn');
  const onSubmit = async () => {
    try {
      await modalFormRef?.current?.validateFields();
      modalFormRef?.current?.submit();
    } catch (error) {
      message.error(t('submit_error'));
    }
  };
  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    setEventTagAlert(false);
    modal.hide();
  }, [modal, setEventTagAlert]);

  const onCancel = useCallback(() => {
    Modal.confirm({
      title: t('cancel_changes_title'),
      content: t('cancel_changes_content'),
      okText: t('cancel_changes_btn'),
      cancelText: t('cancel_changes_btn_cancel'),
      onOk: closeModal,
      centered: true,
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
        shape: 'round',
      },
    });
  }, [closeModal, t]);

  const onAddExistingDocuments = useCallback(() => {
    closeModal();
    if (onAddExisting) {
      onAddExisting();
    }
  }, [closeModal, onAddExisting]);

  const onFinish = async (formData: DataItem) => {
    try {
      if (!formData.file) {
        message.error(t('missing_doc'));
        return;
      }

      const reqBody: CreateDocumentOptionalParams = {
        title: formData.title,
        documentType: capitalizeFirstLetter(formData?.documentType || 'other'),
        file: formData.file,
        privacy: formData.privacy,
        blockchain: formData.blockchain,
        certificationAgency: formData.agency,
        certificationStandard: formData.certificationStandard,
        certificationId: formData.identification,
        purchaseOrderNumber: formData.purchaseOrder,
        expirationDate: formData?.expirationDate
          ? new Date(formData?.expirationDate || '')
          : undefined,
        expirationAlertDate: formData?.expirationDateAlert
          ? new Date(formData?.expirationDateAlert)
          : undefined,
        productsIds: formData?.products as Array<string>,
        locationsIds: formData?.locations as Array<string>,
        tradePartnersIds: formData?.tradePartners as Array<string>,
        documentTypeName: formData.documentTypeName,
        shipFrom: formData?.shipFrom,
        shipTo: formData?.shipTo,
        sid: formData?.sID,
        cid: formData?.cID,
        fob: formData?.fOB,
        shipDate: formData?.shipDate ? new Date(formData?.shipDate || '') : undefined,
        carrier: formData?.carrier,
        items: formData?.items,
        quantitiesOfShippedItems: formData?.quantitiesOfShippedItems,
      };

      const res = await createDocument.mutateAsync(reqBody);
      const thumbURL = await getDivImage('page_1');
      const documentData: DataItem = {
        id: res.id,
        title: formData.title,
        documentType: capitalizeFirstLetter(formData?.documentType || 'other'),
        file: formData.file,
        privacy: formData.privacy,
        blockchain: formData.blockchain,
        expirationDate: formData.expirationDate,
        agency: formData.agency,
        certificationStandard: formData.certificationStandard,
        certificationValue: formData.certificationValue,
        identification: formData.identification,
        documentURL: res?.url,
        documentAttachment: formData.documentAttachment,
        thumbURL,
        locations: formData?.locations || [],
        products: formData?.products || [],
        tradePartners: formData?.tradePartners || [],
        expirationDateAlert: formData?.expirationDateAlert,
        tags: generateDocTags({
          locations: formData?.locations || [],
          products: formData?.products || [],
          tradePartners: formData?.tradePartners || [],
          events: formData?.events || [],
        }),
        shipFrom: formData?.shipFrom,
        shipTo: formData?.shipTo,
        sID: formData?.sID,
        cID: formData?.cID,
        fOB: formData?.fOB,
        shipDate: formData?.shipDate,
        carrier: formData?.carrier,
        items: formData?.items,
        quantitiesOfShippedItems: formData?.quantitiesOfShippedItems,
      };
      setDocuments([...documents, documentData]);
      if (onAddSuccess) {
        onAddSuccess(documentData);
      }
      message.success(t('submit_success', { id: res?.title || '' }));
      closeModal();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      formRef={modalFormRef}
      columns={AddDocumentFormFields({
        hasAdvancedSettings,
        docContainerStyle,
        eventTagAlert,
        setEventTagAlert,
      })}
      grid
      title={title}
      rowProps={rowProps}
      autoFocusFirstInput
      visible={modal.visible}
      modalProps={{
        destroyOnClose: false,
        width: '80%',
        ...modal,
        onCancel,
        wrapClassName: isWorkflow ? styles.workflowmodal : undefined,
        maskStyle: isWorkflow ? { zIndex: 1001 } : undefined,
        className: isWorkflow
          ? classNames(styles.documentmodal, styles.workflowmodal)
          : styles.documentmodal,
      }}
      submitter={{
        searchConfig: {
          submitText: submitBtnText,
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          onClick: onCancel,
          type: 'primary',
          ghost: true,
        },
        onSubmit,
        render: (_, defaultDoms) => [
          <Button type="link" className={styles.existingdocbtn} onClick={onAddExistingDocuments}>
            {t('add_existing_document')}
          </Button>,
          defaultDoms[0],
          defaultDoms[1],
        ],
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default AddDocumentForm;
