import { FC, JSX, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table';
import { Button, ButtonProps, Tag, Tooltip } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';
import GTable from '../../../GTable';
import styles from '../../index.module.less';
import { getT } from '../../../../utils';

type ActionProps = {
  onEditRow: () => void;
  t: TFunction<'pages', 'settings.integration'>;
};

interface DunsEditProps {
  label: JSX.Element;
  value?: string;
}

const defaultBtnProps: ButtonProps = {
  size: 'small',
  ghost: false,
  type: 'text',
  className: 'value-actions-btn',
};

type ColumnsType = {
  t: TFunction<'pages', 'settings.integration'>;
  isConfig?: boolean;
  onEditRow: () => void;
  data?: Array<DunsEditProps>;
};

const Actions: FC<ActionProps> = ({ onEditRow, t = (key: string) => key }) => (
  <Tooltip title={t?.('company_locations.edit')} placement="bottom">
    <Button {...defaultBtnProps} className="actions" onClick={() => onEditRow()}>
      <EditOutlined className={styles.btnicon} />
    </Button>
  </Tooltip>
);

const columns = ({ t, onEditRow }: ColumnsType): Array<ProColumns<DunsEditProps>> => [
  {
    dataIndex: 'label',
    className: 'attribute-cell-duns',
    editable: false,
    width: '8%',
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    className: 'right-aligned-cell-duns',
    width: '8%',
    render: (text, record) => (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>
          {record.value || (
            <Tag color="warning">{getT('settings.comp_integration.customer.tag_missing_duns')}</Tag>
          )}
        </span>
        <Actions onEditRow={onEditRow} t={t} />
      </div>
    ),
  },
];

const DUNSDescriptionTable = ({
  isConfig = false,
  tradePartnerId,
  dunsNumber,
}: {
  isConfig: boolean | undefined;
  tradePartnerId: string;
  dunsNumber: string;
}) => {
  const navigate = useNavigate();
  const actionRef = useRef(null);
  const { t } = useTranslation('pages', { keyPrefix: 'settings.comp_integration' });

  const onEditRow = useCallback(() => {
    navigate(`../../network/${tradePartnerId}/editLocation`);
  }, [navigate, tradePartnerId]);
  const data = [
    {
      label: (
        <>
          {t?.('company_locations.description_HQ')}
          {!isConfig && (
            <Tooltip
              placement="bottom"
              title={`${t('company_locations.duns_number_tooltip')}`}
              overlayClassName="duns-number-tooltip"
            >
              {' '}
              <QuestionCircleOutlined className="text-gray-400 cursor-help" />
            </Tooltip>
          )}
        </>
      ),
      value: dunsNumber,
    },
  ];
  return (
    <GTable<DunsEditProps>
      actionRef={actionRef}
      columns={columns({ onEditRow, t })}
      options={{ reload: false, setting: false }}
      actionsRenderOptions={{ save: true, cancel: true }}
      recordCreatorProps={false}
      loading={false}
      value={data}
      showHeader={false}
      bordered
    />
  );
};
export default DUNSDescriptionTable;
