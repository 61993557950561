import { SettingOutlined } from '@ant-design/icons';
import { Card, Col, Image, message, Row, Switch, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useCreatePreConfiguredOutboundIntegration } from 'services/api/useIntegrations';
import { errorHandler } from 'utils';
import styles from './index.module.less';
import { desc, imgFile, IntegrationCardProps, Learnmore } from './typings';

const IntegrationCard: FC<IntegrationCardProps> = ({ plugin, config, selectedKey }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.new_integration' });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const createConfiguration = useCreatePreConfiguredOutboundIntegration(queryClient);
  const checkIntegrationId = async () => {
    if (config) {
      navigate(`./${plugin.externalSystem}/enable`);
    } else {
      try {
        await createConfiguration.mutateAsync({
          options: { body: { externalSystem: plugin?.externalSystem } },
        });
        navigate(`./${plugin.externalSystem}/enable`);
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    }
  };
  return (
    <Card
      className={styles.card}
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <Row align="middle">
              <Col>
                <Image
                  preview={false}
                  src={imgFile[`${plugin?.externalSystem}`]}
                  alt="logo"
                  className={styles.integrationcardimg}
                />
              </Col>
              <Col>
                <Typography.Text strong className={styles.integrationcardtitle}>
                  {plugin.name}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
          <Col>
            <Typography.Link
              className={styles.learnmore}
              target="_blank"
              href={`${Learnmore[selectedKey]}`}
            >
              {t?.('integration_learnmore')}
            </Typography.Link>
          </Col>
        </Row>
      }
    >
      <Typography.Text>{t(desc[`${plugin?.externalSystem}`])}</Typography.Text>
      <Row justify="space-between" align="middle" className={styles.margintop10}>
        <Col>
          <Row align="middle">
            <Col />
            <Col>
              {config?.isEnabled ? (
                <Typography.Link onClick={() => navigate(`./${plugin.externalSystem}/manage`)}>
                  <SettingOutlined className={styles.integrationcardsettingimg} />{' '}
                  {t?.('integration_manage')}
                </Typography.Link>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col>
          <Switch
            checked={config?.isEnabled}
            disabled={config?.isEnabled}
            onChange={() => checkIntegrationId()}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default IntegrationCard;
