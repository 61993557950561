import { LoadingOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Button, Col, message, Modal, Row, Spin, Tag, Typography } from 'antd';
import ActionButtons from 'components/ActionButtons';
import { ProductSelect } from 'components/GSelect';
import GTable from 'components/GTable';
import { GTableDateType } from 'components/GTable/contexts/typings';
import { ProductItem, useProductFilters } from 'pages/Products';
import { FC, Key, useCallback, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ProductResponse } from 'services/api/client/src';
import {
  useCreateTriggerCriteriaValue,
  useDeleteTriggerCriteriaValue,
} from 'services/api/useIntegrations';
import { errorHandler, getT } from 'utils';
import { setIntegrationTabSession, useFormContext } from '../FormProvider';
import styles from '../index.module.less';
import { SelectTableProps } from '../typings';
import FormTitleBar from './FormTitleBar';

type ColumnsType = {
  t?: TFunction<'pages', 'settings.comp_integration.product'>;
  onEditRow?: (actionPayload?: ProductItem) => void;
  onDelRow?: (actionPayload?: ProductItem) => void;
  selectedRows?: Key[];
  isConfig?: boolean;
};

const Columns = ({
  t,
  onEditRow,
  onDelRow,
  selectedRows,
  isConfig,
}: ColumnsType): Array<ProColumns<ProductItem>> => [
  {
    title: t?.('col_product_title'),
    dataIndex: 'name',
    hideInSetting: true,
    ellipsis: true,
    renderFormItem: (_, { recordKey }, form) => (
      <ProductSelect
        size="small"
        disabledItem={(item) => selectedRows?.includes(item?.id || '')}
        onChange={(value, option) => {
          const { setFieldsValue } = form;
          // @ts-ignore
          const item: ProductResponse = option.itemProps;
          setFieldsValue({
            [String(recordKey)]: {
              ...item,
              id: item?.id || value,
              gtin: item?.gtin,
              name: item?.name,
            },
          });
        }}
      />
    ),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('product_req'),
        },
      ],
    },
  },
  {
    title: t?.('col_gtin_title'),
    dataIndex: 'gtin',
    ellipsis: true,
    fieldProps: {
      size: 'small',
      disabled: true,
    },
    render: (_, row) =>
      row?.gtin ? row?.gtin : <Tag color="warning">{t?.('tag_missing_gtin')}</Tag>,
  },
  {
    title: '',
    dataIndex: 'actions',
    fixed: 'right',
    hideInTable: !isConfig,
    width: 100,
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons
        record={record}
        onDelete={onDelRow}
        onEdit={onEditRow}
        showEdit={false}
        showDelete
      />
    ),
  },
];
const SelectProducts: FC<SelectTableProps> = ({ isReview, isConfig }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.comp_integration.product' });
  const actionRef = useRef<ActionType>();
  const {
    productSelectedRowsKeys,
    productOnRowSelectionChange,
    productSelectedRows,
    showErrorMessage,
    productCriteriaId,
    configObject,
    integrationId,
    productConfigCriteriaId,
  } = useFormContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const addItem = useCreateTriggerCriteriaValue(queryClient);
  const removeItem = useDeleteTriggerCriteriaValue(queryClient);
  const { params, records, totalItems, isLoading, onTableChange } = useProductFilters({
    pageSize: 10,
  });
  const [loadingItems, setLoadingItems] = useState<Key[]>([]);

  useEffect(() => {
    if (configObject) {
      const defaultIds = configObject?.triggerCriteria?.find(
        (item) => item?.triggerSpecificationId === productCriteriaId,
        [],
      )?.values;

      if (defaultIds?.length) {
        const selectedRowsKeys = defaultIds?.map((item) => item?.entityId || '', []);
        const selectedRow = defaultIds?.map(
          (item) => ({
            id: item?.entityId,
            name: item?.name,
            gtin: item?.description,
          }),
          [],
        );
        productOnRowSelectionChange?.(selectedRowsKeys, selectedRow);
      }
    }
  }, [configObject, productCriteriaId, productOnRowSelectionChange]);

  const onDelete = useCallback(
    async (actionPayload?: ProductItem) => {
      try {
        const updatedkeys =
          productSelectedRowsKeys?.filter((item) => item !== actionPayload?.id, []) || [];
        const updatedRows =
          productSelectedRows?.filter((item) => item?.id !== actionPayload?.id, []) || [];
        productOnRowSelectionChange?.(updatedkeys, updatedRows);
        await removeItem?.mutateAsync({
          integrationId: integrationId || '',
          criterionId: productConfigCriteriaId || '',
          criterionTriggerValueId: actionPayload?.id || '',
        });
        message.success(t('products_delete_success'));
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      integrationId,
      productConfigCriteriaId,
      productOnRowSelectionChange,
      productSelectedRows,
      productSelectedRowsKeys,
      removeItem,
      t,
    ],
  );

  const onDelRow = useCallback(
    (actionPayload?: ProductItem) => {
      Modal.warning({
        title: t?.('delete_warn_title', { name: actionPayload?.name }),
        content: t?.('delete_warn_content'),
        okText: t?.('delete_warn_remove'),
        cancelText: t?.('delete_warn_cancel'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
        onOk: () => onDelete(actionPayload),
      });
    },
    [onDelete, t],
  );

  const onEditRow = useCallback(
    (actionPayload?: ProductItem) => {
      setIntegrationTabSession('1');
      navigate(`../../products/${actionPayload?.id}/settings`);
    },
    [navigate],
  );

  const onSelectChange = useCallback(
    async (selectedKeys: Key[], selectedRows: GTableDateType[]) => {
      try {
        const removedItems = productSelectedRowsKeys?.filter(
          (item1) => !selectedKeys.some((item2) => item1 === item2, []),
          [],
        );
        const addedItems = selectedKeys.filter(
          (item2) => !productSelectedRowsKeys?.some((item1) => item1 === item2, []),
          [],
        );
        if (addedItems?.length) {
          setLoadingItems(addedItems);
          const apiCalls = addedItems?.map(async (i) => {
            await addItem?.mutateAsync({
              integrationId: integrationId || '',
              criterionId: productConfigCriteriaId || '',
              options: {
                body: {
                  triggerValueId: String(i),
                },
              },
            });
          }, []);
          await Promise.all(apiCalls);
        }
        if (removedItems?.length) {
          setLoadingItems(removedItems);
          const apiCalls = removedItems?.map(async (i) => {
            await removeItem?.mutateAsync({
              integrationId: integrationId || '',
              criterionId: productConfigCriteriaId || '',
              criterionTriggerValueId: String(i),
            });
          }, []);
          await Promise.all(apiCalls);
        }
        productOnRowSelectionChange?.(selectedKeys, selectedRows);
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      addItem,
      integrationId,
      productConfigCriteriaId,
      productOnRowSelectionChange,
      productSelectedRowsKeys,
      removeItem,
    ],
  );

  const onSaveRow = useCallback(
    async (_: any, data: ProductItem) => {
      try {
        await addItem?.mutateAsync({
          integrationId: integrationId || '',
          criterionId: productConfigCriteriaId || '',
          options: {
            body: {
              triggerValueId: data?.id,
            },
          },
        });
        productOnRowSelectionChange?.(
          [...(productSelectedRowsKeys || []), data?.id || ''],
          [...(productSelectedRows || []), data],
        );
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      addItem,
      integrationId,
      productConfigCriteriaId,
      productOnRowSelectionChange,
      productSelectedRows,
      productSelectedRowsKeys,
    ],
  );

  const onCancelRow = useCallback(async (_: any, data: ProductItem) => {
    actionRef.current?.cancelEditable(data?.id || 0);
  }, []);

  return (
    <>
      {!(isReview || isConfig) && (
        <FormTitleBar
          count={productSelectedRowsKeys?.length}
          tkey="settings.comp_integration.product"
          {...(showErrorMessage && { errorMessage: t?.('atleast_select') })}
        />
      )}
      {isConfig && (
        <Col className={styles.margintop20}>
          <Typography.Title level={5}>
            {getT('settings.comp_integration.review.product_table_title')}
          </Typography.Title>

          <Row justify="space-between" className={styles.rowstyle}>
            <Typography.Text>{t?.('product_table_desc')}</Typography.Text>
            <Button
              type="primary"
              size="middle"
              shape="round"
              onClick={() => {
                actionRef.current?.addEditRecord?.({
                  id: Date.now().toString(),
                  gtin: '',
                });
              }}
            >
              {t?.('add_product')}
            </Button>
          </Row>
        </Col>
      )}

      <GTable<ProductItem>
        actionRef={actionRef}
        columns={Columns({
          t,
          onDelRow,
          onEditRow,
          selectedRows: productSelectedRowsKeys,
          isConfig,
        })}
        loading={isLoading}
        value={isReview || isConfig ? productSelectedRows : records}
        recordCreatorProps={false}
        enableRowSelection={!(isReview || isConfig)}
        editable={{
          onSave: (rowKey, actionPayload) => onSaveRow(rowKey, actionPayload),
          onCancel: async (rowKey, oldData, data) => onCancelRow(rowKey, data),
        }}
        {...(!(isReview || isConfig) && {
          rowSelection: {
            selectedRowKeys: productSelectedRowsKeys,
            onChange: onSelectChange,
            preserveSelectedRowKeys: true,
            renderCell: (value, record, index, originNode) =>
              (addItem?.isLoading || removeItem?.isLoading) &&
              loadingItems?.includes(record?.id || '') ? (
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              ) : (
                originNode
              ),
          },
        })}
        options={{
          setting: false,
          reload: false,
        }}
        actionsRenderOptions={{
          save: isConfig,
          cancel: isConfig,
        }}
        onTableChange={onTableChange}
        rowKey="id"
        pagination={{
          defaultPageSize: params.pageSize,
          total: isReview || isConfig ? productSelectedRows?.length : totalItems,
          showTotal: (total, range) =>
            total <= 1
              ? t('product_pagi', { range0: range[0], range1: range[1], total })
              : t('product_pagi_multi', { range0: range[0], range1: range[1], total }),
        }}
        columnsState={{
          persistenceKey: 'select-products',
        }}
      />
    </>
  );
};
export default SelectProducts;
