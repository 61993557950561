import ProCard from '@ant-design/pro-card';
import { Col, Image, Row, Switch, Tabs, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIntegrationTabSession, useFormContext } from '../FormProvider';
import styles from '../index.module.less';
import { desc, EnableFormProps, imgFile } from '../typings';
import Configuration from './Configuration';

const ManageForm: React.FC<EnableFormProps> = () => {
  const { pluginObject, configObject } = useFormContext();
  const { t } = useTranslation('pages', { keyPrefix: 'settings.comp_integration.manage_drawer' });
  const [activeKey, setActiveKey] = useState<string>(getIntegrationTabSession() || '1');

  return (
    <ProCard className={styles.container}>
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <Row className={styles.aligncenter} gutter={[10, 10]}>
            <Col>
              <Image
                preview={false}
                className={styles.manageimg}
                alt="logo"
                src={imgFile[`${pluginObject?.externalSystem}`]}
              />
            </Col>
            <Col>
              <Typography.Title level={2}>{pluginObject?.name}</Typography.Title>
            </Col>
            <Col>
              <Switch checked={configObject?.isEnabled} disabled />
            </Col>
          </Row>
        </Col>
        <Col span={18}>
          <Typography.Paragraph>{t(desc[`${pluginObject?.externalSystem}`])}</Typography.Paragraph>
        </Col>
        <Col span={24}>
          <Tabs defaultActiveKey={activeKey} onChange={(key) => setActiveKey(key)}>
            <Tabs.TabPane tab={t?.('configuration')} key="1">
              <Configuration />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </ProCard>
  );
};

export default React.memo(ManageForm);
