import { Card, Descriptions, Space, Tooltip, Typography } from 'antd';
import React, { FC } from 'react';
import { featureFlag } from 'utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from '../index.module.less';
import { DetailsDataProps } from '../typings';
import DataTitle from './DataTitle';
import HashCard from './HashCard';

const CardTitle: FC<DetailsDataProps> = ({ t, activeEvent }) =>
  featureFlag?.validateHash ? (
    <div className={styles.titleflex}>
      <Descriptions size="small" className={styles.cardtitle}>
        <Space size="small">
          <Typography.Text strong>{t?.('event_data')}</Typography.Text>
        </Space>
      </Descriptions>
    </div>
  ) : (
    <div className={styles.titleflex}>
      <Descriptions size="small" className={styles.cardtitle}>
        <Descriptions.Item
          label={
            <Space size="small">
              <Typography.Text strong>{t?.('hash_title')}</Typography.Text>
              <Tooltip placement="bottom" title={t?.('hash_tooltip')}>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          }
        >
          <Typography.Paragraph copyable ellipsis>
            {activeEvent?.blockchain?.hashedEventData || ''}
          </Typography.Paragraph>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
const DataContent: FC<DetailsDataProps> = ({
  event,
  activeEvent,
  isPretty,
  productInstance,
  isSerial,
  identifier,
  t,
}) => (
  <>
    <DataTitle
      activeEvent={activeEvent}
      productInstance={productInstance}
      isSerial={isSerial}
      identifier={identifier}
      t={t}
    />
    {featureFlag?.validateHash && (
      <div className={styles.mrb20}>
        <HashCard activeEvent={activeEvent} t={t} />
      </div>
    )}
    <Card title={<CardTitle event={event} t={t} activeEvent={activeEvent} />}>
      {isPretty && <pre className={styles.jsonbox}>{JSON.stringify(activeEvent, null, 2)}</pre>}
      {!isPretty && <p className={styles.jsonbox}>{JSON.stringify(activeEvent, null, 2)}</p>}
    </Card>
  </>
);
export default React.memo(DataContent);
