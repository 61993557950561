import { Col, ColProps, Empty, Input, PageHeader, Row, Segmented, Typography } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  OutboundIntegrationResponse,
  WholechainPluginAccountConfigurationResponse,
} from 'services/api/client/src';
import { useGetIntegrations, useListPluginConfigurations } from 'services/api/useIntegrations';
import IntegrationCard from './IntegrationCard';
import styles from './index.module.less';

type IntegrationType = {
  t: TFunction<'pages', 'setting.new_integration'>;
  total: number;
};
const IntegrationTitle: FC<IntegrationType> = ({ total, t }) => {
  const title = useMemo(() => t?.('newintegration_title', { total }), [t, total]);

  return <PageHeader title={title} className={styles['page-header']} />;
};
const NewIntegrations = () => {
  const colProps24: ColProps = { xs: 24, sm: 12, md: 8, lg: 8 };
  const { data: records } = useListPluginConfigurations();
  const { data: configList } = useGetIntegrations();
  const { t } = useTranslation('pages', { keyPrefix: 'settings.new_integration' });
  const [sections] = useState<any[]>([
    { key: 'trace', total: 0 },
    /* { key: 'erp', total: 0 },
      { key: 'other_data', total: 0 }, */
  ]);
  const [filteredList, setFilteredList] = useState<WholechainPluginAccountConfigurationResponse[]>(
    [],
  );
  const [originalList, setOriginalList] = useState<WholechainPluginAccountConfigurationResponse[]>(
    [],
  );
  const section = useMemo(
    () => [
      t('all'),
      t('enabled'),
      t('disabled') /* , t('erp_wms'), t('traceability'), t('other') */,
    ],
    [t],
  );
  const [segmentVal, setSegmentVal] = useState<SegmentedValue>(section[0]);
  const [searchFilterValue, setSearchFilterValue] = useState<string>('');

  const getConfigurationBasedOnPlugin = useCallback(
    (plugin: WholechainPluginAccountConfigurationResponse) => {
      const pluginExistsInConfig = configList?.results?.find(
        (config: OutboundIntegrationResponse) => plugin.externalSystem === config.externalSystem,
      );
      if (pluginExistsInConfig) {
        return pluginExistsInConfig;
      }
      return undefined;
    },
    [configList?.results],
  );

  const onSearchClick = useCallback(
    (value: string, oglist: WholechainPluginAccountConfigurationResponse[]) => {
      setSearchFilterValue(value);
      if (value) {
        const list = oglist.filter((plugin) =>
          plugin.name?.toLowerCase()?.includes(value.toLowerCase()),
        );
        setFilteredList(list || []);
      } else {
        // If no filter, show all
        setFilteredList(oglist || []);
        setSearchFilterValue('');
      }
    },
    [],
  );

  useEffect(() => {
    setOriginalList(records?.pluginAccountConfiguration || []);
    setFilteredList(records?.pluginAccountConfiguration || []);
  }, [records?.pluginAccountConfiguration]);

  const setValue = useCallback(
    (newValue: SegmentedValue) => {
      setSegmentVal(newValue);
      let list: WholechainPluginAccountConfigurationResponse[] = [];
      switch (newValue) {
        case t('enabled'):
          list =
            records?.pluginAccountConfiguration?.filter(
              (plugin) => getConfigurationBasedOnPlugin(plugin)?.isEnabled,
            ) || [];
          break;
        case t('disabled'):
          list =
            records?.pluginAccountConfiguration?.filter(
              (plugin) => !getConfigurationBasedOnPlugin(plugin)?.isEnabled,
            ) || [];
          break;
        default:
          list = records?.pluginAccountConfiguration || [];
      }
      setOriginalList(list || []);
      if (searchFilterValue) {
        onSearchClick(searchFilterValue, list);
      } else {
        setFilteredList(list || []);
      }
    },
    [
      getConfigurationBasedOnPlugin,
      onSearchClick,
      records?.pluginAccountConfiguration,
      searchFilterValue,
      t,
    ],
  );

  const onSearchChange = useCallback(
    (e: any) => {
      const value = e?.target?.value;
      onSearchClick(value, originalList);
    },
    [onSearchClick, originalList],
  );
  const getNoDataFoundText = () => {
    if (searchFilterValue) {
      return (
        <Typography.Text type="secondary">{`${t('no_filter_result_found', {
          search: searchFilterValue,
        })}`}</Typography.Text>
      );
    }
    return <Typography.Text type="secondary">{`${t('no_result_found')}`}</Typography.Text>;
  };

  return (
    <>
      <Row justify="start">
        <Col>
          <IntegrationTitle total={records?.pluginAccountConfiguration?.length || 0} t={t} />
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Segmented options={section} value={segmentVal} onChange={setValue} />
        </Col>
        <Col>
          <Input.Search
            value={searchFilterValue}
            onChange={onSearchChange}
            onSearch={(val) => onSearchClick(val, originalList)}
            placeholder={t?.('integration_search')}
            allowClear
          />
        </Col>
      </Row>
      <br />
      {sections.map((sec) => (
        <div key={sec.key}>
          <Typography.Text strong className={styles.integrationcard}>
            {t?.(sec.key)}{' '}
            <Typography.Text className={styles.total}>({filteredList.length})</Typography.Text>
          </Typography.Text>

          <Row gutter={16}>
            {filteredList?.map((plugin: WholechainPluginAccountConfigurationResponse) => (
              <Col {...colProps24}>
                <IntegrationCard
                  plugin={plugin}
                  config={getConfigurationBasedOnPlugin(plugin)}
                  selectedKey={plugin?.externalSystem || ''}
                />
              </Col>
            ))}
          </Row>
        </div>
      ))}
      {!filteredList.length ? (
        <Empty
          className="empty-state"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={getNoDataFoundText()}
        />
      ) : null}
    </>
  );
};

export default NewIntegrations;
