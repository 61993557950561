import { Form, Input, Modal } from 'antd';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TraceabilityLotCodeSourceModalProps } from './typings';

import AddressSelectInput from '../../../Network/components/AddressSelectInput';

const TraceabilityLotCodeSourceModal: FC<TraceabilityLotCodeSourceModalProps> = ({
  modalProps,
  closeAllModal,
  addNewCompany,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('pages', { keyPrefix: 'events.traceability' });
  const handleCancel = useCallback(() => {
    closeAllModal?.();
    modalProps?.hide();
  }, [closeAllModal, modalProps]);

  const handleAdd = () => {
    form
      .validateFields()
      .then((values) => {
        const newCompany = {
          label: values.name,
          value: values.id || values.name,
          itemProps: values,
        };
        form.resetFields();
        handleCancel();
        addNewCompany(newCompany);
      })
      .catch(() => {});
  };
  return (
    <Modal
      title={t?.('modal_add_loc')}
      {...modalProps}
      onOk={handleAdd}
      onCancel={handleCancel}
      okText={t?.('ok_text')}
      okButtonProps={{ shape: 'round', type: 'primary' }}
      cancelButtonProps={{ shape: 'round', type: 'primary', ghost: true }}
    >
      <Form form={form} layout="vertical" name="company_location_form">
        <Form.Item
          name="name"
          label={t?.('modal_company_name')}
          rules={[{ required: true, message: t?.('company_name_validation') }]}
        >
          <Input placeholder={t?.('company_name_placeholder')} />
        </Form.Item>
        <Form.Item name="geoCoordinates" hidden />
        <Form.Item
          name="address"
          label={t?.('modal_loc_add')}
          rules={[{ required: true, message: t?.('loc_add_validation') }]}
        >
          <AddressSelectInput form={form} fieldValue="address" geoFieldValue="geoCoordinates" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TraceabilityLotCodeSourceModal;
