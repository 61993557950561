import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormTitleBar from '../components/FormTitleBar';
import SelectAllTables from '../components/SelectAllTables';
import { ReviewProps } from '../typings';

const Review: FC<ReviewProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.comp_integration.review' });

  return (
    <SelectAllTables
      t={t}
      form={form}
      tableTitle={<FormTitleBar hideCount tkey="settings.comp_integration.review" />}
      isReview
    />
  );
};
export default Review;
