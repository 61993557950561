import { queryClient } from 'index';
import { GetAccountResponse } from 'services/api/client/src';
import {
  CSVTemplate,
  certKeys,
  certificationMappings,
  getBizStepT,
  getDispositionT,
  getProductsT,
} from 'utils';

export const commissionTemplate: CSVTemplate = {
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
    valueType: 'string',
    required: true,
  },
  quantity: {
    title: 'Quantity',
    dataIndex: 'quantity',
    valueType: 'number',
    required: true,
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
    valueType: 'string',
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
    valueType: 'string',
    required: true,
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
    valueType: 'string',
    required: true,
  },
  bizStep: {
    title: 'Business Step',
    dataIndex: 'bizStep',
    valueType: 'string',
    required: true,
    initialValue: 'Commissioning (CBV)',
  },
  disposition: {
    title: 'Disposition',
    dataIndex: 'disposition',
    valueType: 'string',
    required: true,
    initialValue: 'Active (CBV)',
  },
  timeZone: {
    title: 'Time Zone',
    dataIndex: 'timeZone',
    valueType: 'string',
    required: true,
    initialValue: '(UTC-05:00) Eastern Time (US & Canada)',
  },
};
export const observeTemplate: CSVTemplate = {
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
    valueType: 'string',
    required: true,
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
    valueType: 'number',
    required: true,
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
    valueType: 'string',
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
    valueType: 'string',
    required: true,
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
    required: true,
  },
  location: {
    title: 'Location',
    dataIndex: 'location',
    required: true,
  },
  bizStep: {
    title: 'Business Step',
    dataIndex: 'bizStep',
    valueType: 'string',
    required: true,
  },
  disposition: {
    title: 'Disposition',
    dataIndex: 'disposition',
    valueType: 'string',
    required: true,
    initialValue: 'In Progress (CBV)',
  },
  timeZone: {
    title: 'Time Zone',
    dataIndex: 'timeZone',
    valueType: 'string',
    required: true,
    initialValue: '(UTC-05:00) Eastern Time (US & Canada)',
  },
};
export const receiveTemplate: CSVTemplate = {
  /* The lot id will be a text field , however user will require have a lot number that is present in shipments , should be internal receivable (Internal and Wholechain) */
  productId: {
    title: 'Product',
    dataIndex: 'productId',
    valueType: 'string',
    required: true,
  },
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
    valueType: 'string',
    required: true,
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
    valueType: 'number',
    required: true,
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
    valueType: 'string',
    required: true,
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
    valueType: 'string',
    required: true,
  },
  bizStep: {
    title: 'Business Step',
    dataIndex: 'bizStep',
    valueType: 'string',
    required: true,
    initialValue: 'Commissioning (CBV)',
  },
  disposition: {
    title: 'Disposition',
    dataIndex: 'disposition',
    valueType: 'string',
    required: true,
    initialValue: 'Active (CBV)',
  },
  timeZone: {
    title: 'Time Zone',
    dataIndex: 'timeZone',
    valueType: 'string',
    required: true,
    initialValue: '(UTC-05:00) Eastern Time (US & Canada)',
  },
};
export const transformTemplate: CSVTemplate = {
  productId: {
    title: 'Product',
    dataIndex: 'productId',
    valueType: 'string',
    required: true,
  },
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
    valueType: 'string',
    required: true,
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
    valueType: 'number',
    required: true,
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
    valueType: 'string',
    required: true,
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
    valueType: 'string',
    required: true,
  },
  bizStep: {
    title: 'Business Step',
    dataIndex: 'bizStep',
    valueType: 'string',
    required: true,
    initialValue: 'Commissioning (CBV)',
  },
  disposition: {
    title: 'Disposition',
    dataIndex: 'disposition',
    valueType: 'string',
    required: true,
    initialValue: 'Active (CBV)',
  },
  timeZone: {
    title: 'Time Zone',
    dataIndex: 'timeZone',
    valueType: 'string',
    required: true,
    initialValue: '(UTC-05:00) Eastern Time (US & Canada)',
  },
};
export const decomissionTemplate: CSVTemplate = {
  productId: {
    title: 'Product',
    dataIndex: 'productId',
    valueType: 'string',
    required: true,
  },
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
    valueType: 'string',
    required: true,
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
    valueType: 'number',
    required: true,
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
    valueType: 'string',
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
    valueType: 'string',
    required: true,
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
    valueType: 'string',
    required: true,
  },
  decommissionType: {
    title: 'Decommission Type',
    dataIndex: 'decommissionType',
    valueType: 'string',
    required: true,
  },
  description: {
    title: 'Description',
    dataIndex: 'description',
    valueType: 'string',
  },
  bizStep: {
    title: 'Business Step',
    dataIndex: 'bizStep',
    valueType: 'string',
    required: true,
    initialValue: 'Shipping (CBV)',
  },
  disposition: {
    title: 'Disposition',
    dataIndex: 'disposition',
    valueType: 'string',
    required: true,
    initialValue: 'In Transit (CBV)',
  },
  timeZone: {
    title: 'Time Zone',
    dataIndex: 'timeZone',
    valueType: 'string',
    required: true,
    initialValue: '(UTC-05:00) Eastern Time (US & Canada)',
  },
};
export const shipTemplate: CSVTemplate = {
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
    valueType: 'string',
    required: true,
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
    valueType: 'number',
    required: true,
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
    valueType: 'string',
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
    valueType: 'string',
    required: true,
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
    valueType: 'string',
    required: true,
  },
  shippingCompany: {
    title: 'Ship To Company',
    dataIndex: 'shippingCompany',
    valueType: 'string',
    required: true,
  },
  shippingAddress: {
    title: 'Ship To Address',
    dataIndex: 'shippingAddress',
    valueType: 'string',
    required: true,
  },
  tlcSourceDetails: {
    title: 'Traceability Lot Code Source Name',
    dataIndex: 'tlcSourceDetails',
    valueType: 'string',
    required: true,
    initialValue: '',
  },
  tlcSource: {
    title: 'Traceability Lot Code Source Address',
    dataIndex: 'tlcSource',
    valueType: 'string',
    required: true,
    initialValue: '',
  },
  bizStep: {
    title: 'Business Step',
    dataIndex: 'bizStep',
    valueType: 'string',
    required: true,
    initialValue: 'Shipping (CBV)',
  },
  disposition: {
    title: 'Disposition',
    dataIndex: 'disposition',
    valueType: 'string',
    required: true,
    initialValue: 'In Transit (CBV)',
  },
  timeZone: {
    title: 'Time Zone',
    dataIndex: 'timeZone',
    valueType: 'string',
    required: true,
    initialValue: '(UTC-05:00) Eastern Time (US & Canada)',
  },
};
export const batchShipTemplate: CSVTemplate = {
  productId: {
    title: 'Product',
    dataIndex: 'productId',
    valueType: 'string',
    required: true,
  },
  primaryId: {
    title: 'Lot Number',
    dataIndex: 'primaryId',
    valueType: 'string',
    required: true,
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
    valueType: 'string',
  },
  quantity: {
    title: 'Quantity',
    dataIndex: 'quantity',
    valueType: 'number',
    required: true,
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
    valueType: 'string',
    required: true,
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
    valueType: 'string',
    required: true,
  },
  bizStep: {
    title: 'Business Step',
    dataIndex: 'bizStep',
    valueType: 'string',
    required: true,
    initialValue: 'Shipping (CBV)',
  },
  disposition: {
    title: 'Disposition',
    dataIndex: 'disposition',
    valueType: 'string',
    required: true,
    initialValue: 'In Transit (CBV)',
  },
  timeZone: {
    title: 'Time Zone',
    dataIndex: 'timeZone',
    valueType: 'string',
    required: true,
    initialValue: '(UTC-05:00) Eastern Time (US & Canada)',
  },
};

export const translationMappings: {
  [key: string]: string;
} = {
  'Lot Number': 'lot_number',
  'Serial Number': 'serial_number',
  Quantity: 'quantity',
  'Purchase Order': 'purchase_order',
  'Logistic ID/SSCC': 'logistic_id',
  Date: 'date',
  Time: 'time',
  'Receive Date': 'receive_date',
  'Receive Time': 'receive_time',
  Location: 'location',
  'Business Step': 'bizstep',
  Disposition: 'disposition',
  'Time Zone': 'timezone',
  'Ship To Company': 'ship_to_company',
  'Ship To Address': 'ship_to_address',
  Product: 'csv_product_title',
  lotID: 'lot_number',
  quantity: 'quantity',
  poNumber: 'purchase_order',
  scss: 'logistic_id',
  date: 'date',
  time: 'time',
  receiveDate: 'receive_date',
  receiveTime: 'receive_time',
  bizStep: 'bizstep',
  disposition: 'disposition',
  timeZone: 'timezone',
  productId: 'csv_product_title',
  primaryId: 'lot_number',
  shippingCompany: 'ship_to_company',
  shippingAddress: 'ship_to_address',
  decommissionType: 'decommission_type',
  description: 'description',
  'Decommission Type': 'decommission_type',
  Description: 'description',
};

export const getTranslatedCSVTemplate = (template: CSVTemplate): CSVTemplate => {
  const translatedTemplate: CSVTemplate = {};
  Object.keys(template).forEach((fieldName) => {
    if (
      fieldName !== 'quantity' &&
      Object.keys(translationMappings).some((key) => key === fieldName)
    ) {
      let initialValue;
      const account = queryClient.getQueryData<GetAccountResponse | undefined>([
        'account',
        undefined,
      ]);

      switch (fieldName) {
        case 'bizStep':
          initialValue = template?.[fieldName]?.initialValue
            ? getBizStepT(String(template?.[fieldName]?.initialValue))
            : undefined;
          translatedTemplate[fieldName] = {
            ...template[fieldName],
            title: getProductsT(translationMappings[template?.[fieldName]?.title || '']),
            initialValue,
          };
          break;

        case 'disposition':
          initialValue = template?.[fieldName]?.initialValue
            ? getDispositionT(String(template?.[fieldName]?.initialValue))
            : undefined;
          translatedTemplate[fieldName] = {
            ...template[fieldName],
            title: getProductsT(translationMappings[template?.[fieldName]?.title || '']),
            initialValue,
          };
          break;

        case 'timeZone':
          initialValue = account?.timezone;
          translatedTemplate[fieldName] = {
            ...template[fieldName],
            title: getProductsT(translationMappings[template?.[fieldName]?.title || '']),
            initialValue: initialValue || '',
          };
          break;

        default:
          translatedTemplate[fieldName] = {
            ...template[fieldName],
            title: getProductsT(translationMappings[template?.[fieldName]?.title || '']),
          };
          break;
      }
    } else {
      switch (true) {
        case certKeys.some((key) => fieldName.startsWith(key)):
          {
            const certKey = fieldName?.split(' ')[0];
            const certKeyIndex = fieldName?.split(' ')[1];
            translatedTemplate[fieldName] = {
              ...template[fieldName],
              title: `${getProductsT(certificationMappings[certKey])} ${certKeyIndex}`,
            };
          }
          break;
        default:
          translatedTemplate[fieldName] = {
            ...template[fieldName],
          };
          break;
      }
    }
  });
  return translatedTemplate;
};
