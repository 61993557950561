import { BetaSchemaForm, ProFormColumnsType } from '@ant-design/pro-form';
import { Space, Typography } from 'antd';
import { TFunction } from 'i18next';
import { ProductItem } from 'pages/Products';
import { FC } from 'react';
import { LocationResponse, TradePartnerResponse } from 'services/api/client/src';
import { getT } from 'utils';
import { SelectAllTableProps } from '../typings';
import SelectCustomers from './SelectCustomers';
import SelectProducts from './SelectProducts';
import CompanyAndLocations from '../EnableIntegration/CompanyAndLocations';

type DataItem = {
  companyLocationTable: Array<LocationResponse>;
  productTable: Array<ProductItem>;
  customerTable: Array<TradePartnerResponse>;
};

type ColumnsType = {
  t?: TFunction<'pages', 'settings.comp_integration'>;
  isConfig?: boolean;
  isReview?: boolean;
};

const FormTableFields = ({ isConfig, isReview }: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    rowProps: { gutter: [24, 24] },
    columns: [
      {
        dataIndex: 'companyLocationTable',
        title: !isConfig && (
          <Typography.Title level={5}>
            {getT?.('settings.comp_integration.review.company_locations.title')}
          </Typography.Title>
        ),
        initialValue: [],
        renderFormItem: () => <CompanyAndLocations isReview={isReview} isConfig={isConfig} />,
        colProps: {
          xs: 24,
          md: 24,
        },
      },
      {
        dataIndex: 'productTable',
        title: !isConfig && (
          <Typography.Title level={5}>
            {getT('settings.comp_integration.review.product_table_title')}
          </Typography.Title>
        ),
        initialValue: [],
        renderFormItem: () => <SelectProducts isReview={isReview} isConfig={isConfig} />,
        colProps: {
          xs: 24,
          md: 24,
        },
      },
      {
        dataIndex: 'customerTable',
        title: !isConfig && (
          <Typography.Title level={5}>
            {getT('settings.comp_integration.review.customer_table_title')}
          </Typography.Title>
        ),
        initialValue: [],
        renderFormItem: () => <SelectCustomers isReview={isReview} isConfig={isConfig} />,
        colProps: {
          xs: 24,
          md: 24,
        },
      },
    ],
  },
];

const SelectAllTables: FC<SelectAllTableProps> = ({ form, tableTitle, isReview, isConfig }) => (
  <Space direction="vertical" size="large">
    {tableTitle}
    <BetaSchemaForm<DataItem>
      columns={FormTableFields({ isReview, isConfig })}
      form={form}
      grid
      layoutType="Form"
      submitter={{ render: () => null }}
    />
  </Space>
);

export default SelectAllTables;
