import { LeftOutlined } from '@ant-design/icons';
import { Button, Modal, Segmented, Skeleton, Space, Switch, Typography } from 'antd';
import { EventErrorModal, ShareDocumentModal } from 'components';
import { DocumentItem, ViewDocumentForm, useDocumentActions } from 'pages/Documents';
import { useEventActions } from 'pages/Events/components';
import pluralize from 'pluralize';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { globalStyles } from 'utils';
import styles from '../index.module.less';
import {
  DOCUMENT,
  DetailsModalProps,
  DetailsSection,
  EPCIS,
  EVENT_DATA,
  EVENT_DETAILS,
} from '../typings';
import DataContent from './DataContent';
import DetailsContent from './DetailsContent';
import Documents from './Documents';
import EpcisContent from './EpcisContent';

const { Title } = Typography;

const skeletonLoader = (
  <div>
    <Skeleton loading active />
    <Skeleton loading active />
    <Skeleton loading active />
  </div>
);
const DetailsModal: FC<DetailsModalProps> = ({
  modalProps,
  product,
  productInstance,
  event,
  activeEvent,
  key,
  loading = false,
  modalWidth,
  closeAllModal,
  hideDocument,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });
  const sections = [EVENT_DETAILS, EVENT_DATA, DOCUMENT];
  const segmentedOptions = useMemo(
    () =>
      hideDocument
        ? [
            { value: EVENT_DETAILS, label: t('event_details_title') },
            { value: EVENT_DATA, label: t('event_data_title') },
          ]
        : [
            { value: EVENT_DETAILS, label: t('event_details_title') },
            { value: EVENT_DATA, label: t('event_data_title') },
            { value: DOCUMENT, label: t('documents_title') },
          ],
    [hideDocument, t],
  );
  const [activeSection, setActiveSection] = useState<DetailsSection>(sections[0] as DetailsSection);
  const [isPretty, setPretty] = useState<boolean>(true);
  const { eventErrorModal } = useEventActions();
  const isSerial = useMemo(
    () => activeEvent?.productInstances?.[0]?.productIdentifierType === 'Serial',
    [activeEvent],
  );
  const identifier = useMemo(
    () => (isSerial ? t?.('serial_title') : t?.('lot_title')),
    [isSerial, t],
  );
  const isContainer = useMemo(() => (activeEvent?.containers?.length || 0) > 0, [activeEvent]);
  const containerIdentifier = useMemo(
    () => activeEvent?.containers?.[0]?.containerIdentifier || '',
    [activeEvent],
  );
  const [documentId, setDocumentId] = useState<string | undefined>();

  const {
    onDownloadDocument,
    onArchiveDocument,
    copyURL,
    onShareDocument,
    shareModal,
    selectedRowKeys,
    onRowSelectionChange,
    resetRowSelection,
  } = useDocumentActions();

  const onCloseModal = useCallback(() => {
    closeAllModal?.();
    modalProps?.hide();
  }, [closeAllModal, modalProps]);

  const handleDocumentActionsMenuClick = useCallback(
    async (actionItemKey: string, actionPayload?: DocumentItem) => {
      switch (actionItemKey) {
        case 'view':
          setDocumentId(String(actionPayload?.id || 0));
          break;
        case 'download':
          onDownloadDocument(actionPayload);
          break;
        case 'share':
          onShareDocument(actionPayload);
          break;
        case 'email':
          break;
        case 'copyURL':
          copyURL(actionPayload);
          break;

        case 'archive':
          onArchiveDocument(actionPayload);
          break;

        default:
          break;
      }
    },
    [onDownloadDocument, onShareDocument, copyURL, onArchiveDocument],
  );
  const renderSection = useCallback(() => {
    switch (activeSection) {
      case EVENT_DETAILS:
        return (
          <DetailsContent
            productInstance={productInstance}
            product={product}
            event={event}
            activeEvent={activeEvent}
            isSerial={isSerial}
            identifier={identifier}
          />
        );
      case EVENT_DATA:
        return (
          <DataContent
            t={t}
            productInstance={productInstance}
            product={product}
            event={event}
            isPretty={isPretty}
            activeEvent={activeEvent}
            isSerial={isSerial}
            identifier={identifier}
          />
        );

      case EPCIS:
        return (
          <EpcisContent
            t={t}
            productInstance={productInstance}
            product={product}
            event={event}
            activeEvent={activeEvent}
            isPretty={isPretty}
            isSerial={isSerial}
            identifier={identifier}
          />
        );
      case DOCUMENT:
        return (
          <Documents
            productInstance={productInstance}
            product={product}
            event={event}
            activeEvent={activeEvent}
            selectedRowKeys={selectedRowKeys}
            onRowSelectionChange={onRowSelectionChange}
            actionsMenuClick={handleDocumentActionsMenuClick}
          />
        );

      default:
        return <DataContent />;
    }
  }, [
    activeSection,
    event,
    product,
    productInstance,
    isPretty,
    activeEvent,
    selectedRowKeys,
    onRowSelectionChange,
    handleDocumentActionsMenuClick,
    isSerial,
    identifier,
    t,
  ]);

  const onCloseDocumentModal = useCallback(() => setDocumentId(undefined), []);

  return (
    <>
      <EventErrorModal modal={eventErrorModal} />
      <ShareDocumentModal modal={shareModal} cleanUpAfterShare={resetRowSelection} />
      {!!documentId && <ViewDocumentForm docId={documentId} onModalClose={onCloseDocumentModal} />}
      <Modal
        {...modalProps}
        key={key}
        mask={!modalWidth}
        width={modalWidth || '70%'}
        className={styles.detailsmodal}
        onCancel={onCloseModal}
        title={
          <Space>
            {closeAllModal && <LeftOutlined onClick={() => modalProps?.hide()} />}
            <Title level={5}>
              {isContainer
                ? containerIdentifier
                : product?.name || activeEvent?.productInstances?.[0]?.productName}
            </Title>
            <Segmented
              options={segmentedOptions}
              value={activeSection}
              onChange={(val) => setActiveSection(val as DetailsSection)}
            />
          </Space>
        }
        footer={
          <>
            {(activeSection === EPCIS || activeSection === EVENT_DATA) && (
              <span style={globalStyles.fleft}>
                {t('beutify')}:&nbsp;
                <Switch
                  defaultChecked
                  size="small"
                  checked={isPretty}
                  style={globalStyles.z4}
                  onChange={setPretty}
                />
              </span>
            )}
            <Space>
              {activeSection === DOCUMENT && selectedRowKeys?.length > 0 && (
                <Typography.Title level={5} className="selectiontext">
                  {`${selectedRowKeys.length} ${pluralize(t('doc'), selectedRowKeys.length)} ${t(
                    'selected',
                  )}`}
                </Typography.Title>
              )}
              <Button onClick={onCloseModal} type="primary" shape="round">
                Save
              </Button>
            </Space>
          </>
        }
      >
        {loading ? skeletonLoader : renderSection()}
      </Modal>
    </>
  );
};
export default DetailsModal;
