import { BetaSchemaForm } from '@ant-design/pro-form';
import { Form, message, Modal, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions, ShareTemplate } from 'components';
import { useDrawerVisibility } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fetchTemplateById,
  useAccount,
  useCreateTemplate,
  useTemplateById,
  useUpdateTemplate,
} from 'services/api';
import {
  CreateAttributeRequest,
  CreateEventTemplateRequest,
  GetEventTemplateByIdResponse,
  TemplateDocumentRequest,
  UpdateEventTemplateRequest,
} from 'services/api/client/src';
import {
  documentTypes,
  errorHandler,
  filterOnlyVesselCatchAttributes,
  filterTAttributes,
  getEventsT,
  hasUnsavedChanges,
  mapAdvanceSettings,
  mapCertDocuments,
  mapCertInfoRequest,
  mapCustomAtrribute,
  mapTFieldtoCustomAttribute,
} from 'utils';
import useAttributeStore from '../Tables/useAttributeStore';
import { DataItem, TemplateItem } from '../typings';
import useTemplateActions from '../useTemplateActions';
import AddTemplateFormFields from './AddTemplateForm.fields';
import ViewTemplateDrawerHeader from './ViewTemplateDrawerHeader';

const rowProps = { gutter: 32 };

const ActionItems = ({
  isEdit,
  role,
  t,
}: {
  isEdit: boolean;
  role?: string;
  t?: TFunction<'pages', 'templates.view_template'>;
}): Array<ItemType> =>
  role === 'Editor'
    ? [
        {
          label: !isEdit ? t?.('edit') : t?.('view'),
          key: 'edit',
        },
        {
          label: t?.('preview'),
          key: 'preview',
        },
        {
          label: t?.('share'),
          key: 'share',
        },
        {
          label: t?.('duplicate'),
          key: 'duplicate',
        },
        {
          label: t?.('delete'),
          key: 'delete',
        },
      ]
    : [
        {
          label: t?.('preview'),
          key: 'preview',
        },
        {
          label: t?.('delete'),
          key: 'delete',
        },
      ];

const ViewTemplateForm: React.FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates.view_template' });
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const { shareModal, onShareTemplate, onDeleteTemplate } = useTemplateActions();
  const { data: dataAccount } = useAccount();
  const [mainForm] = Form.useForm();
  const [headerForm] = Form.useForm(mainForm);
  const { templateId = '' } = useParams();
  const templateQuery = useTemplateById(templateId);
  const queryClient = useQueryClient();
  const updateTemplate = useUpdateTemplate(queryClient);
  const createTemplate = useCreateTemplate(queryClient);
  const { templateMode, setTemplateMode } = useAttributeStore();
  const isEdit = templateMode === 'edit';
  const navigate = useNavigate();
  const templateRole = useMemo(
    () =>
      templateQuery?.data?.sharedWith?.find((i) => i?.accountId === dataAccount?.id, [])?.role ||
      templateQuery?.data?.role,
    [dataAccount?.id, templateQuery?.data?.role, templateQuery?.data?.sharedWith],
  );

  /* on close modal */
  const onClose = useCallback(() => {
    const hasFilled = mainForm.isFieldsTouched();

    if (hasFilled && isEdit) {
      Modal.warning({
        title: t?.('unsaved_changes_title'),
        content: t?.('unsaved_changes_desc'),
        okText: t?.('unsaved_changes_submit'),
        cancelText: t?.('unsaved_changes_cancel'),
        onOk: closeDrawer,
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      });
    } else {
      closeDrawer();
    }
  }, [closeDrawer, isEdit, mainForm, t]);

  const onFinish = async (formData: DataItem) => {
    try {
      const name = headerForm?.getFieldValue('name');
      const role = templateRole || 'Viewer';
      if (role === 'Editor') {
        if (!formData?.customAttributes?.length) {
          throw new Error(t('form_fields.custom_template_required'));
        }
        hasUnsavedChanges();

        const customAttributes =
          formData?.customAttributes?.map(mapCustomAtrribute)?.filter?.(filterTAttributes) || [];
        const advancedSettings = formData?.advanceSettings?.map(mapAdvanceSettings) || [];
        const vesselAttributes = formData?.vesselCatchFields?.map(mapCustomAtrribute) || [];

        const templateFields = [...customAttributes, ...advancedSettings, ...vesselAttributes];

        const templateDocuments: TemplateDocumentRequest[] =
          formData?.documents?.map((doc) => ({
            id: String(doc?.id)?.includes('new') ? undefined : String(doc?.id),
            documentName: doc?.name || documentTypes(doc?.type || 'other')?.name,
            required: doc?.required,
            documentType: documentTypes(doc?.type || 'other')?.dataIndex,
          })) || [];

        const reqBody: UpdateEventTemplateRequest = {
          name: formData.name || name,
          templateFields,
          templateDocuments,
          allowVesselCatch: formData?.additionalOptions?.includes('vesselCatch') || false,
          allowCertificationDocuments:
            formData?.additionalOptions?.includes('certificationDocuments') || false,
          templateCertifications: formData?.certificationFields?.map(mapCertInfoRequest) || [],
        };

        await updateTemplate.mutateAsync({
          id: templateId,
          body: reqBody,
        });

        message.success(t('success_message', { id: formData.name || name || '' }));
      }

      navigate(-1);
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  const toggleEdit = () => {
    setTemplateMode?.(isEdit ? 'view' : 'edit');
  };
  const createDuplicate = async () => {
    const done = message.loading(t('action_progress'), 0);
    try {
      const template = templateQuery?.data;
      const templateFields: CreateAttributeRequest[] =
        template?.templateAttributes?.map((field) => ({
          name: field?.attribute?.name || '',
          fieldProperties: {
            fields: {
              propertyName: field?.attribute?.fieldProperties?.fields?.propertyName,
              fieldType: field?.attribute?.fieldProperties?.fields?.fieldType,
              required: field?.attribute?.fieldProperties?.fields?.required,
              hidden: field?.attribute?.fieldProperties?.fields?.hidden,
            },
            values: {
              valueOptions: field?.attribute?.fieldProperties?.values?.valueOptions,
              defaultValue: field?.attribute?.fieldProperties?.values?.defaultValue,
            },
            location: field?.attribute?.fieldProperties?.location,
            namespace: {
              name: field?.attribute?.fieldProperties?.namespace?.name,
              prefix: field?.attribute?.fieldProperties?.namespace?.prefix,
              uri: 'wholechain.com',
            },
          },
        })) || [];
      const reqBody: CreateEventTemplateRequest = {
        name: `${template?.templateName} (copy)`,
        templateFields,
        templateDocuments:
          template?.templateDocuments?.map((doc) => ({
            name: doc.documentName,
            required: doc.required,
            type: doc.documentType,
          })) || [],
      };
      await createTemplate.mutateAsync(reqBody);
      done();
      message.success(t('duplicate_success_message', { id: template?.templateName }));
      closeDrawer();
    } catch (error) {
      done();
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  const onRowActionClick = (actionKey: string) => {
    switch (actionKey) {
      case 'edit':
        toggleEdit();
        break;
      case 'preview':
        navigate('preview');
        break;
      case 'duplicate':
        createDuplicate();
        break;
      case 'share':
        onShareTemplate({
          ...templateQuery?.data,
          ownerAccountName: templateQuery?.data?.accountName,
        } as TemplateItem);
        break;
      case 'delete':
        onDeleteTemplate(
          actionKey,
          {
            ...templateQuery?.data,
            title: templateQuery?.data?.templateName,
          } as TemplateItem,
          closeDrawer,
        );
        break;
      default:
        break;
    }
  };

  const getValues = useCallback(async () => {
    const templateData = (await queryClient.fetchQuery(['template', templateId], () =>
      fetchTemplateById(templateId),
    )) as GetEventTemplateByIdResponse;
    headerForm?.setFieldsValue({
      name: templateData?.templateName,
    });
    const isVesselCatchEnabled = templateData?.allowVesselCatch;
    const isCertDocsEnabled = templateData?.allowCertificationDocuments;
    const additionalOptions = [
      ...(isVesselCatchEnabled ? ['vesselCatch'] : []),
      ...(isCertDocsEnabled ? ['certificationDocuments'] : []),
    ];
    const vesselFields: DataItem['vesselCatchFields'] = isVesselCatchEnabled
      ? templateData?.templateAttributes
          ?.map(mapTFieldtoCustomAttribute)
          ?.filter?.(filterOnlyVesselCatchAttributes) || []
      : [];
    const certFields: DataItem['certificationFields'] = isCertDocsEnabled
      ? templateData?.templateCertifications?.map(mapCertDocuments) || []
      : [];

    const values: DataItem = {
      ...templateData,
      additionalOptions,
      vesselCatchFields: vesselFields,
      certificationFields: certFields,
    };
    return values;
  }, [queryClient, templateId, headerForm]);
  const onSubmit = async () => {
    try {
      await mainForm?.validateFields();
      mainForm?.submit();
    } catch (error) {
      message.error(getEventsT('events_field_req'));
    }
  };

  const Title = useMemo(
    () => (
      <ViewTemplateDrawerHeader
        title={t('title')}
        form={headerForm}
        readonly={!isEdit}
        templateName={templateQuery?.data?.templateName || ''}
      />
    ),
    [headerForm, isEdit, t, templateQuery?.data?.templateName],
  );

  return (
    <>
      <ShareTemplate modal={shareModal} />
      <BetaSchemaForm<DataItem>
        form={mainForm}
        layoutType="DrawerForm"
        columns={AddTemplateFormFields({ isView: true, isEdit, templateQuery, t })}
        grid
        // @ts-ignore
        title={Title}
        visible={visible}
        rowProps={rowProps}
        autoFocusFirstInput
        drawerProps={{
          destroyOnClose: true,
          height: '100%',
          placement: 'top',
          size: 'large',
          ...restDrawerVisibility,
          onClose,
        }}
        request={getValues}
        submitter={{
          searchConfig: {
            submitText: isEdit ? t?.('submit') : t?.('done'),
          },
          submitButtonProps: {
            shape: 'round',
          },
          resetButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
            onClick: onClose,
          },
          render: ({ form }, defaultDoms) => (
            <Space>
              {!isEdit && (
                <Actions
                  className="actions"
                  buttonProps={{
                    ghost: true,
                  }}
                  items={ActionItems({ isEdit, role: templateRole, t })}
                  onClick={onRowActionClick}
                  actionPayload={form}
                />
              )}
              {isEdit && defaultDoms[0]}
              {defaultDoms[1]}
            </Space>
          ),
          onSubmit,
        }}
        submitTimeout={2000}
        onFinish={onFinish}
      />
    </>
  );
};
export default React.memo(ViewTemplateForm);
