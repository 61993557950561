import { ProFieldValueType } from '@ant-design/pro-field';
import { ProColumns } from '@ant-design/pro-table';
import { ProFieldValueObjectType } from '@ant-design/pro-utils';
import { RcFile } from 'antd/lib/upload';
import { MarkerItemProps } from 'components/GMap/typings';
import { GTableDateType } from 'components/GTable/contexts/typings';
import { flatten } from 'flat';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { FlatObject } from 'pages/AllShipments/PreviewEvent/typings';
import { translationMappings } from 'pages/Events';
import { ProductInventoryItem } from 'pages/Products';
import { Shipment } from 'pages/Shipments/typings';
import Papa from 'papaparse';
import { TagItem } from 'services/api';
import {
  Address,
  EventNodeContainer,
  EventNodeStub,
  MapModel,
  MapNode,
  ProductResponse,
} from 'services/api/client/src';
import { phoneCodes } from 'utils/timezones';
import XLSX from 'xlsx';
import { getProductsT, getT } from './language';
import { isObject } from './locations';
import { getTExpections } from './products';
import { getStringifiedVal } from './template';
import {
  CSVTemplate,
  CheckRequestedDocumentsProps,
  ConcatDateTimeFn,
  DocumentTags,
  FeatureFlagProps,
  JSONData,
} from './typings';

export const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

export const camelToTitleCase = (str: string) =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const dateFormat = (str: string | Date) => moment(str).format('YYYY-MM-DD');
export const dateLocaleFormat = (date?: Date) => {
  if (date && date instanceof Date) {
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    const localTime = new Date(date.getTime() + timezoneOffset);
    return moment(localTime).format('YYYY-MM-DD HH:mm:ss');
  }
  return '';
};
export const dateLocalFormat = (date: Date) => moment(date).format('YYYY-MM-DD HH:mm:ss');
export const timeLocaleFormat = (date: Date) => moment(date).format('HH:mm:ss');

export const getCurrentLocaleDate = () => {
  const date = new Date();
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const localTime = new Date(date.getTime() + timezoneOffset);
  return moment(localTime).format('YYYY-MM-DD HH:mm:ss');
};

export const apiDateFormat = (date?: string, time?: string) => {
  /* new Date('') shall return an invalid date */
  const invalidDate = new Date('');
  if (!date || !time) return invalidDate;

  const isValidDate = moment(date).isValid();
  const isValidTime = moment(time, 'HH:mm:ss', true).isValid();

  if (isValidDate && isValidTime) {
    const dateObj = new Date(`${date} ${time}`);
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    const apiDate = new Date(dateObj.getTime() - timezoneOffset);
    return apiDate;
  }
  return invalidDate;
};
/**
 * @param date
 *
 * @param time
 *
 * @returns string
 *
 * @description concat date and time to return string format in YYYY-MM-DD HH:mm:ss. The parameters date, time can be instances of moment or string, this function will handle both cases.
 */
export const concatDateTime: ConcatDateTimeFn = (date, time) => {
  if (moment.isMoment(date) || moment.isMoment(time)) {
    /* Convert date and time to Moment instances if they are strings */
    const momentDate = moment.isMoment(date) ? date : moment(date);
    const momentTime = moment.isMoment(time) ? time : moment(time);

    /* Format date and time as strings */
    const formattedDate = moment(momentDate).format('YYYY-MM-DD');
    const formattedTime = moment(momentTime).format('HH:mm:ss');

    return formattedDate && formattedTime ? `${formattedDate} ${formattedTime}` : undefined;
  }
  /* If both date and time are strings, assume valid formats */
  const dateParts = date?.split(' ') || [];
  const timeParts = time?.split(' ') || [];

  // Extract YYYY-MM-DD for date (if not provided)
  const fDate = dateParts.length > 1 ? dateParts?.[0] : date;

  // Extract HH:mm:ss for time (if not provided)
  const fTime = timeParts.length > 1 ? timeParts?.[1] : time;

  return fDate && fTime ? `${fDate} ${fTime}` : undefined;
};

export const camelize = (str: string) =>
  str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    .replace(/[&/\\#,+()$~%.'":*?<>{}!@^_]/g, '');

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
export const capitalizeFirstLetterLow = (str: string) =>
  str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
export const getDivImage = async (divId: string, quality: number = 0.5) => {
  const element = document.getElementById(divId);
  if (element) {
    const canvas = await html2canvas(element);

    return canvas.toDataURL('image/png', quality);
  }
  return '';
};
export const filterEmptyRows = (data: any[]) =>
  data.filter((row) => Object.values(row).some((v) => v));

export const csvtoJSON = (file: File) =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      /* allow special characters and preceeding zeros */
      encoding: 'ISO-8859-1',
      complete: (results) => {
        const filteredData = filterEmptyRows(results.data);
        const utf8Data = filteredData.map((row) => {
          const utf8Row = Object.keys(row).reduce((acc, key) => {
            // new key should be in utf8
            const newKey = getTExpections(key);
            acc[newKey] = row[key];
            return acc;
          }, {} as { [key: string]: string });
          return utf8Row;
        });

        resolve(utf8Data);
      },
      error: (error) => {
        reject(error);
      },
    });
  });

/* read csv as json without papaparse and ensure that the utf8 formatting is not changed */
export const csvtoJSONv2 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file, 'utf-8');
    reader.onload = (event) => {
      const csv = event.target?.result;
      const data = csv?.toString().split('\r');

      const headers = data?.[0].split(',');
      // remove new line
      const filteredData = data
        ?.slice(1)
        .filter((row) => row.split(',').some((v) => v.replace('\n', '')));
      const utf8Data = filteredData?.map((row) => {
        const utf8Row = row.split(',').reduce((acc, value, index) => {
          const key = headers?.[index] || '';
          acc[key] = value.replace('\n', '');
          return acc;
        }, {} as { [key: string]: string });
        return utf8Row;
      });
      const ut8FilterData = filterEmptyRows(utf8Data || []);
      resolve(ut8FilterData);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

export const readExcelTemplate = (file: RcFile) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      // make sure to read each and every cell as string  using xlsx
      const workbook = XLSX.read(data, { type: 'binary', bookVBA: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      resolve(json);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsBinaryString(file);
  });

export const readNewReceiveExcelTemplate = (file: RcFile) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      // make sure to read each and every cell as string  using xlsx
      const workbook = XLSX.read(data, { type: 'binary', bookVBA: true });
      const sheetName1 = workbook.SheetNames[0];
      const sheetName2 = workbook.SheetNames[1];
      const worksheet1 = workbook.Sheets[sheetName1];
      const worksheet2 = workbook.Sheets[sheetName2];
      const receiveEventDetails = XLSX.utils.sheet_to_json(worksheet1);
      const receiveItemDetails = XLSX.utils.sheet_to_json(worksheet2);
      const json = {
        receiveEventDetails,
        receiveItemDetails,
      };
      resolve(json);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsBinaryString(file);
  });

export const downloadJsonToCSV = (
  data: Array<JSONData | undefined> | Array<GTableDateType> | undefined,
  fileName = 'data',
) => {
  if (!data) return;
  /* modify data key values to string so that object object is not present */
  const modifiedData = data?.map((item) => {
    const keys = Object.keys(item || {});
    const modifiedItem = keys.reduce(
      (acc, key) => {
        /* if item?.[key]  is object than strigify it otherwise modify to string value and make sure to account for JSON.stringify issues */
        if (item?.[key] && typeof item?.[key] === 'object') {
          acc[String(key)] = JSON.stringify(item?.[key]);
        } else if (item?.[key] && typeof item?.[key] === 'string') {
          acc[String(key)] = item?.[key];
        } else if (item?.[key] && typeof item?.[key] === 'number') {
          acc[String(key)] = String(item?.[key]);
        } else if (item?.[key] && typeof item?.[key] === 'boolean') {
          acc[String(key)] = String(item?.[key]);
        } else if (item?.[key] && typeof item?.[key] === 'undefined') {
          acc[String(key)] = '';
        } else {
          acc[String(key)] = '';
        }

        return acc;
      },
      {} as {
        [key: string]: string;
      },
    );
    return modifiedItem;
  });

  const csv = Papa.unparse(modifiedData);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.csv`);
  link.click();
};

export const downloadJSONToCSVLotDetail = (
  actionPayload: ProductInventoryItem,
  instances: MapModel,
) => {
  const workbook = XLSX.utils.book_new();

  const nodes = instances?.nodes || [];
  const events = nodes.flatMap((item) => item?.events || []);
  events.forEach((item, idx) => {
    const isContainer =
      !!item?.container?.identifier || (item?.containers?.[0]?.products?.length || 0) > 0;
    const newObject: FlatObject = {};
    const flatArray: Array<FlatObject> = [];
    Object.keys(item).forEach((key) => {
      const value = item?.[key as keyof EventNodeStub] || '';
      const newValue = getStringifiedVal(value);

      /* flatten if value is an object */
      if (isObject(value) && key !== 'container') {
        /* remove array value from value before flatten */

        const flattenedobject = flatten(value, {
          delimiter: '_',
        }) as FlatObject;
        Object.keys(flattenedobject).forEach((k) => {
          newObject[`${key}_${k}`] = flattenedobject[k];
        });
      } else {
        switch (key) {
          /* ignore some keys */
          case 'inputProducts':
            break;
          case 'products':
            break;
          case 'outputProducts':
            break;
          case 'container':
            /* flatten all keys expect products */
            // eslint-disable-next-line no-case-declarations
            const containerObject = value as EventNodeContainer;
            Object.keys(containerObject).forEach((k) => {
              if (k !== 'products') {
                newObject[`${key}_${k}`] = containerObject?.[k as keyof EventNodeContainer];
              }
            });
            break;

          default:
            newObject[key] = newValue;
            break;
        }
      }
    });

    // loop over products , inputProducts, outputProducts, containerProducts

    item?.inputProducts?.forEach((p) => {
      const object = {
        ...newObject,
        type: 'Input',
        ...p,
      };
      flatArray.push(object);
    });

    item?.outputProducts?.forEach((p) => {
      const object = {
        ...newObject,
        type: 'Output',
        ...p,
      };
      flatArray.push(object);
    });
    if (!isContainer) {
      item?.products?.forEach((p) => {
        const object = {
          ...newObject,
          ...p,
        };
        flatArray.push(object);
      });
    } else {
      (item?.container || item?.containers?.[0])?.products?.forEach((p) => {
        const object = {
          ...newObject,
          ...p,
        };
        flatArray.push(object);
      });
    }
    const sheet = XLSX.utils.json_to_sheet(flatArray);
    XLSX.utils.book_append_sheet(workbook, sheet, `${item.eventType}-${idx}`);
  });
  if (actionPayload?.isContainer) {
    XLSX.writeFile(workbook, `Events_SSCC_${actionPayload?.containerIdentifier || ''}.xlsx`);
  } else {
    XLSX.writeFile(workbook, `Events_${actionPayload?.name || ''}.xlsx`);
  }
};

export const keysToSampleObject = (template: CSVTemplate) => {
  const keys = Object.keys(template);
  const sampleDataObject = keys.reduce(
    (acc, key) => {
      if (template?.[key]?.title) {
        acc[String(template?.[key]?.title)] = String(template?.[key]?.initialValue || '');
        return acc;
      }
      return acc;
    },
    {} as {
      [key: string]: string;
    },
  );
  return sampleDataObject;
};
export const titletoKeys = (template: CSVTemplate, rawDataObj: any) => {
  const keys = Object.keys(template);
  const mappedObject = keys.reduce(
    (acc, key) => {
      const includeTransKeys = Object.keys(translationMappings).some((k) => k === key);

      let title;
      if (includeTransKeys) {
        switch (key) {
          case 'quantity':
            title = template?.[key]?.title;
            break;

          default:
            title = template?.[key]?.title
              ? getProductsT(translationMappings[template?.[key]?.title || ''])
              : '';
            break;
        }
      } else {
        title = template?.[key]?.title;
      }

      if (rawDataObj[String(title)]) {
        const valueType = template?.[key]?.valueType || 'string';
        if (valueType === 'number') {
          const isValidNumber = !Number.isNaN(Number(rawDataObj[String(title)]));
          acc[String(key)] = isValidNumber ? Number(rawDataObj[String(title)]) : undefined;
        } else {
          acc[String(key)] = String(rawDataObj[String(title)]);
        }
      } else {
        acc[String(key)] = template?.[key]?.initialValue;
      }
      return acc;
    },
    {} as {
      [key: string]: any;
    },
  );
  return mappedObject;
};

export const downloadCSVTemplate = (template: CSVTemplate, fileName = 'template') => {
  const sampleDataObj = keysToSampleObject(template);
  downloadJsonToCSV([sampleDataObj], fileName);
};

export const downloadXLSXTemplate = (template: CSVTemplate, fileName = 'template') => {
  const sampleDataObj = keysToSampleObject(template);
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([sampleDataObj]);
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export const isInvalidEmail = (value: string) => {
  // eslint-disable-next-line no-useless-escape
  const emailRegex =
    /^(?=.{1,256})(?=.{1,64}@.{1,255}$)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
  if (emailRegex.test(String(value))) {
    return false;
  }
  return true;
};
/* It removes underscore from the label of radio button i.e. Third_Party */
export const cleanTitle = (value: string) => value.replace('_', ' ');
export const generateDocTags = ({ tradePartners, products, locations, events }: DocumentTags) => {
  const tags: Array<TagItem> = [];
  if (tradePartners) {
    tradePartners.forEach((item) => {
      tags.push({
        value: String(item.id),
        label: item.name,
        type: 'Company',
      });
    });
  }
  if (products) {
    products.forEach((item) => {
      tags.push({
        value: String(item.id),
        label: item.name,
        type: 'Product',
      });
    });
  }
  if (locations) {
    locations.forEach((item) => {
      tags.push({
        value: String(item.id),
        label: item.name,
        type: 'Location',
      });
    });
  }
  if (events) {
    events.forEach((item) => {
      tags.push({
        value: String(item.id),
        label: item.name,
        type: 'Event',
        disabled: true,
        closable: false,
      });
    });
  }
  return tags;
};

export const fieldTypes: {
  [key: string]: {
    name?: string;
    dataIndex?: string;
    valueType?: ProFieldValueType | ProFieldValueObjectType;
    isDefault?: boolean;
    fieldProps?: ProColumns['fieldProps'];
  };
} = {
  Text: {
    name: 'Text',
    dataIndex: 'Text',
    valueType: 'text',
    isDefault: true,
    fieldProps: {
      size: 'small',
      placeholder: 'Enter default value',
    },
  },
  Number: {
    name: 'Number',
    dataIndex: 'Number',
    valueType: 'digit',
    fieldProps: {
      stringMode: true,
      size: 'small',
      placeholder: '0.00',
      className: 'full-width',
    },
  },
  Dropdown: {
    name: 'Dropdown',
    dataIndex: 'Dropdown',
    valueType: 'select',
    fieldProps: {
      size: 'small',
      placeholder: 'Select or create Options',
    },
  },
  RadioButton: {
    name: 'RadioButton',
    dataIndex: 'RadioButton',
    valueType: 'radio',
    fieldProps: {
      size: 'small',
      placeholder: 'Select or create Options',
    },
  },
  Date: {
    name: 'Date',
    dataIndex: 'Date',
    valueType: 'date',
    fieldProps: {
      size: 'small',
      placeholder: 'Select date',
      className: 'full-width',
    },
  },
};

export const documentTypes = (type: string) => {
  const t = (key: string) => getT(`template_attributes.documents.${key}`);

  const doctypes: {
    [key: string]: {
      name: string;
      dataIndex: string;
    };
  } = {
    certification: {
      name: t('columns.document_type.filters.certification'),
      dataIndex: 'Certification',
    },
    Certification: {
      name: t('columns.document_type.filters.certification'),
      dataIndex: 'Certification',
    },
    purchaseOrder: {
      name: t('columns.document_type.filters.purchase_order'),
      dataIndex: 'PurchaseOrder',
    },
    'Purchase Order': {
      name: t('columns.document_type.filters.purchase_order'),
      dataIndex: 'PurchaseOrder',
    },
    PurchaseOrder: {
      name: t('columns.document_type.filters.purchase_order'),
      dataIndex: 'PurchaseOrder',
    },
    bol: {
      name: t('columns.document_type.filters.bol'),
      dataIndex: 'BOL',
    },
    BOL: {
      name: t('columns.document_type.filters.bol'),
      dataIndex: 'BOL',
    },
    other: {
      name: t('columns.document_type.filters.other'),
      dataIndex: 'Other',
    },
    Other: {
      name: t('columns.document_type.filters.other'),
      dataIndex: 'Other',
    },
  };
  return doctypes?.[type] || {};
};

export const flattenAddress = (address?: Address) => {
  if (!address) {
    return '';
  }
  const shortAddress = {
    line1: address?.line1 || '',
    // line2: address?.line2 || '',
    city: address?.city || '',
    state: address?.state || '',
    country: address?.country || '',
    postal: address?.postalCode || '',
  };
  const values = Object.values(shortAddress);
  return address?.line2 || values.filter((value) => value && String(value).trim()).join(', ');
};

export const reverseFlattenAddress = (address?: string): Address => {
  if (!address) {
    return {};
  }
  const values = address.split(',');
  const shortAddress = {
    line1: values[0],
    city: values[1],
    state: values[2],
    country: values[3],
    postal: values[4],
  };
  return shortAddress;
};

export const isSameKey = <T = any>(key: string, arr?: Array<T>) => {
  const values = arr?.map((item) => item[key as keyof T]) || [];
  const uniqueValues = new Set(values);
  return uniqueValues.size === 1;
};

export const isContainer = <T = any>(arr?: Array<T>) => {
  const value = arr?.some((item) => item?.['isContainer' as keyof T]) || false;
  return value;
};

export const hasContainer = <T = any>(arr?: Array<T>) => {
  if (!arr || arr.length === 0) {
    return { value: false };
  }
  let trueCount = 0;
  let falseCount = 0;
  arr.forEach((item) => {
    if (item?.['hasContainer' as keyof T]) {
      trueCount += 1;
    } else {
      falseCount += 1;
    }
  });
  // If all elements are true or all are false, return false
  if (trueCount === arr.length || falseCount === arr.length) {
    return { value: false };
  }
  // If the count of true is greater or equal to the count of false, return true
  return { value: true, type: trueCount >= falseCount ? 'sscc' : 'lot' };
};

export const isExtTraceSys = <T = any>(arr?: Array<T>) => {
  const value = arr?.some((item) => !!item?.['externalShipmentId' as keyof T]) || false;
  return value;
};
export const isShip = <T = any>(arr?: Array<T>) => {
  const value =
    arr?.some((item) => String(item?.['eventName' as keyof T] || '') === 'Ship') || false;
  return value;
};
export const isRejected = <T = any>(arr?: Array<T>) => {
  const value =
    arr?.some((item) => String(item?.['status' as keyof T] || '') === 'Rejected') || false;
  return value;
};

export const getContainerIds = <T = any>(arr?: Array<T>) => {
  const value = arr?.filter((item) => item?.['isContainer' as keyof T]) || [];
  return value.map((item) => item?.['id' as keyof T]);
};

export const formatPhoneNumber = (value: string) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const getRandomName = (names: Array<string>) =>
  names[Math.floor(Math.random() * (names.length - 1))];

export const getShipmentProductName = (record?: Shipment) => {
  if (!record?.isContainer) {
    return record?.productName || '-';
  }
  if (isSameKey('productName', record?.containerItems)) {
    return record?.containerItems?.[0]?.productName || '-';
  }
  return getProductsT('multiple_products');
};

export const getColWidth = (width: number, netItemCount?: number) =>
  (netItemCount || 0) > 0 ? width : undefined;

export const getPhoneNumber = (number?: string) => {
  if (number) {
    const prefix = number.split(',');
    const obj = phoneCodes.find((item) => item.iso === prefix[0]);
    if (obj) {
      return { ...obj, number: `+${obj?.code} ${prefix[1]}`, tenDigits: prefix[1] };
    }
  }
  return {
    country: 'United States',
    code: '1',
    iso: 'US',
    number: '',
    tenDigits: '',
  };
};

/* check requested documents for an event */
export const checkRequestedDocuments = ({ template, documents }: CheckRequestedDocumentsProps) => {
  if (template && documents) {
    /* get requested documents from template */
    const requestedDocuments = template?.templateDocuments?.filter((el) => el?.required || false);
    /* now check if the requested documents are in the documents array through documentType */
    requestedDocuments?.forEach((el) => {
      const found = documents?.find(
        (doc) =>
          (el?.documentType === 'PurchaseOrder' && doc?.documentType === 'Purchase Order #') ||
          doc?.documentType === el?.documentType ||
          doc?.documentTypeName === el?.documentType,
      );
      if (!found) {
        throw new Error(
          `Document "${el?.documentName || ''}" is required for event template "${
            template?.templateName || ''
          }"`,
        );
      }
    });
  }
};

export const containsReceiveNode = (nodes?: Array<MarkerItemProps<MapNode>>) =>
  nodes?.some((node) => {
    const events = node?.extra?.events || [];
    const receiveEvent = events.find((event) => event?.eventType === 'Receive');
    return !!receiveEvent;
  }) || false;

export const containsReceiveEventNode = (nodes?: Array<MapNode>) =>
  nodes?.some((node) => {
    const events = node?.events || [];
    const receiveEvent = events.find((event) => event?.eventType === 'Receive');
    return !!receiveEvent;
  }) || false;
export const containsObserveEventNode = (nodes?: Array<MapNode>) =>
  nodes?.some((node) => {
    const events = node?.events || [];
    const receiveEvent = events.find((event) => event?.eventType === 'Observation');
    return !!receiveEvent;
  }) || false;

export const getActiveMarker = (markers?: Array<MarkerItemProps<MapNode>>, eventId?: string) => {
  const marker = markers?.find((item) =>
    item?.extra?.events?.some((event) => event?.id === eventId),
  );
  return marker;
};

/*   convert product inventory item to shipment item */
export const getShipmentItemFromProductInventory = (
  item?: ProductInventoryItem,
  product?: ProductResponse,
  shipmentProps?: Shipment,
): Shipment => {
  const shipmentItem: Shipment = {
    id: item?.id,
    name: item?.name,
    productId: product?.id,
    productName: product?.name || '',
    instanceId: item?.instanceId,
    containerId: item?.containerId,
    primaryId: item?.lotSerial || item?.containerIdentifier || '',
    quantity: item?.quantity,
    unitOfMeasure: item?.unitOfMeasure,
    senderName: item?.tradePartnerName,
    loggedDate: item?.created,
    isContainer: item?.isContainer,
    containerItems: item?.containerItems?.map((el) => ({
      ...el,
      primaryId: el?.lotSerial || '',
      productName: product?.name || '',
      productId: product?.id,
      unitDescriptor: product?.unitDescriptor,
      unitQuantity: product?.unitQuantity,
      unitOfMeasure: product?.simpleUnitOfMeasurement,
      instanceUrn: el?.urn,
    })),
    containerIdentifier: item?.containerIdentifier,
    destinationId: item?.locationId,
    originId: item?.locationId,
    shippedFrom: item?.tradePartnerName,
    purchaseOrderNumber: item?.purchaseOrder,
    shipmentEventId: item?.eventId,
    /* shipment type may be Internal(default) or NonWholechain */
    shipmentType: 'Internal',
    /*  may or may not be present */
    status: item?.shipped ? 'Shipped' : 'Pending',

    instanceUrn: item?.urn,
    ...shipmentProps,
  };
  return shipmentItem;
};

export const isDevelopment = () =>
  window.location.hostname === 'happy-desert-0d2622110-develop.centralus.1.azurestaticapps.net' ||
  window.location.hostname === 'localhost';

export const camelizeFirstChar = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const checkIfCodeEnteredInPhoneNumber = (phoneNumber: string) => {
  let updatedPhoneNumber: string = phoneNumber;
  if (updatedPhoneNumber?.includes('+')) {
    updatedPhoneNumber = updatedPhoneNumber?.replace(`${updatedPhoneNumber?.split(' ')[0]} `, '');
  }
  return updatedPhoneNumber;
};

export const featureFlag: FeatureFlagProps = {
  // feature flag as per #7343
  planCheck: false,
  // feature flag to disable #6888
  hideTabs: false,
  newShipment: true,
  // feature flag to disable validate hash
  validateHash: false,
};

export const getShipmentType = (type?: string) => {
  if (type === 'internal') {
    return 'Internal';
  }
  return 'Wholechain';
};

export const checkAllOrNoneContainers = (items: GTableDateType[]) => {
  if (items?.length) {
    // Check if any item has a container
    const hasAnyContainer = items.some((item) => item.isContainer);

    // Check if all items have containers
    const allHaveContainer = items.every((item) => item.isContainer);

    // If either all have containers or none do, return true
    return hasAnyContainer === allHaveContainer;
  }
  return true;
};
