import { ConfigProvider } from 'antd';
import useTableRowSelection from 'hooks/useTableRowSelection';
import { FC, createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetIntegrations,
  useGetTriggerSpecifications,
  useListPluginConfigurations,
} from 'services/api/useIntegrations';
import { FormContextValue, FormProviderProps } from './typings';

export const setIntegrationTabSession = (key: string) => {
  sessionStorage.setItem('configTab', key);
};

export const getIntegrationTabSession = () => sessionStorage.getItem('configTab');

export const removeIntegrationTabSession = () => {
  sessionStorage.removeItem('configTab');
};

export const setIntegrationStepSession = (key: string) => {
  sessionStorage.setItem('configStep', key);
};

export const getIntegrationStepSession = () => sessionStorage.getItem('configStep');

export const removeIntegrationStepSession = () => {
  sessionStorage.removeItem('configStep');
};

const FormContext = createContext<FormContextValue>({
  productSelectedRowsKeys: [],
  productOnRowSelectionChange: () => {},
  productSelectedRows: [],
  customerSelectedRowsKeys: [],
  customerOnRowSelectionChange: () => {},
  customerSelectedRows: [],
  companyLocationSelectedRowsKeys: [],
  companyLocationOnRowSelectionChange: () => {},
  companyLocationSelectedRows: [],
  showErrorMessage: false,
  setShowErrorMessage: () => {},
  current: 0,
  setCurrent: () => {},
  productCriteriaId: '',
  customerCriteriaId: '',
  companyLocationCriteriaId: '',
  configObject: {},
  pluginObject: {},
  integrationId: '',
  productConfigCriteriaId: '',
  customerConfigCriteriaId: '',
  companyLocationConfigCriteriaId: '',
});
const FormProvider: FC<FormProviderProps> = ({ children }) => {
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [current, setCurrent] = useState(
    getIntegrationStepSession() ? Number(getIntegrationStepSession()) : 0,
  );
  const { data: triggerSpecifications } = useGetTriggerSpecifications();
  const { externalSystem = '' } = useParams();
  const { data: pluginList } = useListPluginConfigurations();
  const { data: configList } = useGetIntegrations();

  const pluginObject = useMemo(
    () =>
      pluginList?.pluginAccountConfiguration?.find(
        (item) => item?.externalSystem === externalSystem,
        [],
      ),
    [externalSystem, pluginList?.pluginAccountConfiguration],
  );

  const configObject = useMemo(
    () => configList?.results?.find((item) => item?.externalSystem === externalSystem, []),
    [configList?.results, externalSystem],
  );

  const integrationId = useMemo(() => configObject?.id, [configObject?.id]);
  const customerCriteriaId =
    triggerSpecifications?.values?.find(
      (item) => item?.triggerIdentity === 'DestinationTradePartner',
      [],
    )?.triggerSpecificationId || '';

  const productCriteriaId =
    triggerSpecifications?.values?.find((item) => item?.triggerIdentity === 'Product', [])
      ?.triggerSpecificationId || '';

  const companyLocationCriteriaId =
    triggerSpecifications?.values?.find((item) => item?.triggerIdentity === 'OriginLocation', [])
      ?.triggerSpecificationId || '';

  const productConfigCriteriaId = useMemo(
    () =>
      configObject?.triggerCriteria?.find(
        (item) => item?.triggerSpecificationId === productCriteriaId,
        [],
      )?.id || '',
    [configObject?.triggerCriteria, productCriteriaId],
  );

  const customerConfigCriteriaId = useMemo(
    () =>
      configObject?.triggerCriteria?.find(
        (item) => item?.triggerSpecificationId === customerCriteriaId,
        [],
      )?.id || '',
    [configObject?.triggerCriteria, customerCriteriaId],
  );

  const companyLocationConfigCriteriaId = useMemo(
    () =>
      configObject?.triggerCriteria?.find(
        (item) => item?.triggerSpecificationId === companyLocationCriteriaId,
        [],
      )?.id || '',
    [configObject?.triggerCriteria, companyLocationCriteriaId],
  );

  const {
    selectedRowKeys: productSelectedRowsKeys,
    onRowSelectionChange: productOnRowSelectionChange,
    selectedRows: productSelectedRows,
  } = useTableRowSelection();

  const {
    selectedRowKeys: customerSelectedRowsKeys,
    onRowSelectionChange: customerOnRowSelectionChange,
    selectedRows: customerSelectedRows,
  } = useTableRowSelection();

  const {
    selectedRowKeys: companyLocationSelectedRowsKeys,
    onRowSelectionChange: companyLocationOnRowSelectionChange,
    selectedRows: companyLocationSelectedRows,
  } = useTableRowSelection();

  const contextValue = useMemo(
    () => ({
      productSelectedRowsKeys,
      productOnRowSelectionChange,
      productSelectedRows,
      customerSelectedRowsKeys,
      customerOnRowSelectionChange,
      customerSelectedRows,
      companyLocationSelectedRowsKeys,
      companyLocationOnRowSelectionChange,
      companyLocationSelectedRows,
      showErrorMessage,
      setShowErrorMessage,
      current,
      setCurrent,
      customerCriteriaId,
      productCriteriaId,
      companyLocationCriteriaId,
      configObject,
      pluginObject,
      integrationId,
      productConfigCriteriaId,
      customerConfigCriteriaId,
      companyLocationConfigCriteriaId,
    }),
    [
      productSelectedRowsKeys,
      productOnRowSelectionChange,
      productSelectedRows,
      customerSelectedRowsKeys,
      customerOnRowSelectionChange,
      customerSelectedRows,
      companyLocationSelectedRowsKeys,
      companyLocationOnRowSelectionChange,
      companyLocationSelectedRows,
      showErrorMessage,
      current,
      customerCriteriaId,
      productCriteriaId,
      companyLocationCriteriaId,
      configObject,
      pluginObject,
      integrationId,
      productConfigCriteriaId,
      customerConfigCriteriaId,
      companyLocationConfigCriteriaId,
    ],
  );
  return (
    <FormContext.Provider value={contextValue}>
      <ConfigProvider>{children}</ConfigProvider>
    </FormContext.Provider>
  );
};

export const useFormContext = (): FormContextValue => {
  const context = useContext(FormContext);

  if (context === undefined) {
    throw new Error('FormContext must be used within a FormContextProvider');
  }

  return context;
};
export default FormProvider;
