import ProCard from '@ant-design/pro-card';
import { Steps } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectCustomers from '../components/SelectCustomers';
import SelectProducts from '../components/SelectProducts';
import { setIntegrationStepSession, useFormContext } from '../FormProvider';
import styles from '../index.module.less';
import { EnableFormProps } from '../typings';
import CompanyAndLocations from './CompanyAndLocations';
import Review from './Review';
import ServerCredential from './ServerCredential';

const { Step } = Steps;

const EnableForm: React.FC<EnableFormProps> = ({ form }) => {
  const { current, setCurrent } = useFormContext();
  const { t } = useTranslation('pages', { keyPrefix: 'settings.comp_integration.form' });
  // List on steps
  const steps = useMemo(
    () => [
      {
        key: 'server_credentials',
      },
      {
        key: 'company_locations',
      },
      {
        key: 'products',
      },
      {
        key: 'customers',
      },
      {
        key: 'review',
      },
    ],
    [],
  );

  // Manages Components
  const onSwitchComponent = useMemo(() => {
    document.getElementById('rightcontainer')?.scrollTo(0, 0);
    switch (current) {
      case 0:
        return <ServerCredential form={form} />;
      case 1:
        return <CompanyAndLocations />;
      case 2:
        return <SelectProducts />;
      case 3:
        return <SelectCustomers />;
      case 4:
        return <Review form={form} />;
      default:
        return <Review form={form} />;
    }
  }, [current, form]);

  return (
    <ProCard split="vertical">
      <ProCard colSpan="250px">
        <Steps current={current} direction="vertical">
          {steps?.map(
            (item) => (
              <Step
                key={item?.key}
                title={t?.(item?.key)}
                onStepClick={(index) => {
                  if (index < (current || 0)) {
                    setCurrent?.(index);
                    setIntegrationStepSession(String(index));
                  }
                }}
              />
            ),
            [],
          )}
        </Steps>
      </ProCard>
      <ProCard className={styles.container} id="rightcontainer">
        {onSwitchComponent}
      </ProCard>
    </ProCard>
  );
};

export default React.memo(EnableForm);
