import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import React, { useCallback, useMemo, useState } from 'react';
import { useProducts } from 'services/api';
import { GetPaginatedProductsOptionalParams, ProductResponse } from 'services/api/client/src';
import { useDebouncedCallback } from 'use-debounce';
import GSelect from './GSelect';
import { FetchDataFn, SubProductSelectProps } from './typings';
/**
 * Documentation https://docs.google.com/document/d/1pOcY05bFgBeKHK60G50odx4j_u9Aa0qpzsF1tAsdFVM/edit
 * Same product type and same unit of measure
 * A parent product cannot be a child product if it already has child products assigned to it
 * @param props
 * @returns
 */
const SubProductSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  renderLabel,
  renderValue,
  disabledItem,
  filterItem,
  isSerial,
  product: parentProduct,
  ...props
}: SubProductSelectProps<ValueType, OptionType>) => {
  const [params, setParams] = useState<GetPaginatedProductsOptionalParams>();
  const { data, isLoading } = useProducts(params);

  const filterOption = useCallback(
    (option?: DefaultOptionType) => {
      if (!filterItem) {
        return true;
      }
      return filterItem?.(option?.itemProps as OptionType['itemProps']);
    },
    [filterItem],
  );

  const filterSubProducts = useCallback(
    (product: ProductResponse) => {
      const productId = parentProduct?.id;

      if ((product?.subProducts?.length || 0) > 0) {
        return false;
      }
      /* filter current product */
      /* same uom means unitOfMeasure, unitDescriptor, unitQuantity  is matching */
      const parentHasDefinedUnits = !!parentProduct?.unitDescriptor || false;
      const hasDefinedUnits = !!product?.unitDescriptor || false;
      let sameUom = false;
      if (parentHasDefinedUnits) {
        const bothHaveDefinedUnits = (hasDefinedUnits && parentHasDefinedUnits) || false;
        sameUom =
          bothHaveDefinedUnits &&
          product?.simpleUnitOfMeasurement === parentProduct?.simpleUnitOfMeasurement &&
          product?.unitDescriptor === parentProduct?.unitDescriptor &&
          product?.unitQuantity === parentProduct?.unitQuantity;
      } else {
        const noDefinedUnits = !hasDefinedUnits && !parentHasDefinedUnits;
        sameUom =
          noDefinedUnits &&
          product?.simpleUnitOfMeasurement === parentProduct?.simpleUnitOfMeasurement;
      }

      if (isSerial) {
        /* serial will have an exception as serial has no uom */
        return product?.id !== productId && product?.productIdentifierType === 'Serial';
      }
      return product?.id !== productId && product?.productIdentifierType !== 'Serial' && sameUom;
    },
    [isSerial, parentProduct],
  );

  // we create here the list with the shape the select is expecting for
  const productOptions: Array<DefaultOptionType> = useMemo(
    () =>
      data?.results
        ?.filter(filterSubProducts)
        ?.map((product) => ({
          label: renderLabel ? renderLabel(product) : String(product.name),
          value: renderValue ? renderValue(product) : String(product.id),
          disabled: disabledItem?.(product) || false,
          itemProps: product,
        }))
        ?.filter(filterOption) || [],
    [data, renderLabel, renderValue, disabledItem, filterOption, filterSubProducts],
  );

  const debounced = useDebouncedCallback(
    // function
    (filterText) => {
      setParams((oldParams) => ({ ...oldParams, name: filterText }));
    },
    // delay in ms
    1000,
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText, initialValue }) => {
      if (initialValue) {
        setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
      } else {
        debounced(filterText);
      }
    },
    [debounced],
  );
  const handleBlur = () => {
    setParams((oldParams) => ({ ...oldParams, name: '' }));
  };

  return (
    <GSelect<ValueType, OptionType>
      placeholder="Select Product"
      {...props}
      loading={isLoading}
      options={productOptions as OptionType[]}
      fetchData={fetchData}
      allowFilterOption={false}
      onBlur={handleBlur}
    />
  );
};

export default React.memo(SubProductSelect);
