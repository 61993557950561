import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetPaginatedProductsOptionalParams } from 'services/api/client/src';
import useProducts from 'services/api/useProducts';
import { dateLocalFormat } from 'utils';
import { ProductItem } from './typings';

/**
 * Custom hook to handle product filters and fetching.
 * @param initParams Initial parameters for fetching products.
 */
const useProductFilters = (initParams?: GetPaginatedProductsOptionalParams) => {
  const [params, setParams] = useState<GetPaginatedProductsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    ...initParams,
  });
  const { data: productRes, isLoading, refetch, isFetching } = useProducts(params);
  const netItemCount = useMemo(() => productRes?.grossCount || 0, [productRes]);
  const { t } = useTranslation('pages', { keyPrefix: 'products' });

  const totalItems = useMemo(() => productRes?.totalItems || 0, [productRes]);
  const records = useMemo<Array<ProductItem>>(
    () =>
      productRes?.results?.map((el) => ({
        id: String(el?.id || '0'),
        name: el?.name || '',
        currentInventory: el?.currentInventory || 0,
        shippedInventory: el?.shippedInventory || 0,
        pendingShipmentItems: el?.pendingShipmentItems || 0,
        recentActivity: dateLocalFormat(new Date(el?.lastModifiedTime || '')),
        created: dateLocalFormat(new Date(el?.createdTime || '')),
        sharingPolicy: el?.sharingPolicy || 'Open',
        blockchain: el.blockchain,
        accountName: el.accountName,
        accountId: el.accountId,
        gtin: el?.gtin,
      })) || [],
    [productRes],
  );
  const onSearch = (text: string) => {
    setParams({
      ...params,
      pageNumber: 1,
      name: text,
    });
  };
  const { filters: nameSearchfilter } = useTableSearchFilter({
    title: t?.('col_product_name'),
    onSearch,
  });
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
  ) => {
    const apiFilters: GetPaginatedProductsOptionalParams = {
      sharingPolicies: (filters?.sharingPolicy as Array<string>) || undefined,
    };
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        ...apiFilters,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        ...apiFilters,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };
  return {
    records,
    totalItems,
    netItemCount,
    isLoading,
    onTableChange,
    params,
    setParams,
    filters: [nameSearchfilter],
    refetch,
    isFetching,
  };
};
export default useProductFilters;
