import { InfoCircleOutlined } from '@ant-design/icons';
import ProForm from '@ant-design/pro-form';
import { Col, ColProps, Input, Row, RowProps, Typography } from 'antd';
import LocationSelect from 'components/GSelect/LocationSelect';
import React, { FC } from 'react';
import { getEventsT } from 'utils';
import styles from './index.module.less';
import { HeaderProps } from './typings';

const rowProps: RowProps = { gutter: [32, 32] };
const col1Props: ColProps = { xs: 24, sm: 24, md: 5, lg: 3 };
const col2Props: ColProps = { xs: 24, sm: 24, md: 18, lg: 21 };
const col21Props: ColProps = { xs: 24, sm: 24, md: 6, lg: 6 };

const Header: FC<HeaderProps> = ({
  t,
  title,
  form,
  onInfoClick,
  locationId,
  // locationName,
  locationDisabled,
  onChangeLocation,
  isManual,
}) => (
  <Row {...rowProps}>
    <Col {...col1Props}>
      <Typography.Title level={4} className={styles.header}>
        {getEventsT(title)}&nbsp;
        <small>
          <InfoCircleOutlined onClick={onInfoClick} width={12} />
        </small>
      </Typography.Title>
    </Col>
    <Col {...col2Props}>
      <ProForm form={form} layout="inline" colProps={col21Props} submitter={{ render: () => null }}>
        <ProForm.Item
          name="location"
          className={styles.headerinput1}
          rules={[{ required: true, message: t?.('location_req') }]}
          initialValue={locationId}
        >
          <LocationSelect
            className={styles.location}
            disabled={locationDisabled}
            searchValue={!(!isManual && title === 'Observe') ? undefined : t?.('locations_csv')}
            initParams={{
              tradePartnerStatus: ['Self', 'NonWholechain'],
            }}
            placeholder={t?.('location_placeholder')}
            onChange={(val) => onChangeLocation?.(form, val)}
          />
        </ProForm.Item>
        <ProForm.Item
          name="poNumber"
          className={styles.headerinput}
          hidden={!isManual && title !== 'Transform'}
        >
          <Input placeholder={t?.('po_title')} />
        </ProForm.Item>
      </ProForm>
    </Col>
  </Row>
);

export default React.memo(Header);
