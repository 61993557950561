import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Col, ColProps, Space, Typography, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getAddress, getGeocodeFromString } from 'services/api/usePlaces';
import { csvtoJSON, downloadCSVTemplate, titletoKeys } from 'utils';
import { phoneCodes } from 'utils/timezones';
import { v4 as uuidv4 } from 'uuid';
import { DataItem } from '../CompanyLocation.fields';
import useDataEntryMethodStore from '../DataEntryMethod/useDataEntryStore';
import CSVTable from './CSVTable';
import styles from './index.module.less';
import { CustomDataTemplateProps, locationTemplate } from './typings';

const uploadColProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 12 };

const { Text } = Typography;
const { Dragger } = Upload;

const CustomDataTemplate: FC<CustomDataTemplateProps> = ({ t }) => (
  <div>
    <Text className="ant-pro-form-group-title">{t?.('custom_data_template')}</Text>

    <div className={styles.content}>
      <Text type="secondary">{t?.('no_data_template')}</Text>
    </div>
  </div>
);

export const getPhoneNumberForCSV = (code?: string, number?: string) => {
  const obj = phoneCodes.find((item) => item.code === String(code));
  const tenDigits = new AsYouType((obj?.iso || 'US') as CountryCode).input(String(number));
  if (number && code) {
    if (obj) {
      return {
        ...obj,
        number: `+${obj?.code} ${tenDigits}`,
        tenDigits,
        phoneNumber: `${obj?.iso},${number}`,
      };
    }
  }
  if (number) {
    return {
      country: 'United States',
      code: '1',
      iso: 'US',
      number: `+1${tenDigits}`,
      tenDigits,
      phoneNumber: `US,${number}`,
    };
  }
  return {
    country: '',
    code: '',
    iso: '',
    number: ``,
    tenDigits,
    phoneNumber: ``,
  };
};

export const getGeoDataFromCSV = async (rawAddress?: string, rawCoordinates?: string) => {
  try {
    const geoCode = await getGeocodeFromString(rawAddress || '');
    const addressObj = getAddress(geoCode?.[0]?.address_components || []);
    const coordinates = rawCoordinates
      ? {
          geoCoordinates: rawCoordinates,
        }
      : {
          geoCoordinates: geoCode?.[0]?.geometry?.location
            ? `${geoCode?.[0]?.geometry?.location?.lat()},${geoCode?.[0]?.geometry?.location?.lng()}`
            : undefined,
        };
    return {
      address: {
        ...addressObj,
        line2: rawAddress,
      },
      ...coordinates,
    };
  } catch (error) {
    return {
      address: {
        line2: rawAddress,
      },
      geoCoordinates: rawCoordinates,
    };
  }
};

export const CSVUpload: FC<CustomDataTemplateProps> = ({ form, editableTableFormRef }) => {
  const { getFieldValue, setFieldsValue } = form;
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_network.form_fields' });
  const csvData: Array<DataItem> = getFieldValue('csvData');
  const vesselVal: string = getFieldValue('isVessel');
  const isVessel = vesselVal === 'Yes';

  const setCsvData = useCallback(
    (value: Array<DataItem>) => {
      setFieldsValue({ csvData: value });
    },
    [setFieldsValue],
  );

  const onDownloadCSVTemplate = () => {
    downloadCSVTemplate(locationTemplate({ t, isVessel }), t('csv_file_name'));
  };
  const onCSVUpload: UploadProps['onChange'] = async (info) => {
    const { file } = info;
    file.status = 'done';
    if (!file.url && !file.preview && file.originFileObj) {
      const data: any = await csvtoJSON(file.originFileObj);
      const newData: Array<DataItem> =
        data?.map((item: any) => ({
          id: item?.id || uuidv4(),
          ...titletoKeys(locationTemplate({ t, isVessel }), item),
        })) || [];
      const insertAddress: Array<DataItem> = await Promise.all(
        newData?.map(async (item: any) => {
          const numberObj = getPhoneNumberForCSV(item?.phoneCode, item?.phoneNumber);
          const geoData = await getGeoDataFromCSV(item?.address, item?.geoCoordinates);
          return {
            ...item,
            ...geoData,
            connectionType: item?.connectionType?.toUpperCase() || '',
            number: numberObj?.tenDigits,
            phoneCode: numberObj?.iso || '',
            phonePrefix: numberObj?.code,
            phoneNumber: numberObj?.phoneNumber || '',
          };
        }) || [],
      );
      setCsvData(insertAddress);
    }
  };
  const uploadProps: UploadProps = {
    name: 'csvfile',
    multiple: false,
    maxCount: 1,
    accept: 'text/csv',
    onChange: onCSVUpload,
  };
  const renderTable = () => <CSVTable form={form} editableTableFormRef={editableTableFormRef} />;

  return (
    <>
      <Space size="large" direction="vertical" className={styles.csvbtn}>
        <Text className="ant-pro-form-group-title">{t('csv_upload')}</Text>
        {!csvData?.length && (
          <Button shape="round" type="primary" onClick={onDownloadCSVTemplate}>
            {t('download_csv_template')}
          </Button>
        )}
      </Space>

      {csvData?.length ? (
        <Col>
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            shape="round"
            ghost
            onClick={() => setCsvData([])}
            className={styles.removebtn}
          >
            {t('remove_file')}
          </Button>
          {renderTable()}
        </Col>
      ) : (
        <Col {...uploadColProps}>
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('upload_desc')}</p>
          </Dragger>
        </Col>
      )}
    </>
  );
};

const DataTemplate: FC<CustomDataTemplateProps> = ({ form, editableTableFormRef }) => {
  const dataEntryMethod = useDataEntryMethodStore((state) => state.dataEntryMethod);

  if (dataEntryMethod === 'manual') {
    return <CustomDataTemplate form={form} />;
  }

  return <CSVUpload form={form} editableTableFormRef={editableTableFormRef} />;
};

export default React.memo(DataTemplate);
