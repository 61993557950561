import { LoadingOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import {
  Button,
  Col,
  ColProps,
  Descriptions,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import ActionButtons from 'components/ActionButtons';
import GTable from 'components/GTable';
import { FC, Key, useCallback, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { LocationResponse } from 'services/api/client/src';
import {
  useCreateTriggerCriteriaValue,
  useDeleteTriggerCriteriaValue,
} from 'services/api/useIntegrations';
import useListLocation from '../../../../hooks/useListLocation';
import useTradePartners from '../../../../services/api/useTradePartners';
import { errorHandler, getT } from '../../../../utils';
import LocationSelect from '../../../GSelect/LocationSelect';
import { GTableDateType } from '../../../GTable/contexts/typings';
import DUNSDescriptionTable from '../components/DunsEdit';
import FormTitleBar from '../components/FormTitleBar';
import { setIntegrationTabSession, useFormContext } from '../FormProvider';
import styles from '../index.module.less';
import { SelectTableProps } from '../typings';

const { Text } = Typography;
const colProps24: ColProps = { xs: 24, sm: 22, md: 18, lg: 12, xl: 12 };
type ColumnsType = {
  t?: TFunction<'pages', 'settings.integration'>;
  selectedRows?: Key[];
  isConfig?: boolean;
  onEditRow?: (actionPayload?: LocationResponse) => void;
  onDelRow?: (actionPayload?: LocationResponse) => void;
  allLocations?: LocationResponse[];
};

const columns = ({
  t,
  selectedRows,
  isConfig,
  onEditRow,
  onDelRow,
  allLocations,
}: ColumnsType): Array<ProColumns<LocationResponse>> => [
  {
    title: t?.('col_company_location_name'),
    dataIndex: 'name',
    key: 'name',
    hideInSetting: true,
    ellipsis: true,
    render: (_, row) => (
      <Text>{allLocations?.find((item) => item?.id === row?.id, [])?.name || ''}</Text>
    ),
    renderFormItem: (_, { recordKey }, form) => (
      <LocationSelect
        size="small"
        initParams={{
          tradePartnerStatus: ['Self', 'NonWholechain'],
        }}
        disabledItem={(item) => selectedRows?.includes(item?.id || '')}
        spaceClassName={styles.tagloccontainer}
        onChange={(value, option) => {
          const { setFieldsValue } = form;
          // @ts-ignore
          const item: LocationResponse = option.itemProps;
          setFieldsValue({
            [String(recordKey)]: {
              ...item,
              id: item?.id || value,
              gln: item?.gln,
              name: item?.name,
            },
          });
        }}
      />
    ),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('loc_req'),
        },
      ],
    },
  },
  {
    title: t?.('col_company_location_gln'),
    dataIndex: 'gln',
    key: 'gln',
    ellipsis: true,
    fieldProps: {
      size: 'small',
      disabled: true,
    },
    render: (_, row) => {
      const location = allLocations?.find((item) => item?.id === row?.id);
      return location?.gln ? location?.gln : <Tag color="warning">{t?.('tag_missing_gln')}</Tag>;
    },
  },
  {
    title: '',
    dataIndex: 'actions',
    fixed: 'right',
    hideInTable: !isConfig,
    width: 100,
    valueType: 'option',
    render: (_, record) => (
      <ActionButtons record={record} onDelete={onDelRow} onEdit={onEditRow} showEdit showDelete />
    ),
  },
];

const CompanyAndLocations: FC<SelectTableProps> = ({ isReview, isConfig }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.comp_integration' });
  const actionRef = useRef<ActionType>();
  const {
    companyLocationSelectedRowsKeys,
    companyLocationOnRowSelectionChange,
    companyLocationSelectedRows,
    showErrorMessage,
    companyLocationCriteriaId,
    configObject,
    integrationId,
    companyLocationConfigCriteriaId,
  } = useFormContext();
  const [loadingItems, setLoadingItems] = useState<Key[]>([]);

  const queryClient = useQueryClient();
  const addItem = useCreateTriggerCriteriaValue(queryClient);
  const removeItem = useDeleteTriggerCriteriaValue(queryClient);
  const navigate = useNavigate();

  const { data: records, isLoading } = useListLocation({
    tradePartnerStatus: ['Self', 'NonWholechain'],
  });
  const { data: ownTradepartner } = useTradePartners({
    types: ['Self'],
  });

  const onSelectChange = useCallback(
    async (selectedKeys: Key[], selectedRows: GTableDateType[]) => {
      const backupKeys = [...companyLocationSelectedRowsKeys];
      const backupRows = [...companyLocationSelectedRows];
      try {
        companyLocationOnRowSelectionChange?.(selectedKeys, selectedRows);
        const removedItems = companyLocationSelectedRowsKeys?.filter(
          (item1) => !selectedKeys.some((item2) => item1 === item2, []),
          [],
        );
        const addedItems = selectedKeys.filter(
          (item2) => !companyLocationSelectedRowsKeys?.some((item1) => item1 === item2, []),
          [],
        );
        if (addedItems?.length) {
          setLoadingItems(addedItems);
          const apiCalls = addedItems?.map(async (i) => {
            await addItem?.mutateAsync({
              integrationId: integrationId || '',
              criterionId: companyLocationConfigCriteriaId || '',
              options: {
                body: {
                  triggerValueId: String(i),
                },
              },
            });
          }, []);
          await Promise.all(apiCalls);
        }
        if (removedItems?.length) {
          setLoadingItems(removedItems);
          const apiCalls = removedItems?.map(async (i) => {
            await removeItem?.mutateAsync({
              integrationId: integrationId || '',
              criterionId: companyLocationConfigCriteriaId || '',
              criterionTriggerValueId: String(i),
            });
          }, []);
          await Promise.all(apiCalls);
        }
      } catch (error) {
        companyLocationOnRowSelectionChange?.(backupKeys, backupRows);
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      companyLocationSelectedRowsKeys,
      companyLocationSelectedRows,
      companyLocationOnRowSelectionChange,
      addItem,
      integrationId,
      companyLocationConfigCriteriaId,
      removeItem,
    ],
  );

  const onDelete = useCallback(
    async (actionPayload?: LocationResponse) => {
      try {
        const updatedkeys =
          companyLocationSelectedRowsKeys?.filter((item) => item !== actionPayload?.id, []) || [];
        const updatedRows =
          companyLocationSelectedRows?.filter((item) => item?.id !== actionPayload?.id, []) || [];

        await removeItem?.mutateAsync({
          integrationId: integrationId || '',
          criterionId: companyLocationConfigCriteriaId || '',
          criterionTriggerValueId: actionPayload?.id || '',
        });
        message.success(t('location_delete_success'));
        companyLocationOnRowSelectionChange?.(updatedkeys, updatedRows);
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      companyLocationSelectedRowsKeys,
      companyLocationSelectedRows,
      removeItem,
      integrationId,
      companyLocationConfigCriteriaId,
      t,
      companyLocationOnRowSelectionChange,
    ],
  );

  const onDelRow = useCallback(
    (actionPayload?: LocationResponse) => {
      Modal.warning({
        title: t?.('delete_warn_title', { name: actionPayload?.name }),
        content: t?.('delete_warn_content'),
        okText: t?.('delete_warn_remove'),
        cancelText: t?.('delete_warn_cancel'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
        onOk: () => onDelete(actionPayload),
      });
    },
    [onDelete, t],
  );

  const onEditRow = useCallback(
    (actionPayload?: LocationResponse) => {
      setIntegrationTabSession('1');
      const tradePartnerId = records?.find(
        (item) => item?.id === actionPayload?.id,
        [],
      )?.tradePartnerId;
      navigate(`../../network/${tradePartnerId}/editLocation`);
    },
    [navigate, records],
  );

  const onSaveRow = useCallback(
    async (_: any, data: LocationResponse) => {
      try {
        await addItem?.mutateAsync({
          integrationId: integrationId || '',
          criterionId: companyLocationConfigCriteriaId || '',
          options: {
            body: {
              triggerValueId: data?.id,
            },
          },
        });
        companyLocationOnRowSelectionChange?.(
          [...(companyLocationSelectedRowsKeys || []), data?.id || ''],
          [...(companyLocationSelectedRows || []), data],
        );
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      addItem,
      companyLocationConfigCriteriaId,
      companyLocationOnRowSelectionChange,
      companyLocationSelectedRows,
      companyLocationSelectedRowsKeys,
      integrationId,
    ],
  );

  const onCancelRow = async (_: any, data: LocationResponse) => {
    actionRef.current?.cancelEditable(data?.id || 0);
  };

  useEffect(() => {
    if (configObject) {
      const defaultIds = configObject?.triggerCriteria?.find(
        (item) => item?.triggerSpecificationId === companyLocationCriteriaId,
        [],
      )?.values;
      if (defaultIds?.length) {
        const selectedRowsKeys = defaultIds?.map((item) => item?.entityId || '', []);
        const selectedRow = defaultIds?.map(
          (item) => ({
            id: item?.entityId,
            name: item?.name,
            gln: item?.description,
          }),
          [],
        );
        companyLocationOnRowSelectionChange?.(selectedRowsKeys, selectedRow);
      }
    }
  }, [companyLocationCriteriaId, companyLocationOnRowSelectionChange, configObject]);

  return (
    <Space direction="vertical" size="middle">
      {!isReview && !isConfig && (
        <Space direction="vertical" size="middle">
          <FormTitleBar
            hideCount
            tkey="settings.comp_integration.company_locations"
            {...(showErrorMessage && { errorMessage: t?.('atleast_select') })}
          />
        </Space>
      )}
      {isConfig && (
        <Row justify="space-between" className={styles.margintop20}>
          <Col className={styles.marginBottom15}>
            <Typography.Title level={5}>
              {getT('settings.comp_integration.review.table_title')}
            </Typography.Title>
            <Typography.Text>{t?.('customer_table_desc')}</Typography.Text>
          </Col>
        </Row>
      )}
      <Row justify="space-between" align="middle">
        <Col {...colProps24} className={styles.marginBottom15}>
          <Descriptions title={isReview || isConfig ? '' : t('company_locations.company')} />
          <DUNSDescriptionTable
            dunsNumber={ownTradepartner?.data?.[0]?.duns ?? ''}
            isConfig={isConfig}
            tradePartnerId={ownTradepartner?.data?.[0]?.id ?? ''}
          />
        </Col>
        {isConfig && (
          <Col>
            <Button
              type="primary"
              size="middle"
              shape="round"
              onClick={() => {
                actionRef.current?.addEditRecord?.({
                  id: Date.now().toString(),
                });
              }}
            >
              {t?.('add_company')}
            </Button>
          </Col>
        )}
      </Row>
      {!(isReview || isConfig) && (
        <FormTitleBar
          count={companyLocationSelectedRowsKeys?.length}
          hideDesc
          titleSize={5}
          tkey="settings.comp_integration.company_locations.loc"
          {...(showErrorMessage && { errorMessage: t?.('atleast_select') })}
        />
      )}

      <GTable<LocationResponse>
        actionRef={actionRef}
        columns={columns({
          t,
          selectedRows: companyLocationSelectedRowsKeys,
          isConfig,
          onEditRow,
          onDelRow,
          allLocations: records,
        })}
        loading={isLoading}
        value={
          isReview || isConfig ? (companyLocationSelectedRows as LocationResponse[]) : records || []
        }
        recordCreatorProps={false}
        enableRowSelection={!(isReview || isConfig)}
        {...(!(isReview || isConfig) && {
          rowSelection: {
            selectedRowKeys: companyLocationSelectedRowsKeys,
            onChange: onSelectChange,
            preserveSelectedRowKeys: true,
            renderCell: (value, record, index, originNode) =>
              (addItem?.isLoading || removeItem?.isLoading) &&
              loadingItems?.includes(record?.id || '') ? (
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              ) : (
                originNode
              ),
          },
        })}
        rowKey="id"
        options={{
          setting: false,
          reload: false,
        }}
        editable={{
          onSave: (rowKey, actionPayload) => onSaveRow(rowKey, actionPayload),
          onCancel: async (rowKey, _, data) => onCancelRow(rowKey, data),
        }}
        actionsRenderOptions={{
          save: isConfig,
          cancel: isConfig,
        }}
        pagination={{
          defaultPageSize: 10,
          total: isReview || isConfig ? companyLocationSelectedRows?.length : records?.length,
          showTotal: (total, range) =>
            total <= 1
              ? t('company_locations.company_pagi', { range0: range[0], range1: range[1], total })
              : t('company_locations.company_pagi_multi', {
                  range0: range[0],
                  range1: range[1],
                  total,
                }),
        }}
        columnsState={{
          persistenceKey: 'select-company-location',
        }}
      />
    </Space>
  );
};

export default CompanyAndLocations;
