import { LoadingOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Button, Col, message, Modal, Row, Spin, Tag, Typography } from 'antd';
import ActionButtons from 'components/ActionButtons';
import { TradePartnerSelect } from 'components/GSelect';
import GTable from 'components/GTable';
import { GTableDateType } from 'components/GTable/contexts/typings';
import useListLocation from 'hooks/useListLocation';
import { FC, Key, useCallback, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTradePartners } from 'services/api';
import { LocationResponse, TradePartnerResponse } from 'services/api/client/src';
import {
  useCreateTriggerCriteriaValue,
  useDeleteTriggerCriteriaValue,
} from 'services/api/useIntegrations';
import { errorHandler, getT } from 'utils';
import { setIntegrationTabSession, useFormContext } from '../FormProvider';
import styles from '../index.module.less';
import { SelectTableProps } from '../typings';
import FormTitleBar from './FormTitleBar';

type ColumnsType = {
  t?: TFunction<'pages', 'settings.comp_integration.customer'>;
  selectedRows?: Key[];
  isConfig?: boolean;
  onEditRow?: (actionPayload?: TradePartnerResponse) => void;
  onDelRow?: (actionPayload?: TradePartnerResponse) => void;
  allLocations?: LocationResponse[];
};

const Columns = ({
  t,
  selectedRows,
  isConfig,
  onEditRow,
  onDelRow,
  allLocations,
}: ColumnsType): Array<ProColumns<TradePartnerResponse>> => [
  {
    title: t?.('col_company_title'),
    dataIndex: 'name',
    hideInSetting: true,
    ellipsis: true,
    renderFormItem: (_, { recordKey }, form) => (
      <TradePartnerSelect
        size="small"
        initParams={{
          types: ['Wholechain', 'NonWholechain'],
        }}
        disabledItem={(item) => selectedRows?.includes(item?.id || '')}
        onChange={(value, option) => {
          const { setFieldsValue } = form;
          // @ts-ignore
          const item: TradePartnerResponse = option.itemProps;
          setFieldsValue({
            [String(recordKey)]: {
              ...item,
              id: item?.id || value,
              duns: item?.duns,
              name: item?.name,
            },
          });
        }}
      />
    ),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('cust_req'),
        },
      ],
    },
  },
  {
    title: t?.('col_duns_title'),
    dataIndex: 'duns',
    ellipsis: true,
    fieldProps: {
      size: 'small',
      disabled: true,
    },
    render: (_, record) =>
      record?.duns ? record.duns : <Tag color="warning">{t?.('tag_missing_duns')}</Tag>,
  },
  {
    title: t?.('col_locgln_title'),
    dataIndex: 'glns',
    ellipsis: true,
    fieldProps: {
      size: 'small',
      disabled: true,
    },
    renderFormItem: () => null,
    render: (_, record) => {
      const filteredLocations = allLocations?.filter(
        (location) => location.tradePartnerId === record?.id,
      );
      const hasGLN = filteredLocations?.some((location) => location.gln);
      return hasGLN ? (
        <Tag color="success">{t?.('tag_glns')}</Tag>
      ) : (
        <Tag color="warning">{t?.('tag_missing_glns')}</Tag>
      );
    },
  },
  {
    title: '',
    dataIndex: 'actions',
    fixed: 'right',
    hideInTable: !isConfig,
    width: 100,
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons
        record={record}
        onDelete={onDelRow}
        onEdit={onEditRow}
        showEdit={record?.type === 'NonWholechain'}
        showDelete
      />
    ),
  },
];
const SelectCustomers: FC<SelectTableProps> = ({ isReview, isConfig }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.comp_integration.customer' });
  const actionRef = useRef<ActionType>();
  const {
    customerSelectedRowsKeys,
    customerOnRowSelectionChange,
    customerSelectedRows,
    showErrorMessage,
    customerCriteriaId,
    configObject,
    integrationId,
    customerConfigCriteriaId,
  } = useFormContext();
  const queryClient = useQueryClient();
  const addItem = useCreateTriggerCriteriaValue(queryClient);
  const removeItem = useDeleteTriggerCriteriaValue(queryClient);
  const navigate = useNavigate();

  const { data: records, isLoading } = useTradePartners({
    types: ['Wholechain', 'NonWholechain'],
  });
  const { data: record } = useListLocation();
  const [loadingItems, setLoadingItems] = useState<Key[]>([]);

  useEffect(() => {
    if (configObject) {
      const defaultIds = configObject?.triggerCriteria?.find(
        (item) => item?.triggerSpecificationId === customerCriteriaId,
        [],
      )?.values;
      if (defaultIds?.length) {
        const selectedRowsKeys = defaultIds?.map((item) => item?.entityId || '', []);
        const selectedRow = defaultIds?.map((item) => {
          const companyObj = records?.data?.find?.((i) => i?.id === item?.entityId, []);
          return {
            ...companyObj,
            id: item?.entityId,
            name: companyObj?.name,
            duns: companyObj?.duns,
          };
        }, []);
        customerOnRowSelectionChange?.(selectedRowsKeys, selectedRow);
      }
    }
  }, [configObject, customerCriteriaId, customerOnRowSelectionChange, records?.data]);

  const onSelectChange = useCallback(
    async (selectedKeys: Key[], selectedRows: GTableDateType[]) => {
      try {
        const removedItems = customerSelectedRowsKeys?.filter(
          (item1) => !selectedKeys.some((item2) => item1 === item2, []),
          [],
        );
        const addedItems = selectedKeys.filter(
          (item2) => !customerSelectedRowsKeys?.some((item1) => item1 === item2, []),
          [],
        );
        if (addedItems?.length) {
          setLoadingItems(addedItems);
          const apiCalls = addedItems?.map(async (i) => {
            await addItem?.mutateAsync({
              integrationId: integrationId || '',
              criterionId: customerConfigCriteriaId || '',
              options: {
                body: {
                  triggerValueId: String(i),
                },
              },
            });
          }, []);
          Promise.all(apiCalls);
        }
        if (removedItems?.length) {
          setLoadingItems(removedItems);
          const apiCalls = removedItems?.map(async (i) => {
            await removeItem?.mutateAsync({
              integrationId: integrationId || '',
              criterionId: customerConfigCriteriaId || '',
              criterionTriggerValueId: String(i),
            });
          }, []);
          Promise.all(apiCalls);
        }
        customerOnRowSelectionChange?.(selectedKeys, selectedRows);
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      addItem,
      customerConfigCriteriaId,
      customerOnRowSelectionChange,
      customerSelectedRowsKeys,
      integrationId,
      removeItem,
    ],
  );

  const onDelete = useCallback(
    async (actionPayload?: TradePartnerResponse) => {
      try {
        const updatedkeys =
          customerSelectedRowsKeys?.filter((item) => item !== actionPayload?.id, []) || [];
        const updatedRows =
          customerSelectedRows?.filter((item) => item?.id !== actionPayload?.id, []) || [];

        await removeItem?.mutateAsync({
          integrationId: integrationId || '',
          criterionId: customerConfigCriteriaId || '',
          criterionTriggerValueId: actionPayload?.id || '',
        });
        message.success(t('customer_delete_success'));
        customerOnRowSelectionChange?.(updatedkeys, updatedRows);
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      customerConfigCriteriaId,
      customerOnRowSelectionChange,
      customerSelectedRows,
      customerSelectedRowsKeys,
      integrationId,
      removeItem,
      t,
    ],
  );

  const onDelRow = useCallback(
    (actionPayload?: TradePartnerResponse) => {
      Modal.warning({
        title: t?.('delete_warn_title', { name: actionPayload?.name }),
        content: t?.('delete_warn_content'),
        okText: t?.('delete_warn_remove'),
        cancelText: t?.('delete_warn_cancel'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
        onOk: () => onDelete(actionPayload),
      });
    },
    [onDelete, t],
  );

  const onEditRow = useCallback(
    (actionPayload?: TradePartnerResponse) => {
      setIntegrationTabSession('1');
      navigate(`../../network/${actionPayload?.id}/editLocation`);
    },
    [navigate],
  );

  const onSaveRow = useCallback(
    async (_: any, data: TradePartnerResponse) => {
      try {
        await addItem?.mutateAsync({
          integrationId: integrationId || '',
          criterionId: customerConfigCriteriaId || '',
          options: {
            body: {
              triggerValueId: data?.id,
            },
          },
        });
        customerOnRowSelectionChange?.(
          [...(customerSelectedRowsKeys || []), data?.id || ''],
          [...(customerSelectedRows || []), data],
        );
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      addItem,
      customerConfigCriteriaId,
      customerOnRowSelectionChange,
      customerSelectedRows,
      customerSelectedRowsKeys,
      integrationId,
    ],
  );

  const onCancelRow = async (_: any, data: TradePartnerResponse) => {
    actionRef.current?.cancelEditable(data?.id || 0);
  };

  return (
    <>
      {!(isReview || isConfig) && (
        <FormTitleBar
          count={customerSelectedRowsKeys?.length}
          tkey="settings.comp_integration.customer"
          {...(showErrorMessage && { errorMessage: t?.('atleast_select') })}
        />
      )}

      {isConfig && (
        <Col className={styles.margintop20}>
          <Typography.Title level={5}>
            {getT('settings.comp_integration.review.customer_table_title')}
          </Typography.Title>

          <Row justify="space-between" className={styles.rowstyle}>
            <Typography.Text>{t?.('customer_table_desc')}</Typography.Text>
            <Button
              type="primary"
              size="middle"
              shape="round"
              onClick={() => {
                actionRef.current?.addEditRecord?.({
                  id: Date.now().toString(),
                });
              }}
            >
              {t?.('add_company')}
            </Button>
          </Row>
        </Col>
      )}

      <GTable<TradePartnerResponse>
        actionRef={actionRef}
        columns={Columns({
          t,
          selectedRows: customerSelectedRowsKeys,
          isConfig,
          onEditRow,
          onDelRow,
          allLocations: record,
        })}
        loading={isLoading}
        value={
          isReview || isConfig
            ? (customerSelectedRows as TradePartnerResponse[])
            : records?.data || []
        }
        recordCreatorProps={false}
        enableRowSelection={!(isReview || isConfig)}
        {...(!(isReview || isConfig) && {
          rowSelection: {
            selectedRowKeys: customerSelectedRowsKeys,
            onChange: onSelectChange,
            preserveSelectedRowKeys: true,
            renderCell: (value, row, index, originNode) =>
              (addItem?.isLoading || removeItem?.isLoading) &&
              loadingItems?.includes(row?.id || '') ? (
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              ) : (
                originNode
              ),
          },
        })}
        rowKey="id"
        options={{
          setting: false,
          reload: false,
        }}
        editable={{
          onSave: (rowKey, actionPayload) => onSaveRow(rowKey, actionPayload),
          onCancel: async (rowKey, oldData, data) => onCancelRow(rowKey, data),
        }}
        actionsRenderOptions={{
          save: isConfig,
          cancel: isConfig,
        }}
        pagination={{
          defaultPageSize: 10,
          total: isReview || isConfig ? customerSelectedRows?.length : records?.data?.length,
          showTotal: (total, range) =>
            total <= 1
              ? t('company_pagi', { range0: range[0], range1: range[1], total })
              : t('company_pagi_multi', { range0: range[0], range1: range[1], total }),
        }}
        columnsState={{
          persistenceKey: 'select-customers',
        }}
      />
    </>
  );
};
export default SelectCustomers;
