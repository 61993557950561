import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, message, Modal, Space, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { drawerBodyStyle, drawerContentWrapper } from 'components/Settings/common';
import { useDrawerVisibility } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useUpdateIntegration } from 'services/api/useIntegrations';
import { errorHandler } from 'utils';
import FormProvider, {
  removeIntegrationStepSession,
  removeIntegrationTabSession,
  setIntegrationStepSession,
  useFormContext,
} from '../FormProvider';
import EnableForm from './EnableForm';

const whiteBackground = {
  backgroundColor: 'white',
};

const FormDrawer: React.FC = () => {
  const [drawerForm] = useForm();
  const { t } = useTranslation('pages', { keyPrefix: 'settings.comp_integration.drawer' });
  const { closeDrawer, visible, ...restDrawerVisibility } = useDrawerVisibility(true);
  const queryClient = useQueryClient();
  const updateIntegration = useUpdateIntegration(queryClient);
  const {
    productSelectedRowsKeys,
    customerSelectedRowsKeys,
    companyLocationSelectedRowsKeys,
    setShowErrorMessage,
    current,
    setCurrent,
    pluginObject,
    integrationId,
    configObject,
  } = useFormContext();

  const onDrawerClose = useCallback(() => {
    removeIntegrationTabSession();
    removeIntegrationStepSession();
    closeDrawer();
  }, [closeDrawer]);

  const onClose = useCallback(() => {
    Modal.warning({
      title: t?.('close_warn_title', { system: pluginObject?.name }),
      content: t?.('close_warn_content', { system: pluginObject?.name }),
      okText: t?.('close_warn_remove'),
      cancelText: t?.('close_warn_cancel'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
      onOk: () => onDrawerClose(),
    });
  }, [onDrawerClose, pluginObject?.name, t]);

  // Go to next screen after current step's submit success
  const onNext = useCallback(async () => {
    try {
      switch (current) {
        case 0:
          await drawerForm.validateFields();
          drawerForm.submit();
          break;
        case 1:
          if (companyLocationSelectedRowsKeys?.length === 0) {
            setShowErrorMessage?.(true);
          } else {
            setShowErrorMessage?.(false);
            setCurrent?.(current + 1);
            setIntegrationStepSession(String(current + 1));
          }
          break;
        case 2:
          if (productSelectedRowsKeys?.length === 0) {
            setShowErrorMessage?.(true);
          } else {
            setShowErrorMessage?.(false);
            setCurrent?.(current + 1);
            setIntegrationStepSession(String(current + 1));
          }
          break;

        case 3:
          if (customerSelectedRowsKeys?.length === 0) {
            setShowErrorMessage?.(true);
          } else {
            setShowErrorMessage?.(false);
            setCurrent?.(current + 1);
            setIntegrationStepSession(String(current + 1));
          }
          break;

        default:
          setCurrent?.((current || 0) + 1);
          setIntegrationStepSession(String((current || 0) + 1));
          await drawerForm.validateFields();
          drawerForm.submit();
      }
    } catch (error) {
      message.error('Please complete all required fields');
    }
  }, [
    current,
    customerSelectedRowsKeys?.length,
    drawerForm,
    productSelectedRowsKeys?.length,
    companyLocationSelectedRowsKeys?.length,
    setCurrent,
    setShowErrorMessage,
  ]);

  // on back
  const onBack = useCallback(() => {
    setCurrent?.((current || 0) - 1);
    setIntegrationStepSession(String((current || 0) - 1));
    setShowErrorMessage?.(false);
  }, [current, setCurrent, setShowErrorMessage]);

  const onDone = useCallback(async () => {
    try {
      await updateIntegration?.mutateAsync({
        integrationId: integrationId || '',
        options: {
          body: {
            externalSystem: configObject?.externalSystem,
            transportMethod: configObject?.transportMethod,
            transportFormat: configObject?.transportFormat,
            billedToEntity: configObject?.billedToEntity,
            customSystemConfigurationKeyVaultPath:
              configObject?.customSystemConfigurationKeyVaultPath,
            useCustomSystemConfiguration: configObject?.useCustomSystemConfiguration,
            isEnabled: true,
          },
        },
      });
      message.success(t?.('config_success'));
      onDrawerClose();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  }, [
    onDrawerClose,
    configObject?.billedToEntity,
    configObject?.customSystemConfigurationKeyVaultPath,
    configObject?.externalSystem,
    configObject?.transportFormat,
    configObject?.transportMethod,
    configObject?.useCustomSystemConfiguration,
    integrationId,
    t,
    updateIntegration,
  ]);

  const ExtraButton = useMemo(
    () => (
      <Space>
        {(current || 0) > 0 && (
          <Button type="primary" shape="round" ghost onClick={onBack}>
            {t?.('back')}
          </Button>
        )}
        <Button type="primary" shape="round" onClick={current === 4 ? onDone : onNext}>
          {current === 4 ? t?.('configure_integration') : t?.('next')}
        </Button>
      </Space>
    ),
    [current, onBack, onDone, onNext, t],
  );

  return (
    <Drawer
      {...restDrawerVisibility}
      title={
        <Space>
          <Button type="text" onClick={onClose}>
            <CloseOutlined />
          </Button>
          <Typography.Title level={5} className="mr0">
            {t?.('config_title', { system: pluginObject?.name })}
          </Typography.Title>
        </Space>
      }
      placement="top"
      height="100%"
      visible={visible}
      closable={false}
      size="large"
      bodyStyle={{ ...whiteBackground, ...drawerBodyStyle }}
      headerStyle={whiteBackground}
      extra={ExtraButton}
      contentWrapperStyle={drawerContentWrapper}
    >
      <EnableForm form={drawerForm} />
    </Drawer>
  );
};

const EnableFormDrawer = () => (
  <FormProvider>
    <FormDrawer />
  </FormProvider>
);

export default EnableFormDrawer;
