import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, message, Space, Typography } from 'antd';
import { drawerBodyStyle, drawerContentWrapper } from 'components/Settings/common';
import { useDrawerVisibility } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from 'utils';
import { hasUnsavedChanges } from 'utils/validation';
import FormProvider, {
  removeIntegrationStepSession,
  removeIntegrationTabSession,
  useFormContext,
} from '../FormProvider';
import ManageForm from './ManageForm';

const whiteBackground = {
  backgroundColor: 'white',
};

const FormDrawer: React.FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.comp_integration.manage_drawer' });
  const { closeDrawer, visible, ...restDrawerVisibility } = useDrawerVisibility(true);
  const { configObject } = useFormContext();

  const onDone = useCallback(() => {
    try {
      hasUnsavedChanges();
      removeIntegrationTabSession();
      removeIntegrationStepSession();
      closeDrawer();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  }, [closeDrawer]);

  const ExtraButton = useMemo(
    () => (
      <Button type="primary" shape="round" onClick={onDone}>
        {t?.('done')}
      </Button>
    ),
    [onDone, t],
  );

  return (
    <Drawer
      {...restDrawerVisibility}
      title={
        <Space>
          <Button type="text" onClick={onDone}>
            <CloseOutlined />
          </Button>
          <Typography.Title level={5} className="mr0">
            {t?.('config_title', { system: configObject?.externalSystem })}
          </Typography.Title>
        </Space>
      }
      placement="top"
      height="100%"
      visible={visible}
      closable={false}
      size="large"
      bodyStyle={{ ...whiteBackground, ...drawerBodyStyle }}
      headerStyle={whiteBackground}
      extra={ExtraButton}
      contentWrapperStyle={drawerContentWrapper}
    >
      <ManageForm />
    </Drawer>
  );
};

const EnableFormDrawer = () => (
  <FormProvider>
    <FormDrawer />
  </FormProvider>
);

export default EnableFormDrawer;
