import { ArrowLeftOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Button, Modal, RowProps, Space, Typography, message } from 'antd';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useAccount, useBulkUpdateDelete, useCreateInvite } from 'services/api';
import { InviteUsersToAccountRequest } from 'services/api/client/src';
import { errorHandler } from 'utils';
import InviteFormFields from './InviteFormFields';
import { DataItem, InviteProps } from './typings';
import useInviteStore from './useInviteStore';

const rowProps: RowProps = { gutter: [8, 0] };

const InviteModal: FC<InviteProps> = ({ modal }) => {
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const { t } = useTranslation('pages', { keyPrefix: 'settings.users_permission.invite_modal' });
  const queryClient = useQueryClient();
  const createInvite = useCreateInvite(queryClient);
  const bulkUpdateDelete = useBulkUpdateDelete(queryClient);
  const { data: account } = useAccount();

  const { emails, setEmails, users } = useInviteStore();
  const pendingChanges = useMemo(
    () => users?.some((partner) => partner.isUpdated || partner.isDeleted),
    [users],
  );

  const resetFields = useCallback(() => {
    editableFormRef.current?.resetFields();
    setEmails([]);
  }, [setEmails]);

  const atLeastOneAdminPopup = useCallback(() => {
    Modal.warning({
      title: t('one_admin.title'),
      content: t('one_admin.content'),
      centered: true,
      okCancel: false,
      width: 500,
      okText: t('one_admin.done'),
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
    });
  }, [t]);

  const closeModal = useCallback(() => {
    modal.hide();
    resetFields();
  }, [modal, resetFields]);
  const onCancel = useCallback(() => {
    if (pendingChanges) {
      Modal.confirm({
        title: t('cancel_changes_title'),
        okText: t('cancel_changes_btn'),
        cancelText: t('cancel_changes_btn_cancel'),
        onOk: closeModal,
        centered: true,
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
      });
    } else {
      closeModal();
    }
  }, [closeModal, pendingChanges, t]);

  const onFinish = useCallback(
    async (formData: DataItem) => {
      try {
        if ((emails?.length || 0) > 0) {
          const reqBody: InviteUsersToAccountRequest = {
            emails: formData.emails || [],
            role: formData.role || '',
            message: formData?.message || '',
          };
          await createInvite.mutateAsync(reqBody);
          message.success(t('submit_sucess'));

          // onSuccess(res);
        } else if (pendingChanges) {
          const adminCount =
            users?.length &&
            users?.filter((item) => item?.invitation === 'Accepted' && item?.role === 'Admin')
              ?.length;
          if (adminCount) {
            const reqBody = users?.map((item) => ({ ...item, accountId: account?.id }), []);
            await bulkUpdateDelete.mutateAsync(reqBody);
            message.success(t('submit_sucess_changes'));
          } else {
            atLeastOneAdminPopup();
          }
        }
        closeModal();
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      emails?.length,
      pendingChanges,
      closeModal,
      createInvite,
      t,
      users,
      bulkUpdateDelete,
      account?.id,
      atLeastOneAdminPopup,
    ],
  );

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={InviteFormFields({ t })}
      grid
      // @ts-ignore
      title={
        <Space>
          {(emails?.length || 0) > 0 && (
            <Button type="text" size="small" onClick={resetFields}>
              <ArrowLeftOutlined />
            </Button>
          )}
          {t('title')}
        </Space>
      }
      visible={modal.visible}
      rowProps={rowProps}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        width: '40%',
        ...modal,
        centered: true,
        onCancel,
      }}
      submitter={{
        searchConfig: {
          // eslint-disable-next-line no-nested-ternary
          submitText: pendingChanges
            ? t('save')
            : (emails?.length || 0) > 0
            ? t('send')
            : t('done'),
          resetText: t('cancel'),
        },
        resetButtonProps: {
          shape: 'round',
          onClick: closeModal,
          hidden: (emails?.length || 0) === 0,
          type: 'primary',
          ghost: true,
        },
        submitButtonProps: {
          shape: 'round',
        },
        render: (_, defaultDoms) => (
          <Space>
            <Typography.Text type="secondary">
              {pendingChanges ? t('pending_changes') : ''}
            </Typography.Text>
            {defaultDoms[0]}
            {defaultDoms[1]}
          </Space>
        ),
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default React.memo(InviteModal);
