import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter,
} from "@azure/core-client";
import {
  CreateAccountRequest as CreateAccountRequestMapper,
  UpdateAccountRequest as UpdateAccountRequestMapper,
  InviteUsersToAccountRequest as InviteUsersToAccountRequestMapper,
  UpdateTemplateAttributeRequest as UpdateTemplateAttributeRequestMapper,
  CreateAttributeRequest as CreateAttributeRequestMapper,
  UpdateDocumentRequest as UpdateDocumentRequestMapper,
  ShareDocumentRequest as ShareDocumentRequestMapper,
  UnshareDocumentRequest as UnshareDocumentRequestMapper,
  SupportEmailRequest as SupportEmailRequestMapper,
  CreateEpcisDataRequest as CreateEpcisDataRequestMapper,
  UpdateEventTemplateRequest as UpdateEventTemplateRequestMapper,
  CreateEventTemplateRequest as CreateEventTemplateRequestMapper,
  ShareEventTemplateRequest as ShareEventTemplateRequestMapper,
  MultiCommissionRequest as MultiCommissionRequestMapper,
  DecommissionRequest as DecommissionRequestMapper,
  MultiObserveRequest as MultiObserveRequestMapper,
  AggregateRequest as AggregateRequestMapper,
  DisaggregateRequest as DisaggregateRequestMapper,
  TransformRequest as TransformRequestMapper,
  MultiShipRequest as MultiShipRequestMapper,
  MultiReceiveRequest as MultiReceiveRequestMapper,
  CreateIntegrationDataRequest as CreateIntegrationDataRequestMapper,
  MultiDirectReceiveRequest as MultiDirectReceiveRequestMapper,
  SetExternalRegistryRequest as SetExternalRegistryRequestMapper,
  UpdateExternalRegistryRequest as UpdateExternalRegistryRequestMapper,
  CreateLanguageRequest as CreateLanguageRequestMapper,
  CreateLanguageAttributeRequest as CreateLanguageAttributeRequestMapper,
  CreateLanguageAttributeBulkRequest as CreateLanguageAttributeBulkRequestMapper,
  UpdateLanguageAttributeBulkRequest as UpdateLanguageAttributeBulkRequestMapper,
  UpdateLanguageAttributeRequest as UpdateLanguageAttributeRequestMapper,
  CreateLocationRequest as CreateLocationRequestMapper,
  UpdateLocationRequest as UpdateLocationRequestMapper,
  ToggleArchiveLocationRequest as ToggleArchiveLocationRequestMapper,
  ShareLocationRequest as ShareLocationRequestMapper,
  CreateNetworkInviteRequest as CreateNetworkInviteRequestMapper,
  UpdateNetworkInviteRequest as UpdateNetworkInviteRequestMapper,
  ChangeToReadNotificationRequest as ChangeToReadNotificationRequestMapper,
  CreateOutboundIntegrationRequest as CreateOutboundIntegrationRequestMapper,
  UpdateOutboundIntegrationRequest as UpdateOutboundIntegrationRequestMapper,
  CreateConfiguredOutboundIntegrationRequest as CreateConfiguredOutboundIntegrationRequestMapper,
  CreateTriggerCriterionRequest as CreateTriggerCriterionRequestMapper,
  CreateTriggerCriterionLookupValueRequest as CreateTriggerCriterionLookupValueRequestMapper,
  CreateDataMapElementRequest as CreateDataMapElementRequestMapper,
  SaveIntegrationFtpSecretsRequest as SaveIntegrationFtpSecretsRequestMapper,
  CreateProductRequest as CreateProductRequestMapper,
  UpdateProductRequest as UpdateProductRequestMapper,
  CreateSubProductsRequest as CreateSubProductsRequestMapper,
  ChangeArchivedProductRequest as ChangeArchivedProductRequestMapper,
  CreateExternalShipmentRequest as CreateExternalShipmentRequestMapper,
  CreateTradePartnerRequest as CreateTradePartnerRequestMapper,
  UpdateTradePartnerRequest as UpdateTradePartnerRequestMapper,
  ChangeArchivedTradePartnerRequest as ChangeArchivedTradePartnerRequestMapper,
  ToggleArchiveVesselsRequest as ToggleArchiveVesselsRequestMapper,
  ChangeAccountRequest as ChangeAccountRequestMapper,
  ChangeAcceptedTermsRequest as ChangeAcceptedTermsRequestMapper,
  ChangePasswordRequest as ChangePasswordRequestMapper,
  ChangeEmailRequest as ChangeEmailRequestMapper,
  ChangePinnedAccountRequest as ChangePinnedAccountRequestMapper,
  ChangePersonalInfoRequest as ChangePersonalInfoRequestMapper,
  ChangeSettingsRequest as ChangeSettingsRequestMapper,
  ChangeRoleRequest as ChangeRoleRequestMapper,
} from "../models/mappers";

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const body: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateAccountRequestMapper,
};

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const body1: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateAccountRequestMapper,
};

export const id: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const body2: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: InviteUsersToAccountRequestMapper,
};

export const inviteId: OperationURLParameter = {
  parameterPath: "inviteId",
  mapper: {
    serializedName: "inviteId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const name: OperationQueryParameter = {
  parameterPath: ["options", "name"],
  mapper: {
    serializedName: "Name",
    type: {
      name: "String",
    },
  },
};

export const email: OperationQueryParameter = {
  parameterPath: ["options", "email"],
  mapper: {
    serializedName: "Email",
    type: {
      name: "String",
    },
  },
};

export const role: OperationQueryParameter = {
  parameterPath: ["options", "role"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Role",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const status: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const pageNumber: OperationQueryParameter = {
  parameterPath: ["options", "pageNumber"],
  mapper: {
    serializedName: "PageNumber",
    type: {
      name: "Number",
    },
  },
};

export const pageSize: OperationQueryParameter = {
  parameterPath: ["options", "pageSize"],
  mapper: {
    serializedName: "PageSize",
    type: {
      name: "Number",
    },
  },
};

export const sortBy: OperationQueryParameter = {
  parameterPath: ["options", "sortBy"],
  mapper: {
    serializedName: "SortBy",
    type: {
      name: "String",
    },
  },
};

export const accountId: OperationURLParameter = {
  parameterPath: "accountId",
  mapper: {
    serializedName: "accountId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const userId: OperationURLParameter = {
  parameterPath: "userId",
  mapper: {
    serializedName: "userId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const body3: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateTemplateAttributeRequestMapper,
};

export const locationIds: OperationQueryParameter = {
  parameterPath: ["options", "locationIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "LocationIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const partnerIds: OperationQueryParameter = {
  parameterPath: ["options", "partnerIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "PartnerIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const fieldsFieldName: OperationQueryParameter = {
  parameterPath: ["options", "fieldsFieldName"],
  mapper: {
    serializedName: "Fields.FieldName",
    type: {
      name: "String",
    },
  },
};

export const fieldsPropertyName: OperationQueryParameter = {
  parameterPath: ["options", "fieldsPropertyName"],
  mapper: {
    serializedName: "Fields.PropertyName",
    type: {
      name: "String",
    },
  },
};

export const fieldsFieldTypes: OperationQueryParameter = {
  parameterPath: ["options", "fieldsFieldTypes"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Fields.FieldTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const values: OperationQueryParameter = {
  parameterPath: ["options", "values"],
  mapper: {
    serializedName: "Values.Values",
    type: {
      name: "String",
    },
  },
};

export const valuesDefaultValue: OperationQueryParameter = {
  parameterPath: ["options", "valuesDefaultValue"],
  mapper: {
    serializedName: "Values.DefaultValue",
    type: {
      name: "String",
    },
  },
};

export const namespaces: OperationQueryParameter = {
  parameterPath: ["options", "namespaces"],
  mapper: {
    serializedName: "Namespaces",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const locationName: OperationQueryParameter = {
  parameterPath: ["options", "locationName"],
  mapper: {
    serializedName: "LocationName",
    type: {
      name: "String",
    },
  },
};

export const propertyLocations: OperationQueryParameter = {
  parameterPath: ["options", "propertyLocations"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "PropertyLocations",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const hidden: OperationQueryParameter = {
  parameterPath: ["options", "hidden"],
  mapper: {
    serializedName: "Hidden",
    type: {
      name: "Boolean",
    },
  },
};

export const expandValues: OperationQueryParameter = {
  parameterPath: ["options", "expandValues"],
  mapper: {
    serializedName: "ExpandValues",
    type: {
      name: "Boolean",
    },
  },
};

export const body4: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateAttributeRequestMapper,
};

export const partnerId: OperationQueryParameter = {
  parameterPath: ["options", "partnerId"],
  mapper: {
    serializedName: "PartnerId",
    type: {
      name: "Uuid",
    },
  },
};

export const field: OperationQueryParameter = {
  parameterPath: ["options", "field"],
  mapper: {
    serializedName: "Field",
    type: {
      name: "String",
    },
  },
};

export const propertyName: OperationQueryParameter = {
  parameterPath: ["options", "propertyName"],
  mapper: {
    serializedName: "PropertyName",
    type: {
      name: "String",
    },
  },
};

export const types: OperationQueryParameter = {
  parameterPath: ["options", "types"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Types",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const values1: OperationQueryParameter = {
  parameterPath: ["options", "values"],
  mapper: {
    serializedName: "Values",
    type: {
      name: "String",
    },
  },
};

export const defaultValue: OperationQueryParameter = {
  parameterPath: ["options", "defaultValue"],
  mapper: {
    serializedName: "DefaultValue",
    type: {
      name: "String",
    },
  },
};

export const location: OperationQueryParameter = {
  parameterPath: ["options", "location"],
  mapper: {
    serializedName: "Location",
    type: {
      name: "String",
    },
  },
};

export const locationId: OperationURLParameter = {
  parameterPath: "locationId",
  mapper: {
    serializedName: "locationId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const documentId: OperationURLParameter = {
  parameterPath: "documentId",
  mapper: {
    serializedName: "documentId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const body5: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateDocumentRequestMapper,
};

export const accept1: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/octet-stream, application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const contentType1: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "multipart/form-data",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const title: OperationParameter = {
  parameterPath: ["options", "title"],
  mapper: {
    serializedName: "Title",
    type: {
      name: "String",
    },
  },
};

export const file: OperationParameter = {
  parameterPath: ["options", "file"],
  mapper: {
    serializedName: "File",
    type: {
      name: "Stream",
    },
  },
};

export const privacy: OperationParameter = {
  parameterPath: ["options", "privacy"],
  mapper: {
    serializedName: "Privacy",
    type: {
      name: "String",
    },
  },
};

export const blockchain: OperationParameter = {
  parameterPath: ["options", "blockchain"],
  mapper: {
    serializedName: "Blockchain",
    type: {
      name: "String",
    },
  },
};

export const documentType: OperationParameter = {
  parameterPath: ["options", "documentType"],
  mapper: {
    serializedName: "DocumentType",
    type: {
      name: "String",
    },
  },
};

export const documentTypeName: OperationParameter = {
  parameterPath: ["options", "documentTypeName"],
  mapper: {
    serializedName: "DocumentTypeName",
    type: {
      name: "String",
    },
  },
};

export const expirationDate: OperationParameter = {
  parameterPath: ["options", "expirationDate"],
  mapper: {
    serializedName: "ExpirationDate",
    type: {
      name: "Date",
    },
  },
};

export const expirationAlertDate: OperationParameter = {
  parameterPath: ["options", "expirationAlertDate"],
  mapper: {
    serializedName: "ExpirationAlertDate",
    type: {
      name: "Date",
    },
  },
};

export const locationsIds: OperationParameter = {
  parameterPath: ["options", "locationsIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "LocationsIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const productsIds: OperationParameter = {
  parameterPath: ["options", "productsIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "ProductsIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const tradePartnersIds: OperationParameter = {
  parameterPath: ["options", "tradePartnersIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "TradePartnersIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const purchaseOrderNumber: OperationParameter = {
  parameterPath: ["options", "purchaseOrderNumber"],
  mapper: {
    serializedName: "PurchaseOrderNumber",
    type: {
      name: "String",
    },
  },
};

export const certificationStandard: OperationParameter = {
  parameterPath: ["options", "certificationStandard"],
  mapper: {
    serializedName: "CertificationStandard",
    type: {
      name: "String",
    },
  },
};

export const certificationAgency: OperationParameter = {
  parameterPath: ["options", "certificationAgency"],
  mapper: {
    serializedName: "CertificationAgency",
    type: {
      name: "String",
    },
  },
};

export const certificationId: OperationParameter = {
  parameterPath: ["options", "certificationId"],
  mapper: {
    serializedName: "CertificationId",
    type: {
      name: "String",
    },
  },
};

export const shipFrom: OperationParameter = {
  parameterPath: ["options", "shipFrom"],
  mapper: {
    serializedName: "ShipFrom",
    type: {
      name: "String",
    },
  },
};

export const shipTo: OperationParameter = {
  parameterPath: ["options", "shipTo"],
  mapper: {
    serializedName: "ShipTo",
    type: {
      name: "String",
    },
  },
};

export const sid: OperationParameter = {
  parameterPath: ["options", "sid"],
  mapper: {
    serializedName: "SID",
    type: {
      name: "String",
    },
  },
};

export const cid: OperationParameter = {
  parameterPath: ["options", "cid"],
  mapper: {
    serializedName: "CID",
    type: {
      name: "String",
    },
  },
};

export const fob: OperationParameter = {
  parameterPath: ["options", "fob"],
  mapper: {
    serializedName: "FOB",
    type: {
      name: "String",
    },
  },
};

export const shipDate: OperationParameter = {
  parameterPath: ["options", "shipDate"],
  mapper: {
    serializedName: "ShipDate",
    type: {
      name: "DateTime",
    },
  },
};

export const carrier: OperationParameter = {
  parameterPath: ["options", "carrier"],
  mapper: {
    serializedName: "Carrier",
    type: {
      name: "String",
    },
  },
};

export const items: OperationParameter = {
  parameterPath: ["options", "items"],
  mapper: {
    serializedName: "Items",
    type: {
      name: "String",
    },
  },
};

export const quantitiesOfShippedItems: OperationParameter = {
  parameterPath: ["options", "quantitiesOfShippedItems"],
  mapper: {
    serializedName: "QuantitiesOfShippedItems",
    type: {
      name: "String",
    },
  },
};

export const accept2: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const title1: OperationQueryParameter = {
  parameterPath: ["options", "title"],
  mapper: {
    serializedName: "Title",
    type: {
      name: "String",
    },
  },
};

export const documentType1: OperationQueryParameter = {
  parameterPath: ["options", "documentType"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "DocumentType",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const tradePartnerIds: OperationQueryParameter = {
  parameterPath: ["options", "tradePartnerIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "TradePartnerIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const productIds: OperationQueryParameter = {
  parameterPath: ["options", "productIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "ProductIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const sharedWith: OperationQueryParameter = {
  parameterPath: ["options", "sharedWith"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "SharedWith",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const privacy1: OperationQueryParameter = {
  parameterPath: ["options", "privacy"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Privacy",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const showArchived: OperationQueryParameter = {
  parameterPath: ["options", "showArchived"],
  mapper: {
    serializedName: "ShowArchived",
    type: {
      name: "Boolean",
    },
  },
};

export const productId: OperationQueryParameter = {
  parameterPath: ["options", "productId"],
  mapper: {
    serializedName: "ProductId",
    type: {
      name: "Uuid",
    },
  },
};

export const eventId: OperationQueryParameter = {
  parameterPath: ["options", "eventId"],
  mapper: {
    serializedName: "EventId",
    type: {
      name: "Uuid",
    },
  },
};

export const body6: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ShareDocumentRequestMapper,
};

export const body7: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UnshareDocumentRequestMapper,
};

export const body8: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: SupportEmailRequestMapper,
};

export const body9: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateEpcisDataRequestMapper,
};

export const body10: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateEventTemplateRequestMapper,
};

export const accept3: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/problem+json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const templateName: OperationQueryParameter = {
  parameterPath: ["options", "templateName"],
  mapper: {
    serializedName: "TemplateName",
    type: {
      name: "String",
    },
  },
};

export const primaryId: OperationQueryParameter = {
  parameterPath: ["options", "primaryId"],
  mapper: {
    serializedName: "PrimaryId",
    type: {
      name: "Uuid",
    },
  },
};

export const roles: OperationQueryParameter = {
  parameterPath: ["options", "roles"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Roles",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const body11: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateEventTemplateRequestMapper,
};

export const body12: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ShareEventTemplateRequestMapper,
};

export const templateId: OperationURLParameter = {
  parameterPath: "templateId",
  mapper: {
    serializedName: "templateId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const accountId1: OperationQueryParameter = {
  parameterPath: ["options", "accountId"],
  mapper: {
    serializedName: "accountId",
    type: {
      name: "Uuid",
    },
  },
};

export const accept4: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json, application/problem+json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const eventIds: OperationQueryParameter = {
  parameterPath: ["options", "eventIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "EventIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const performTraceback: OperationQueryParameter = {
  parameterPath: ["options", "performTraceback"],
  mapper: {
    serializedName: "PerformTraceback",
    type: {
      name: "Boolean",
    },
  },
};

export const isInboundShipment: OperationQueryParameter = {
  parameterPath: ["options", "isInboundShipment"],
  mapper: {
    serializedName: "IsInboundShipment",
    type: {
      name: "Boolean",
    },
  },
};

export const eventTypes: OperationQueryParameter = {
  parameterPath: ["options", "eventTypes"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "EventTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const lotSerial: OperationQueryParameter = {
  parameterPath: ["options", "lotSerial"],
  mapper: {
    serializedName: "LotSerial",
    type: {
      name: "String",
    },
  },
};

export const purchaseOrder: OperationQueryParameter = {
  parameterPath: ["options", "purchaseOrder"],
  mapper: {
    serializedName: "PurchaseOrder",
    type: {
      name: "String",
    },
  },
};

export const companyIds: OperationQueryParameter = {
  parameterPath: ["options", "companyIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "CompanyIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const eventId1: OperationURLParameter = {
  parameterPath: "eventId",
  mapper: {
    serializedName: "eventId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const instanceId: OperationQueryParameter = {
  parameterPath: ["options", "instanceId"],
  mapper: {
    serializedName: "instanceId",
    type: {
      name: "Uuid",
    },
  },
};

export const containerId: OperationQueryParameter = {
  parameterPath: ["options", "containerId"],
  mapper: {
    serializedName: "containerId",
    type: {
      name: "Uuid",
    },
  },
};

export const showAllProducts: OperationQueryParameter = {
  parameterPath: ["options", "showAllProducts"],
  mapper: {
    serializedName: "showAllProducts",
    type: {
      name: "Boolean",
    },
  },
};

export const isInboundShipment1: OperationQueryParameter = {
  parameterPath: ["options", "isInboundShipment"],
  mapper: {
    serializedName: "isInboundShipment",
    type: {
      name: "Boolean",
    },
  },
};

export const id1: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const lERecordTime: OperationQueryParameter = {
  parameterPath: ["options", "lERecordTime"],
  mapper: {
    serializedName: "LE_recordTime",
    type: {
      name: "DateTime",
    },
  },
};

export const gERecordTime: OperationQueryParameter = {
  parameterPath: ["options", "gERecordTime"],
  mapper: {
    serializedName: "GE_recordTime",
    type: {
      name: "DateTime",
    },
  },
};

export const lEEventTime: OperationQueryParameter = {
  parameterPath: ["options", "lEEventTime"],
  mapper: {
    serializedName: "LE_eventTime",
    type: {
      name: "DateTime",
    },
  },
};

export const gEEventTime: OperationQueryParameter = {
  parameterPath: ["options", "gEEventTime"],
  mapper: {
    serializedName: "GE_eventTime",
    type: {
      name: "DateTime",
    },
  },
};

export const matchAnyEPC: OperationQueryParameter = {
  parameterPath: ["options", "matchAnyEPC"],
  mapper: {
    serializedName: "MATCH_anyEPC",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const matchAnyEPCClass: OperationQueryParameter = {
  parameterPath: ["options", "matchAnyEPCClass"],
  mapper: {
    serializedName: "MATCH_anyEPCClass",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const eQBizStep: OperationQueryParameter = {
  parameterPath: ["options", "eQBizStep"],
  mapper: {
    serializedName: "EQ_bizStep",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const typeParam: OperationQueryParameter = {
  parameterPath: ["options", "type"],
  mapper: {
    serializedName: "Type",
    type: {
      name: "String",
    },
  },
};

export const body13: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: MultiCommissionRequestMapper,
};

export const body14: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: DecommissionRequestMapper,
};

export const body15: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: MultiObserveRequestMapper,
};

export const body16: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: AggregateRequestMapper,
};

export const body17: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: DisaggregateRequestMapper,
};

export const body18: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: TransformRequestMapper,
};

export const body19: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: MultiShipRequestMapper,
};

export const body20: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: MultiReceiveRequestMapper,
};

export const body21: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateIntegrationDataRequestMapper,
};

export const body22: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: MultiDirectReceiveRequestMapper,
};

export const body23: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: SetExternalRegistryRequestMapper,
};

export const body24: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateExternalRegistryRequestMapper,
};

export const tradePartnerId: OperationURLParameter = {
  parameterPath: "tradePartnerId",
  mapper: {
    serializedName: "tradePartnerId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const url: OperationQueryParameter = {
  parameterPath: ["options", "url"],
  mapper: {
    serializedName: "Url",
    type: {
      name: "String",
    },
  },
};

export const apiKey: OperationQueryParameter = {
  parameterPath: ["options", "apiKey"],
  mapper: {
    serializedName: "ApiKey",
    type: {
      name: "String",
    },
  },
};

export const linkType: OperationQueryParameter = {
  parameterPath: ["options", "linkType"],
  mapper: {
    serializedName: "LinkType",
    type: {
      name: "String",
    },
  },
};

export const body25: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateLanguageRequestMapper,
};

export const languageCode: OperationURLParameter = {
  parameterPath: "languageCode",
  mapper: {
    serializedName: "languageCode",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body26: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateLanguageAttributeRequestMapper,
};

export const languageCodes: OperationQueryParameter = {
  parameterPath: ["options", "languageCodes"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "LanguageCodes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const languageAttributeName: OperationQueryParameter = {
  parameterPath: ["options", "languageAttributeName"],
  mapper: {
    serializedName: "LanguageAttributeName",
    type: {
      name: "String",
    },
  },
};

export const status1: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const languageAttributeValue: OperationQueryParameter = {
  parameterPath: ["options", "languageAttributeValue"],
  mapper: {
    serializedName: "LanguageAttributeValue",
    type: {
      name: "String",
    },
  },
};

export const types1: OperationQueryParameter = {
  parameterPath: ["options", "types"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Types",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const body27: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateLanguageAttributeBulkRequestMapper,
};

export const body28: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateLanguageAttributeBulkRequestMapper,
};

export const attributeName: OperationURLParameter = {
  parameterPath: "attributeName",
  mapper: {
    serializedName: "attributeName",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const typeParam1: OperationQueryParameter = {
  parameterPath: ["options", "type"],
  mapper: {
    serializedName: "type",
    type: {
      name: "String",
    },
  },
};

export const body29: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateLanguageAttributeRequestMapper,
};

export const showArchived1: OperationQueryParameter = {
  parameterPath: ["options", "showArchived"],
  mapper: {
    serializedName: "showArchived",
    type: {
      name: "Boolean",
    },
  },
};

export const body30: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateLocationRequestMapper,
};

export const body31: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateLocationRequestMapper,
};

export const tradePartnerId1: OperationQueryParameter = {
  parameterPath: ["options", "tradePartnerId"],
  mapper: {
    serializedName: "TradePartnerId",
    type: {
      name: "Uuid",
    },
  },
};

export const isVessel: OperationQueryParameter = {
  parameterPath: ["options", "isVessel"],
  mapper: {
    serializedName: "IsVessel",
    type: {
      name: "Boolean",
    },
  },
};

export const body32: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ToggleArchiveLocationRequestMapper,
};

export const body33: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ShareLocationRequestMapper,
};

export const pgln: OperationURLParameter = {
  parameterPath: "pgln",
  mapper: {
    serializedName: "pgln",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const gln: OperationURLParameter = {
  parameterPath: "gln",
  mapper: {
    serializedName: "gln",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const gtin: OperationURLParameter = {
  parameterPath: "gtin",
  mapper: {
    serializedName: "gtin",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const lotSerial1: OperationURLParameter = {
  parameterPath: "lotSerial",
  mapper: {
    serializedName: "lotSerial",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const address: OperationQueryParameter = {
  parameterPath: ["options", "address"],
  mapper: {
    serializedName: "Address",
    type: {
      name: "String",
    },
  },
};

export const status2: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const status3: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const networkInvitationId: OperationQueryParameter = {
  parameterPath: ["options", "networkInvitationId"],
  mapper: {
    serializedName: "NetworkInvitationId",
    type: {
      name: "Uuid",
    },
  },
};

export const accountId2: OperationQueryParameter = {
  parameterPath: ["options", "accountId"],
  mapper: {
    serializedName: "AccountId",
    type: {
      name: "Uuid",
    },
  },
};

export const incoming: OperationQueryParameter = {
  parameterPath: ["options", "incoming"],
  mapper: {
    serializedName: "Incoming",
    type: {
      name: "Boolean",
    },
  },
};

export const body34: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateNetworkInviteRequestMapper,
};

export const body35: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateNetworkInviteRequestMapper,
};

export const typeParam2: OperationQueryParameter = {
  parameterPath: ["options", "type"],
  mapper: {
    serializedName: "Type",
    type: {
      name: "String",
    },
  },
};

export const body36: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangeToReadNotificationRequestMapper,
};

export const productName: OperationQueryParameter = {
  parameterPath: ["options", "productName"],
  mapper: {
    serializedName: "ProductName",
    type: {
      name: "String",
    },
  },
};

export const company: OperationQueryParameter = {
  parameterPath: ["options", "company"],
  mapper: {
    serializedName: "Company",
    type: {
      name: "String",
    },
  },
};

export const quantity: OperationQueryParameter = {
  parameterPath: ["options", "quantity"],
  mapper: {
    serializedName: "Quantity",
    type: {
      name: "Number",
    },
  },
};

export const ticketId: OperationQueryParameter = {
  parameterPath: ["options", "ticketId"],
  mapper: {
    serializedName: "TicketId",
    type: {
      name: "String",
    },
  },
};

export const gtin1: OperationQueryParameter = {
  parameterPath: ["options", "gtin"],
  mapper: {
    serializedName: "GTIN",
    type: {
      name: "String",
    },
  },
};

export const shipmentStatus: OperationQueryParameter = {
  parameterPath: ["options", "shipmentStatus"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "ShipmentStatus",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const shippedToIds: OperationQueryParameter = {
  parameterPath: ["options", "shippedToIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "ShippedToIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const shippedFromIds: OperationQueryParameter = {
  parameterPath: ["options", "shippedFromIds"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "ShippedFromIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const startEventDate: OperationQueryParameter = {
  parameterPath: ["options", "startEventDate"],
  mapper: {
    serializedName: "StartEventDate",
    type: {
      name: "DateTime",
    },
  },
};

export const endEventDate: OperationQueryParameter = {
  parameterPath: ["options", "endEventDate"],
  mapper: {
    serializedName: "EndEventDate",
    type: {
      name: "DateTime",
    },
  },
};

export const startLoggedDate: OperationQueryParameter = {
  parameterPath: ["options", "startLoggedDate"],
  mapper: {
    serializedName: "StartLoggedDate",
    type: {
      name: "DateTime",
    },
  },
};

export const endLoggedDate: OperationQueryParameter = {
  parameterPath: ["options", "endLoggedDate"],
  mapper: {
    serializedName: "EndLoggedDate",
    type: {
      name: "DateTime",
    },
  },
};

export const orphanInstanceId: OperationQueryParameter = {
  parameterPath: ["options", "orphanInstanceId"],
  mapper: {
    serializedName: "OrphanInstanceId",
    type: {
      name: "Uuid",
    },
  },
};

export const orphanContainerId: OperationQueryParameter = {
  parameterPath: ["options", "orphanContainerId"],
  mapper: {
    serializedName: "OrphanContainerId",
    type: {
      name: "Uuid",
    },
  },
};

export const preferred: OperationQueryParameter = {
  parameterPath: ["options", "preferred"],
  mapper: {
    serializedName: "Preferred",
    type: {
      name: "Boolean",
    },
  },
};

export const quantity1: OperationQueryParameter = {
  parameterPath: ["options", "quantity"],
  mapper: {
    serializedName: "Quantity",
    type: {
      name: "Number",
    },
  },
};

export const orphanInstanceId1: OperationQueryParameter = {
  parameterPath: "orphanInstanceId",
  mapper: {
    serializedName: "OrphanInstanceId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const instanceId1: OperationQueryParameter = {
  parameterPath: "instanceId",
  mapper: {
    serializedName: "InstanceId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const needsTransform: OperationQueryParameter = {
  parameterPath: ["options", "needsTransform"],
  mapper: {
    serializedName: "NeedsTransform",
    type: {
      name: "Boolean",
    },
  },
};

export const integrationId: OperationURLParameter = {
  parameterPath: "integrationId",
  mapper: {
    serializedName: "integrationId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const outboundIntegrationId: OperationQueryParameter = {
  parameterPath: ["options", "outboundIntegrationId"],
  mapper: {
    serializedName: "OutboundIntegrationId",
    type: {
      name: "Uuid",
    },
  },
};

export const accountPluginActivationId: OperationQueryParameter = {
  parameterPath: ["options", "accountPluginActivationId"],
  mapper: {
    serializedName: "accountPluginActivationId",
    type: {
      name: "Uuid",
    },
  },
};

export const body37: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateOutboundIntegrationRequestMapper,
};

export const body38: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateOutboundIntegrationRequestMapper,
};

export const body39: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateConfiguredOutboundIntegrationRequestMapper,
};

export const id2: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const triggerSpecificationGuid: OperationURLParameter = {
  parameterPath: "triggerSpecificationGuid",
  mapper: {
    serializedName: "triggerSpecificationGuid",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const defaultConfiguration: OperationURLParameter = {
  parameterPath: "defaultConfiguration",
  mapper: {
    serializedName: "defaultConfiguration",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const body40: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateTriggerCriterionRequestMapper,
};

export const criteriaId: OperationURLParameter = {
  parameterPath: "criteriaId",
  mapper: {
    serializedName: "criteriaId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const body41: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateTriggerCriterionLookupValueRequestMapper,
};

export const criterionId: OperationURLParameter = {
  parameterPath: "criterionId",
  mapper: {
    serializedName: "criterionId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const criterionTriggerValueId: OperationURLParameter = {
  parameterPath: "criterionTriggerValueId",
  mapper: {
    serializedName: "criterionTriggerValueId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const body42: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateDataMapElementRequestMapper,
};

export const elementMapId: OperationURLParameter = {
  parameterPath: "elementMapId",
  mapper: {
    serializedName: "elementMapId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const body43: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: SaveIntegrationFtpSecretsRequestMapper,
};

export const body44: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateProductRequestMapper,
};

export const urn: OperationQueryParameter = {
  parameterPath: ["options", "urn"],
  mapper: {
    serializedName: "Urn",
    type: {
      name: "String",
    },
  },
};

export const sharingPolicies: OperationQueryParameter = {
  parameterPath: ["options", "sharingPolicies"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "SharingPolicies",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const authorizingEventId: OperationQueryParameter = {
  parameterPath: ["options", "authorizingEventId"],
  mapper: {
    serializedName: "authorizingEventId",
    type: {
      name: "Uuid",
    },
  },
};

export const body45: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateProductRequestMapper,
};

export const isHistory: OperationQueryParameter = {
  parameterPath: ["options", "isHistory"],
  mapper: {
    serializedName: "IsHistory",
    type: {
      name: "Boolean",
    },
  },
};

export const instanceIds: OperationQueryParameter = {
  parameterPath: ["options", "instanceIds"],
  mapper: {
    serializedName: "InstanceIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const lotSerial11: OperationQueryParameter = {
  parameterPath: ["options", "lotSerial1"],
  mapper: {
    serializedName: "LotSerial",
    type: {
      name: "String",
    },
  },
};

export const body46: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateSubProductsRequestMapper,
};

export const productId1: OperationURLParameter = {
  parameterPath: "productId",
  mapper: {
    serializedName: "productId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const subProductId: OperationURLParameter = {
  parameterPath: "subProductId",
  mapper: {
    serializedName: "subProductId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const body47: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangeArchivedProductRequestMapper,
};

export const file1: OperationParameter = {
  parameterPath: ["options", "file"],
  mapper: {
    serializedName: "file",
    type: {
      name: "Stream",
    },
  },
};

export const direction: OperationQueryParameter = {
  parameterPath: ["options", "direction"],
  mapper: {
    serializedName: "Direction",
    type: {
      name: "String",
    },
  },
};

export const shippedFromTradePartnerUrn: OperationQueryParameter = {
  parameterPath: ["options", "shippedFromTradePartnerUrn"],
  mapper: {
    serializedName: "ShippedFromTradePartnerUrn",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const shippedToTradePartnerUrn: OperationQueryParameter = {
  parameterPath: ["options", "shippedToTradePartnerUrn"],
  mapper: {
    serializedName: "ShippedToTradePartnerUrn",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const shippedFromUrn: OperationQueryParameter = {
  parameterPath: ["options", "shippedFromUrn"],
  mapper: {
    serializedName: "ShippedFromUrn",
    type: {
      name: "String",
    },
  },
};

export const shippedToUrn: OperationQueryParameter = {
  parameterPath: ["options", "shippedToUrn"],
  mapper: {
    serializedName: "ShippedToUrn",
    type: {
      name: "String",
    },
  },
};

export const primaryId1: OperationQueryParameter = {
  parameterPath: ["options", "primaryId"],
  mapper: {
    serializedName: "PrimaryId",
    type: {
      name: "String",
    },
  },
};

export const purchaseOrderNumber1: OperationQueryParameter = {
  parameterPath: ["options", "purchaseOrderNumber"],
  mapper: {
    serializedName: "PurchaseOrderNumber",
    type: {
      name: "String",
    },
  },
};

export const onlyMyShipments: OperationQueryParameter = {
  parameterPath: ["options", "onlyMyShipments"],
  mapper: {
    serializedName: "OnlyMyShipments",
    type: {
      name: "Boolean",
    },
  },
};

export const status4: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const startDate: OperationQueryParameter = {
  parameterPath: ["options", "startDate"],
  mapper: {
    serializedName: "StartDate",
    type: {
      name: "DateTime",
    },
  },
};

export const endDate: OperationQueryParameter = {
  parameterPath: ["options", "endDate"],
  mapper: {
    serializedName: "EndDate",
    type: {
      name: "DateTime",
    },
  },
};

export const primaryIdOrProductName: OperationQueryParameter = {
  parameterPath: ["options", "primaryIdOrProductName"],
  mapper: {
    serializedName: "PrimaryIdOrProductName",
    type: {
      name: "String",
    },
  },
};

export const startDate1: OperationQueryParameter = {
  parameterPath: ["options", "startDate"],
  mapper: {
    serializedName: "StartDate",
    type: {
      name: "Date",
    },
  },
};

export const endDate1: OperationQueryParameter = {
  parameterPath: ["options", "endDate"],
  mapper: {
    serializedName: "EndDate",
    type: {
      name: "Date",
    },
  },
};

export const locationUrn: OperationQueryParameter = {
  parameterPath: ["options", "locationUrn"],
  mapper: {
    serializedName: "LocationUrn",
    type: {
      name: "String",
    },
  },
};

export const tradePartnerUrn: OperationQueryParameter = {
  parameterPath: ["options", "tradePartnerUrn"],
  mapper: {
    serializedName: "TradePartnerUrn",
    type: {
      name: "String",
    },
  },
};

export const body48: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateExternalShipmentRequestMapper,
};

export const captureId: OperationURLParameter = {
  parameterPath: "captureId",
  mapper: {
    serializedName: "captureId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const originTradePartnerUrns: OperationQueryParameter = {
  parameterPath: ["options", "originTradePartnerUrns"],
  mapper: {
    serializedName: "OriginTradePartnerUrns",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const originLocationUrns: OperationQueryParameter = {
  parameterPath: ["options", "originLocationUrns"],
  mapper: {
    serializedName: "OriginLocationUrns",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const destinationTradePartnerUrns: OperationQueryParameter = {
  parameterPath: ["options", "destinationTradePartnerUrns"],
  mapper: {
    serializedName: "DestinationTradePartnerUrns",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const destinationLocationUrns: OperationQueryParameter = {
  parameterPath: ["options", "destinationLocationUrns"],
  mapper: {
    serializedName: "DestinationLocationUrns",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const shipmentIds: OperationQueryParameter = {
  parameterPath: ["options", "shipmentIds"],
  mapper: {
    serializedName: "ShipmentIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const inboundShipmentType: OperationQueryParameter = {
  parameterPath: ["options", "inboundShipmentType"],
  mapper: {
    serializedName: "InboundShipmentType",
    type: {
      name: "String",
    },
  },
};

export const shipmentIds1: OperationQueryParameter = {
  parameterPath: "shipmentIds",
  mapper: {
    serializedName: "ShipmentIds",
    required: true,
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const gtin2: OperationQueryParameter = {
  parameterPath: ["options", "gtin"],
  mapper: {
    serializedName: "Gtin",
    type: {
      name: "String",
    },
  },
};

export const outboundShipmentType: OperationQueryParameter = {
  parameterPath: ["options", "outboundShipmentType"],
  mapper: {
    serializedName: "OutboundShipmentType",
    type: {
      name: "String",
    },
  },
};

export const hello: OperationQueryParameter = {
  parameterPath: ["options", "hello"],
  mapper: {
    serializedName: "hello",
    type: {
      name: "String",
    },
  },
};

export const testApiKey: OperationQueryParameter = {
  parameterPath: ["options", "testApiKey"],
  mapper: {
    serializedName: "testApiKey",
    type: {
      name: "String",
    },
  },
};

export const digitalLink: OperationQueryParameter = {
  parameterPath: ["options", "digitalLink"],
  mapper: {
    serializedName: "digitalLink",
    type: {
      name: "String",
    },
  },
};

export const userId1: OperationQueryParameter = {
  parameterPath: ["options", "userId"],
  mapper: {
    serializedName: "userId",
    type: {
      name: "Uuid",
    },
  },
};

export const body49: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateTradePartnerRequestMapper,
};

export const urns: OperationQueryParameter = {
  parameterPath: ["options", "urns"],
  mapper: {
    serializedName: "Urns",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const types2: OperationQueryParameter = {
  parameterPath: ["options", "types"],
  mapper: {
    constraints: {
      UniqueItems: true,
    },
    serializedName: "Types",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String",
        },
      },
    },
  },
};

export const body50: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateTradePartnerRequestMapper,
};

export const body51: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangeArchivedTradePartnerRequestMapper,
};

export const body52: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ToggleArchiveVesselsRequestMapper,
};

export const id3: OperationQueryParameter = {
  parameterPath: ["options", "id"],
  mapper: {
    serializedName: "id",
    type: {
      name: "Uuid",
    },
  },
};

export const name1: OperationQueryParameter = {
  parameterPath: ["options", "name"],
  mapper: {
    serializedName: "name",
    type: {
      name: "String",
    },
  },
};

export const body53: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangeAccountRequestMapper,
};

export const body54: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangeAcceptedTermsRequestMapper,
};

export const body55: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangePasswordRequestMapper,
};

export const body56: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangeEmailRequestMapper,
};

export const isSiteAdmin: OperationParameter = {
  parameterPath: ["options", "isSiteAdmin"],
  mapper: {
    serializedName: "isSiteAdmin",
    type: {
      name: "Boolean",
    },
  },
};

export const body57: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangePinnedAccountRequestMapper,
};

export const body58: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangePersonalInfoRequestMapper,
};

export const body59: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangeSettingsRequestMapper,
};

export const body60: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangeRoleRequestMapper,
};

export const contentType2: OperationParameter = {
  parameterPath: "contentType",
  mapper: {
    defaultValue: "application/octet-stream",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const data: OperationParameter = {
  parameterPath: ["options", "data"],
  mapper: {
    serializedName: "data",
    type: {
      name: "Stream",
    },
  },
};

export const accept5: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const contentType3: OperationParameter = {
  parameterPath: "contentType",
  mapper: {
    defaultValue: "multipart/form-data",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const picture: OperationParameter = {
  parameterPath: ["options", "picture"],
  mapper: {
    serializedName: "Picture",
    type: {
      name: "Stream",
    },
  },
};
