import { queryClient } from 'index';
import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  GetBlockchainByEventIdOptionalParams,
  GetEventDetailsDiagramOptionalParams,
  GetEventDetailsMapOptionalParams,
  ListEventDetailsOptionalParams,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';
import { GetEventResponseFn } from './typings';

export const getEventById = (eventId?: string) =>
  eventId ? HttpClient.getEventById(eventId) : undefined;

export const getEventResponse: GetEventResponseFn = async (body) => {
  let eventId: string;

  switch (typeof body) {
    case 'string':
      eventId = body;
      break;
    case 'object':
      if (Array.isArray(body)) {
        eventId = body?.[0];
      } else if (body?.body) {
        const resBody = body?.body;
        eventId = Array.isArray(resBody) ? resBody?.[0] : resBody;
      } else {
        return undefined; // Return undefined for invalid body format
      }
      break;
    default:
      return undefined; // Return undefined for invalid body type
  }

  const event = await queryClient.fetchQuery({
    queryKey: ['eventById', eventId],
    queryFn: () => getEventById(eventId),
  });
  return event;
};

export const getEventNode = (eventId?: string) =>
  eventId ? HttpClient.getEventById(eventId) : undefined;

export const getEventHistoryCount = (eventId?: string) =>
  eventId ? HttpClient.getEventHistoryCount(eventId) : undefined;

const setEventHistoryCount = async (eventId: string) => {
  try {
    await queryClient.prefetchQuery(['eventHistoryCount', eventId], () =>
      HttpClient.getEventHistoryCount(eventId),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.info('SET_EVENT_HISTORY_COUNT_ERROR', error);
  }
};

const getEventDiagramById = async (
  eventId?: string,
  options?: GetEventDetailsDiagramOptionalParams,
) => (eventId ? HttpClient.getEventDetailsDiagram(eventId, options) : undefined);

const getEventMapById = async (eventId?: string, options?: GetEventDetailsMapOptionalParams) => {
  if (!eventId) return undefined;
  await setEventHistoryCount(eventId);

  const result = await HttpClient.getEventDetailsMap(eventId, options);
  return result;
};

const getEventListById = (options?: ListEventDetailsOptionalParams) =>
  HttpClient.listEventDetails(options);

export const useEventDiagramById = (
  eventId?: string,
  options?: GetEventDetailsDiagramOptionalParams,
) =>
  useQuery(['eventDiagram', eventId, options], () => getEventDiagramById(eventId, options), {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
  });

export const useEventDiagramByIdEvent = (queryClientEvent: QueryClient) =>
  useMutation(
    ({ eventId, options }: { eventId?: string; options?: GetEventDetailsDiagramOptionalParams }) =>
      getEventDiagramById(eventId, options),
    {
      onSuccess: () => {
        queryClientEvent.invalidateQueries('diagrams');
      },
    },
  );

export const useEventMapById = (eventId?: string, options?: GetEventDetailsMapOptionalParams) =>
  useQuery(['eventMap', eventId, options], () => getEventMapById(eventId, options), {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
  });
export const useEventMapByIdEvent = (queryClientEvent: QueryClient) =>
  useMutation(
    ({ eventId, options }: { eventId?: string; options?: GetEventDetailsMapOptionalParams }) =>
      getEventMapById(eventId, options),
    {
      onSuccess: () => {
        queryClientEvent.invalidateQueries('products');
      },
    },
  );
const useEventById = (eventId?: string) =>
  useQuery(['event', eventId], () => getEventNode(eventId), {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
  });

export const useEventHistoryCountById = (eventId?: string) =>
  useQuery(['eventHistoryCount', eventId], () => getEventHistoryCount(eventId), {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
  });

export const useEventListById = (options?: ListEventDetailsOptionalParams) =>
  useQuery(['eventList', options], () => getEventListById(options), {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
  });

const getBlockchainDetailsById = (id: string, options?: GetBlockchainByEventIdOptionalParams) =>
  HttpClient.getBlockchainByEventId(id, options);

export const useBlockchainDetailsById = (
  id: string,
  options?: GetBlockchainByEventIdOptionalParams,
) =>
  useQuery(['eventList', options], () => getBlockchainDetailsById(id, options), {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
  });
export default useEventById;
