import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  CreatePreConfiguredOutboundIntegrationOptionalParams,
  CreatePreConfiguredOutboundIntegrationResponse,
  CreateTriggerCriteriaValueOptionalParams,
  CreateTriggerCriteriaValueResponse,
  DeleteTriggerCriteriaValueOptionalParams,
  GetIntegrationByIdOptionalParams,
  GetIntegrationByIdResponse,
  GetIntegrationsOptionalParams,
  GetIntegrationsResponse,
  GetTriggerSpecificationCriteriaOptionsOptionalParams,
  GetTriggerSpecificationCriteriaOptionsResponse,
  GetTriggerSpecificationsOptionalParams,
  GetTriggerSpecificationsResponse,
  ListPluginConfigurationsOptionalParams,
  ListPluginConfigurationsResponse,
  UpdateIntegrationOptionalParams,
  UpdateIntegrationResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

/** @param options The options parameters. */
export const getTriggerSpecifications = (
  options?: GetTriggerSpecificationsOptionalParams,
): Promise<GetTriggerSpecificationsResponse> => HttpClient.getTriggerSpecifications(options);

export const getTriggerSpecificationCriteriaOptions = (
  triggerSpecificationGuid: string,
  options?: GetTriggerSpecificationCriteriaOptionsOptionalParams,
): Promise<GetTriggerSpecificationCriteriaOptionsResponse> =>
  HttpClient.getTriggerSpecificationCriteriaOptions(triggerSpecificationGuid, options);

export const createTriggerCriteriaValue = (
  integrationId: string,
  criterionId: string,
  options?: CreateTriggerCriteriaValueOptionalParams,
): Promise<CreateTriggerCriteriaValueResponse> =>
  HttpClient.createTriggerCriteriaValue(integrationId, criterionId, options);

export const deleteTriggerCriteriaValue = (
  integrationId: string,
  criterionId: string,
  criterionTriggerValueId: string,
  options?: DeleteTriggerCriteriaValueOptionalParams,
): Promise<void> =>
  HttpClient.deleteTriggerCriteriaValue(
    integrationId,
    criterionId,
    criterionTriggerValueId,
    options,
  );

export const createPreConfiguredOutboundIntegration = (
  options?: CreatePreConfiguredOutboundIntegrationOptionalParams,
): Promise<CreatePreConfiguredOutboundIntegrationResponse> =>
  HttpClient.createPreConfiguredOutboundIntegration(options);

export const getIntegrationById = (
  integrationId: string,
  options?: GetIntegrationByIdOptionalParams,
): Promise<GetIntegrationByIdResponse> => HttpClient.getIntegrationById(integrationId, options);

export const getIntegrations = (
  options?: GetIntegrationsOptionalParams,
): Promise<GetIntegrationsResponse> => HttpClient.getIntegrations(options);

export const listPluginConfigurations = (
  options?: ListPluginConfigurationsOptionalParams,
): Promise<ListPluginConfigurationsResponse> => HttpClient.listPluginConfigurations(options);

export const updateIntegration = (
  integrationId: string,
  options?: UpdateIntegrationOptionalParams,
): Promise<UpdateIntegrationResponse> => HttpClient.updateIntegration(integrationId, options);

export const useGetTriggerSpecifications = (options?: GetTriggerSpecificationsOptionalParams) =>
  useQuery(['triggerSpecifications', options], () => getTriggerSpecifications(options));

export const useGetTriggerSpecificationCriteriaOptions = (
  triggerSpecificationGuid: string,
  options?: GetTriggerSpecificationCriteriaOptionsOptionalParams,
) =>
  useQuery(['triggerSpecificationCriteriaOptionsataId', triggerSpecificationGuid, options], () =>
    getTriggerSpecificationCriteriaOptions(triggerSpecificationGuid, options),
  );

export const useCreateTriggerCriteriaValue = (queryClient: QueryClient) =>
  useMutation(
    ({
      integrationId,
      criterionId,
      options,
    }: {
      integrationId: string;
      criterionId: string;
      options?: CreateTriggerCriteriaValueOptionalParams;
    }) => createTriggerCriteriaValue(integrationId, criterionId, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getIntegrations');
        queryClient.invalidateQueries('getIntegrationById');
      },
    },
  );

export const useDeleteTriggerCriteriaValue = (queryClient: QueryClient) =>
  useMutation(
    ({
      integrationId,
      criterionId,
      criterionTriggerValueId,
      options,
    }: {
      integrationId: string;
      criterionId: string;
      criterionTriggerValueId: string;
      options?: DeleteTriggerCriteriaValueOptionalParams;
    }) => deleteTriggerCriteriaValue(integrationId, criterionId, criterionTriggerValueId, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getIntegrations');
        queryClient.invalidateQueries('getIntegrationById');
      },
    },
  );

export const useCreatePreConfiguredOutboundIntegration = (queryClient: QueryClient) =>
  useMutation(
    ({ options }: { options?: CreatePreConfiguredOutboundIntegrationOptionalParams }) =>
      createPreConfiguredOutboundIntegration(options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('preConfiguredOutboundIntegration');
        queryClient.invalidateQueries('getIntegrations');
        queryClient.invalidateQueries('getIntegrationById');
      },
      onError: () => {
        queryClient.invalidateQueries('preConfiguredOutboundIntegration');
      },
    },
  );

export const useGetIntegrationById = (
  integrationId: string,
  options?: GetIntegrationByIdOptionalParams,
) =>
  useQuery(['getIntegrationById', integrationId, options], () =>
    getIntegrationById(integrationId, options),
  );

export const useGetIntegrations = (options?: GetIntegrationsOptionalParams) =>
  useQuery(['getIntegrations', options], () => getIntegrations(options));

export const useListPluginConfigurations = (options?: ListPluginConfigurationsOptionalParams) =>
  useQuery(['listPluginConfigurations', options], () => listPluginConfigurations(options));

export const useUpdateIntegration = (queryClient: QueryClient) =>
  useMutation(
    ({
      integrationId,
      options,
    }: {
      integrationId: string;
      options?: UpdateIntegrationOptionalParams;
    }) => updateIntegration(integrationId, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('preConfiguredOutboundIntegration');
        queryClient.invalidateQueries('getIntegrations');
        queryClient.invalidateQueries('getIntegrationById');
      },
    },
  );
